@import "./base";
@import "./media";

$render-mixins: false !default;

$image-ratio-steps: (
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16
) !default;


@mixin ratios($size1, $size2, $suffix: '') {
    .ratio--#{$size1}-#{$size2}#{$suffix}:after {
        padding-bottom: calc($size2 / $size1 ) * 100%;
    }
}

@if ($render-mixins) {

    [class*="ratio--"] > img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    [class*="ratio--"]:after {
        content: '\00a0';
        display: block;
        width: 100%;
        height: 0;
    }

    @include media() {
        @each $stepOuter in reverse($image-ratio-steps) {
            @each $stepInner in $image-ratio-steps {
                @include ratios($stepOuter, $stepInner, $suffix);
            }
        }
    }
}

@import "../icon/sass-inline-svg";

/**
 * Google infowindow
 */

.gm-style .gm-style-iw > button img {
    display: none !important;
}

.gm-style .gm-style-iw-c {
    border-radius: 0 !important;
    background: $color-white !important;
    box-shadow: 0 0 4px rgba($color-black, 0.1) !important;
}

.gm-style .gm-style-iw > button {
    top: -3px !important;
    right: -3px !important;
    background-size: 14px 14px !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-image: inline-svg('times', $color-black) !important;
    transition: opacity 0.15s;
}

.gm-style .gw-i-html,
.gm-style .gm-iw {
    padding: 10px;
}

/**
 * Google directions
 */

.adp-placemark {
    padding: 15px 10px;
    border: 0 !important;
    background: $color-background !important;
}

.adp-placemark td {
    padding: 10px;
}

.adp-placemark td:first-child {
    padding: 10px 10px 10px 20px;
}

.adp-placemark td:last-child {
    padding: 10px 20px 10px 10px;
}

.adp-summary {
    padding: 10px 20px !important;
}

.adp-directions {
    width: 100%;
}

.adp-directions td {
    border-top: 1px solid $color-grey-100 !important;
    padding: 10px;
}

.adp-directions td:first-child {
    padding: 10px 10px 10px 20px;
}

.adp-directions td:last-child {
    padding: 10px 20px 10px 10px;
}

.adp-distance {
    text-align: right;
}

.adp-legal {
    text-align: center;
    font-size: 13px;
    color: $color-grey-500;
}

@import "./base";
@import "./media";

$render-mixins: false !default;

$grid: (
        steps: (1, 2, 3, 12),
        gutters: (),
        breakpoint-gutters: (
                'xs': 20px,
                'sm': 20px,
                'md': 30px,
                'lg': 30px,
                'xl': 30px,
                'xxl': 10px,
        ),
        container-paddings: (
                'xs': 20px,
                'sm': 20px,
                'md': 30px,
                'lg': 60px,
                'xl': 60px,
                'xxl': 60px,
        ),
        container-paddings-fluid: (
                'xs': 0px,
                'sm': 0px,
                'md': 0px,
                'lg': 49px,
                'xl': 96px,
                'xxl': 96px,
        )
) !default;

$grid-steps: map-get($grid, 'steps') !default;

$grid-gutters: map-get($grid, 'gutters') !default;

$grid-breakpoint-gutters: map-get($grid, 'breakpoint-gutters') !default;

$container-paddings: map-get($grid, 'container-paddings') !default;

$container-paddings--fluid: map-get($grid, 'container-fluid-paddings') !default;

$container-full-maxwidth: 1500 !default;


@mixin grid-container($suffix: '') {

    .grid#{$suffix} {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .grid--base#{$suffix}, .grid--nowrap#{$suffix} {
        flex-wrap: nowrap;
    }

    .grid--col#{$suffix} {
        flex-direction: column;
    }

    .grid--wrap#{$suffix} {
        flex-wrap: wrap;
    }

    .grid.grid--reverse#{$suffix},
    .grid--row.grid--reverse#{$suffix},
    .grid--row#{$suffix}.grid--reverse#{$suffix} {
        flex-direction: row-reverse;
    }

    .grid--col.grid--reverse#{$suffix},
    .grid--col#{$suffix}.grid--reverse#{$suffix} {
        flex-direction: column-reverse;
    }
    .grid.grid--stretch#{$suffix},
    .grid--row.grid--stretch#{$suffix},
    .grid--row#{$suffix}.grid--stretch#{$suffix} {
        justify-content: stretch;
    }

    .grid--col.grid--stretch#{$suffix},
    .grid--col#{$suffix}.grid--stretch#{$suffix} {
        align-items: stretch;
    }

    .grid.grid--top#{$suffix},
    .grid--row.grid--top#{$suffix},
    .grid--row#{$suffix}.grid--top#{$suffix},
    .grid--col.grid--left#{$suffix},
    .grid--col#{$suffix}.grid--left#{$suffix} {
        align-items: flex-start;
    }

    .grid.grid--middle#{$suffix},
    .grid--row.grid--middle#{$suffix},
    .grid--row#{$suffix}.grid--middle#{$suffix},
    .grid--col.grid--center#{$suffix},
    .grid--col#{$suffix}.grid--center#{$suffix} {
        align-items: center;
    }

    .grid.grid--bottom#{$suffix},
    .grid--row.grid--bottom#{$suffix},
    .grid--row#{$suffix}.grid--bottom#{$suffix},
    .grid--col.grid--right#{$suffix},
    .grid--col#{$suffix}.grid--right#{$suffix} {
        align-items: flex-end;
    }

    .grid.grid--left#{$suffix},
    .grid--row.grid--left#{$suffix},
    .grid--row#{$suffix}.grid--left#{$suffix},
    .grid--col.grid--top#{$suffix},
    .grid--col#{$suffix}.grid--top#{$suffix} {
        justify-content: flex-start;
    }

    .grid.grid--center#{$suffix},
    .grid--row.grid--center#{$suffix},
    .grid--row#{$suffix}.grid--center#{$suffix},
    .grid--col.grid--middle#{$suffix},
    .grid--col#{$suffix}.grid--middle#{$suffix} {
        justify-content: center;
    }

    .grid.grid--right#{$suffix},
    .grid--row.grid--right#{$suffix},
    .grid--row#{$suffix}.grid--right#{$suffix},
    .grid--col.grid--bottom#{$suffix},
    .grid--col#{$suffix}.grid--bottom#{$suffix} {
        justify-content: flex-end;
    }

}

@mixin grid-columns($suffix: '') {

    .col#{$suffix},
    .col--auto#{$suffix} {
        width: auto;
    }

    .col--top#{$suffix} {
        margin-bottom: auto;
    }

    .col--middle#{$suffix} {
        margin-top: auto;
        margin-bottom: auto;
    }

    .col--bottom#{$suffix} {
        margin-top: auto;
    }

    .col--left#{$suffix} {
        margin-right: auto;
    }

    .col--center#{$suffix} {
        margin-right: auto;
        margin-left: auto;
    }

    .col--right#{$suffix} {
        margin-left: auto;
    }

    .col--flex-1#{$suffix},
    .col--flex-1-0#{$suffix} {
        flex: 1 0 auto;
    }

    .col--flex-0#{$suffix},
    .col--flex-0-1#{$suffix} {
        flex: 0 1 auto;
    }

    .col--flex-none#{$suffix},
    .col--flex-0-0#{$suffix} {
        flex: 0 0 auto;
    }

    .col--flex-auto#{$suffix},
    .col--flex-1-1#{$suffix} {
        flex: 1 1 auto;
    }

    .col--flex-fixed#{$suffix} {
        flex: 1 1 auto;
        min-height: 0;
        max-height: 100%;
    }

    @for $i from 1 through 10 {
        .col--order-#{$i}#{$suffix} {
            order: $i;
        }
    }
}


@mixin grid-columns-size($size, $suffix: '') {

    @for $i from 1 through $size {
        .col--#{$i}-#{$size}#{$suffix} {
            width: #{(calc(100% / $size) * $i)};
        }
    }

    @for $i from 1 through $size {
        .offset--#{$i}-#{$size}#{$suffix} {
            margin-left: #{(calc(100% / $size) * $i)};
        }
    }
}


@mixin grid-gutter($gutter, $suffix: '') {

    .grid--#{strip($gutter)}#{$suffix} {
        width: calc(100% + #{$gutter});
        margin-left: -#{calc($gutter / 2)};
        margin-right: -#{calc($gutter / 2)};
    }

    .grid--#{strip($gutter)}-#{strip($gutter)}#{$suffix} {
        width: calc(100% + #{$gutter}) !important;
        margin-left: -#{calc($gutter / 2)};
        margin-right: -#{calc($gutter / 2)};
        margin-top: -#{calc($gutter / 2)};
        margin-bottom: -#{calc($gutter / 2)};
    }

    .grid--#{strip($gutter)}#{$suffix} > [class^="col"],
    .grid--#{strip($gutter)}#{$suffix} > [class*="col"] {
        padding-left: calc($gutter / 2);
        padding-right: calc($gutter / 2);
    }

    .grid--#{strip($gutter)}-#{strip($gutter)}#{$suffix} > [class^="col"],
    .grid--#{strip($gutter)}-#{strip($gutter)}#{$suffix} > [class*="col"] {
        padding-left: calc($gutter / 2);
        padding-right: calc($gutter / 2);
        padding-top: calc($gutter / 2);
        padding-bottom: calc($gutter / 2);
    }

}


@mixin grid-gutters() {
    @each $breakpointName, $gutter in ($grid-breakpoint-gutters) {
        @include media($breakpointName) {
            .grid.grid--gutter {
                margin-left: -#{calc($gutter / 2)};
                margin-right: -#{calc($gutter / 2)};
            }

            .grid.grid--gutter > [class^="col"],
            .grid.grid--gutter > [class*="col"] {
                padding-left: calc($gutter / 2);
                padding-right: calc($gutter / 2);
            }
        }
    }
}

@mixin container() {
    .container {
        position: relative;
        margin: 0 auto;
        max-width: 100%;
    }

    @each $breakpointName, $padding in ($container-paddings) {
        //@debug ($breakpointName);
        @include media($breakpointName) {
            .container {
                padding-left: #{$padding};
                padding-right: #{$padding};

                //TODO SX4 erst ab mb einschränken
                max-width: #{map-get($media-steps,breakpoint-next($breakpointName))};
            }
        }
    }

    .container .container {
        padding-left: 0;
        padding-right: 0;
        max-width: 100%;
    }
}


@mixin container--fluid() {
    .container--fluid {
        max-width: inherit !important;
    }

    @each $breakpointName, $padding in ($container-paddings--fluid) {
        @include media($breakpointName) {
            .container--fluid {
                padding-left: #{$padding};
                padding-right: #{$padding};
            }
        }
    }

    .container--fluid .container {
        padding-left: 0;
        padding-right: 0;
        max-width: 100%;
    }
}

@mixin container--full() {
    .container--full {
        max-width: $container-full-maxwidth;
    }

    .container--full .container {
        padding-left: 0;
        padding-right: 0;
        max-width: 100%;
    }
}


@if ($render-mixins) {

    @include media() {

        @include grid-columns($suffix);

        @each $steps in reverse($grid-steps) {
            @include grid-columns-size($steps, $suffix);
        }

        @each $gutter in reverse($grid-gutters) {
            @include grid-gutter($gutter, $suffix);
        }

        @include grid-container($suffix);

    }

    @include grid-gutters();

    @include container();

    @include container--fluid();

    @include container--full();

}
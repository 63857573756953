@import "./vars";

@import "./vendor/google";
//@import "./vendor/hamburger";
@import "./vendor/lightgallery";
@import "./vendor/splide";
//@import "./vendor/swiper";
@import "./vendor/uikit";
@import "./vendor/mobiscroll";

.splide {
    visibility: visible !important;
}

/**
 * Activate rendering
 */
$render-mixins: true;

@import "@sx-core/src/scss/mixin/base";
@import "@sx-core/src/scss/mixin/breakpoints";
@import "@sx-core/src/scss/mixin/media";
@import "@sx-core/src/scss/mixin/grid";
@import "@sx-core/src/scss/mixin/image";


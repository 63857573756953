
$mbsc-font-path: '' !default;

@font-face {
    font-family: 'Mobiscroll';
    src: 
        url($mbsc-font-path + 'icons_mobiscroll.woff?kdcybb') format('woff'),
        url($mbsc-font-path + 'icons_mobiscroll.woff') format('woff'),
        url($mbsc-font-path + 'icons_mobiscroll.ttf?kdcybb') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.mbsc-font-icon:before {
    font-family: 'Mobiscroll';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Icons */

.mbsc-icon-arrow-down5::before {
    content: "\ea01";
}
.mbsc-icon-arrow-left5::before {
    content: "\ea02";
}
.mbsc-icon-arrow-right5::before {
    content: "\ea03";
}
.mbsc-icon-arrow-up5::before {
    content: "\ea04";
}
.mbsc-icon-ion-ios7-arrow-back::before {
    content: "\ea05";
}
.mbsc-icon-ion-ios7-arrow-forward::before {
    content: "\ea06";
}
.mbsc-icon-ion-ios7-checkmark-empty::before {
    content: "\ea07";
}
.mbsc-icon-ios-backspace::before {
    content: "\ea08";
}

.mbsc-icon {
  display: inline-block;
  vertical-align: middle;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  text-align: center;
  flex: 0 0 auto;
}

.mbsc-icon > svg {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  fill: currentColor;
}

:root {
  --mbsc-safe-top: 0;
  --mbsc-safe-right: 0;
  --mbsc-safe-bottom: 0;
  --mbsc-safe-left: 0;

  @supports (top: constant(safe-area-inset-top)) {
    --mbsc-safe-top: constant(safe-area-inset-top);
    --mbsc-safe-right: constant(safe-area-inset-right);
    --mbsc-safe-bottom: constant(safe-area-inset-bottom);
    --mbsc-safe-left: constant(safe-area-inset-left);
  }

  @supports (top: env(safe-area-inset-top)) {
    --mbsc-safe-top: env(safe-area-inset-top);
    --mbsc-safe-right: env(safe-area-inset-right);
    --mbsc-safe-bottom: env(safe-area-inset-bottom);
    --mbsc-safe-left: env(safe-area-inset-left);
  }
}

.mbsc-font {
  font-family: -apple-system, 'Segoe UI', Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
}

.mbsc-reset {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  // border-radius: 0;
  // font-family: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.mbsc-resize {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
}

.mbsc-resize-i {
  position: absolute;
  left: 0;
  top: 0;
}

.mbsc-resize-y {
  width: 200%;
  height: 200%;
}

.mbsc-hidden {
  visibility: hidden;
}

.mbsc-hidden-content {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  user-select: none;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.mbsc-ltr {
  direction: ltr;
}

.mbsc-rtl {
  direction: rtl;
}

.mbsc-ripple {
  background: currentColor;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  border-radius: 1000em;
  pointer-events: none;
  transform: scale(0);
}

/* Flex util classes */

.mbsc-flex,
.mbsc-flex-col {
  display: flex;
}

.mbsc-flex-col {
  flex-direction: column;
}

.mbsc-flex-1-1 {
  flex: 1 1 auto;
}

.mbsc-flex-1-0 {
  flex: 1 0 auto;
}

.mbsc-flex-1-0-0 {
  flex: 1 0 0;
}

/* IE11 hack, where flex-basis auto does not work correctly */
@media all and (-ms-high-contrast: none) {
  .mbsc-flex-1-0-0 {
    flex: 1 0 auto;
  }
}

.mbsc-flex-none {
  flex: none;
}

@media (-webkit-min-device-pixel-ratio: 2) {
  .mbsc-hb,
  .mbsc-hb:before,
  .mbsc-hb:after {
    border-style: none; // Needed for Tailwind css compatibility
    border-width: 0.5px !important;
  }
}

// Theme variables for download builder
$mbsc-ios-theme: 'true' !default;
$mbsc-material-theme: null !default;
$mbsc-windows-theme: null !default;
$mbsc-mobiscroll-theme: null !default;

// Component conditional loading variables
$mbsc-datepicker: true !default;
$mbsc-eventcalendar: true !default;
$mbsc-forms: true !default;
$mbsc-grid-layout: true !default;
$mbsc-popup: true !default;
$mbsc-select: true !default;

// Sub-components and Dependencies
$mbsc-button: false !default;
$mbsc-input: false !default;
$mbsc-scroller: false !default;
$mbsc-segmented: false !default;
$mbsc-calendar-view: false !default;

@if $mbsc-datepicker or $mbsc-eventcalendar {
  $mbsc-calendar-view: true;
}

@if $mbsc-datepicker or $mbsc-select {
  // pickers
  $mbsc-button: true;
  $mbsc-input: true;
  $mbsc-popup: true;
  $mbsc-scroller: true;
}

@if $mbsc-forms {
  $mbsc-button: true;
  $mbsc-input: true;
  $mbsc-segmented: true;
}

@if $mbsc-datepicker {
  $mbsc-segmented: true;
}

@if $mbsc-popup or $mbsc-eventcalendar {
  $mbsc-button: true;
}

// Page color variables

$mbsc-page-background-light: null !default;
$mbsc-page-background-dark: null !default;
$mbsc-page-text-light: null !default;
$mbsc-page-text-dark: null !default;

$mbsc-button-color-light: null !default;
$mbsc-button-color-dark: null !default;
$mbsc-button-text-light: null !default;
$mbsc-button-text-dark: null !default;

$mbsc-form-background-light: null !default;
$mbsc-form-background-dark: null !default;
$mbsc-form-accent-light: null !default;
$mbsc-form-accent-dark: null !default;
$mbsc-form-text-light: null !default;
$mbsc-form-text-dark: null !default;
$mbsc-form-error-light: null !default;
$mbsc-form-error-dark: null !default;

$mbsc-input-background-light: null !default;
$mbsc-input-background-dark: null !default;
$mbsc-input-text-light: null !default;
$mbsc-input-text-dark: null !default;
$mbsc-input-accent-light: null !default;
$mbsc-input-accent-dark: null !default;
$mbsc-input-border-light: null !default;
$mbsc-input-border-dark: null !default;

$mbsc-calendar-background-light: null !default;
$mbsc-calendar-background-dark: null !default;
$mbsc-calendar-text-light: null !default;
$mbsc-calendar-text-dark: null !default;
$mbsc-calendar-accent-light: null !default;
$mbsc-calendar-accent-dark: null !default;
$mbsc-calendar-border-light: null !default;
$mbsc-calendar-border-dark: null !default;
$mbsc-calendar-mark-light: null !default;
$mbsc-calendar-mark-dark: null !default;
$mbsc-calendar-event-light: null !default;
$mbsc-calendar-event-dark: null !default;

@function get-contrast-color($color) {
  @if (lightness($color) > 67%) {
    @return #000;
  } @else {
    @return #fff;
  }
}


$mbsc-mobiscroll-accent: #2b93e6 !default;
$mbsc-mobiscroll-background: #ffffff !default;
$mbsc-mobiscroll-text: #454545 !default;

$mbsc-mobiscroll-dark-accent: #2b93e6 !default;
$mbsc-mobiscroll-dark-background: #000 !default;
$mbsc-mobiscroll-dark-text: #ffffff !default;

/* Base colors */
$mbsc-mobiscroll-primary: #3f97f6 !default;
$mbsc-mobiscroll-secondary: #90979e !default;
$mbsc-mobiscroll-success: #43be5f !default;
$mbsc-mobiscroll-danger: #f5504e !default;
$mbsc-mobiscroll-warning: #f8b042 !default;
$mbsc-mobiscroll-info: #5bb7c5 !default;
$mbsc-mobiscroll-light: darken(#fff, 10%) !default;
$mbsc-mobiscroll-dark: #47494a !default;

$mbsc-mobiscroll-error: #de3226;

/* Form colors */

$mbsc-mobiscroll-form-background: $mbsc-form-background-light !default;
$mbsc-mobiscroll-dark-form-background: $mbsc-form-background-dark !default;
$mbsc-mobiscroll-form-accent: $mbsc-form-accent-light !default;
$mbsc-mobiscroll-dark-form-accent: $mbsc-form-accent-dark !default;
$mbsc-mobiscroll-form-text: $mbsc-form-text-light !default;
$mbsc-mobiscroll-dark-form-text: $mbsc-form-text-dark !default;
$mbsc-mobiscroll-form-error: $mbsc-form-error-light !default;
$mbsc-mobiscroll-dark-form-error: $mbsc-form-error-dark !default;

$mbsc-mobiscroll-colors: (
  // Colors map
  'background': $mbsc-mobiscroll-background,
  'text': $mbsc-mobiscroll-text,
  'accent': $mbsc-mobiscroll-accent,
  'form-background': $mbsc-mobiscroll-form-background,
  'form-accent': $mbsc-mobiscroll-form-accent,
  'form-text': $mbsc-mobiscroll-form-text,
  'form-error': $mbsc-mobiscroll-form-error
);

$mbsc-mobiscroll-dark-colors: (
  // Colors map
  'background': $mbsc-mobiscroll-dark-background,
  'text': $mbsc-mobiscroll-dark-text,
  'accent': $mbsc-mobiscroll-dark-accent,
  'form-background': $mbsc-mobiscroll-dark-form-background,
  'form-accent': $mbsc-mobiscroll-dark-form-accent,
  'form-text': $mbsc-mobiscroll-dark-form-text,
  'form-error': $mbsc-mobiscroll-dark-form-error
);

@function mbsc-mobiscroll-colors($params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  @return ();
}


$mbsc-mobiscroll-button-color: $mbsc-button-color-light !default;
$mbsc-mobiscroll-button-text: $mbsc-button-text-light !default;

$mbsc-mobiscroll-dark-button-color: $mbsc-button-color-dark !default;
$mbsc-mobiscroll-dark-button-text: $mbsc-button-text-dark !default;

$mbsc-mobiscroll-colors: map-merge(
  $mbsc-mobiscroll-colors,
  (
    'button-color': $mbsc-mobiscroll-button-color,
    'button-text': $mbsc-mobiscroll-button-text,
  )
);

$mbsc-mobiscroll-dark-colors: map-merge(
  $mbsc-mobiscroll-dark-colors,
  (
    'button-color': $mbsc-mobiscroll-dark-button-color,
    'button-text': $mbsc-mobiscroll-dark-button-text,
  )
);

@mixin mbsc-mobiscroll-button($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $button-background-param: map-get($params, 'button-color');
  $button-text-param: map-get($params, 'button-text');

  $btn-background: if($button-background-param, $button-background-param, $accent);
  $btn-text-base: if($button-background-param, $button-background-param, $background);
  $btn-text: '';

  @if (lightness($btn-text-base) > 50%) {
    $btn-text: darken($btn-text-base, 3%);
  } @else {
    $btn-text: $btn-text-base;
  }

  $btn-text: if($button-text-param, $button-text-param, $btn-text);

  .mbsc-#{$theme} {
    &.mbsc-button-standard {
      background: $btn-background;
      color: $btn-text;
    }

    /* Flat buttons */
    &.mbsc-button-flat {
      color: $btn-background;

      &.mbsc-hover {
        background: rgba($btn-background, 0.2);
      }

      &.mbsc-active {
        background: rgba($btn-background, 0.3);
      }
    }

    /* Outline buttons */
    &.mbsc-button-outline {
      border: 1px solid $btn-background;
      color: $btn-background;

      &.mbsc-active {
        background: $btn-background;
        color: $background;
      }
    }

    &.mbsc-button.mbsc-focus {
      // background: rgba($accent, .3);
      box-shadow: 0 0 0 2px rgba($text, 0.5);
    }

    /* Predefined colors */
    &.mbsc-button-primary.mbsc-button-standard {
      background: $mbsc-mobiscroll-primary;
      color: $background;
    }

    &.mbsc-button-secondary.mbsc-button-standard {
      background: $mbsc-mobiscroll-secondary;
      color: $background;
    }

    &.mbsc-button-success.mbsc-button-standard {
      background: $mbsc-mobiscroll-success;
      color: $background;
    }

    &.mbsc-button-danger.mbsc-button-standard {
      background: $mbsc-mobiscroll-danger;
      color: $background;
    }

    &.mbsc-button-warning.mbsc-button-standard {
      background: $mbsc-mobiscroll-warning;
      color: $background;
    }

    &.mbsc-button-info.mbsc-button-standard {
      background: $mbsc-mobiscroll-info;
      color: $background;
    }

    &.mbsc-button-dark.mbsc-button-standard {
      background: $mbsc-mobiscroll-dark;
      color: $background;
    }

    &.mbsc-button-light.mbsc-button-standard {
      background: $mbsc-mobiscroll-light;
      color: $text;
    }

    &.mbsc-button-primary.mbsc-button-flat {
      color: $mbsc-mobiscroll-primary;

      &.mbsc-hover {
        background: rgba($mbsc-mobiscroll-primary, 0.2);
      }

      &.mbsc-active {
        background: rgba($mbsc-mobiscroll-primary, 0.3);
      }
    }

    &.mbsc-button-secondary.mbsc-button-flat {
      color: $mbsc-mobiscroll-secondary;

      &.mbsc-hover {
        background: rgba($mbsc-mobiscroll-secondary, 0.2);
      }

      &.mbsc-active {
        background: rgba($mbsc-mobiscroll-secondary, 0.3);
      }
    }

    &.mbsc-button-success.mbsc-button-flat {
      color: $mbsc-mobiscroll-success;

      &.mbsc-hover {
        background: rgba($mbsc-mobiscroll-success, 0.2);
      }

      &.mbsc-active {
        background: rgba($mbsc-mobiscroll-success, 0.3);
      }
    }

    &.mbsc-button-danger.mbsc-button-flat {
      color: $mbsc-mobiscroll-danger;

      &.mbsc-hover {
        background: rgba($mbsc-mobiscroll-danger, 0.2);
      }

      &.mbsc-active {
        background: rgba($mbsc-mobiscroll-danger, 0.3);
      }
    }

    &.mbsc-button-warning.mbsc-button-flat {
      color: $mbsc-mobiscroll-warning;

      &.mbsc-hover {
        background: rgba($mbsc-mobiscroll-warning, 0.2);
      }

      &.mbsc-active {
        background: rgba($mbsc-mobiscroll-warning, 0.3);
      }
    }

    &.mbsc-button-info.mbsc-button-flat {
      color: $mbsc-mobiscroll-info;

      &.mbsc-hover {
        background: rgba($mbsc-mobiscroll-info, 0.2);
      }

      &.mbsc-active {
        background: rgba($mbsc-mobiscroll-info, 0.3);
      }
    }

    &.mbsc-button-dark.mbsc-button-flat {
      color: $mbsc-mobiscroll-dark;

      &.mbsc-hover {
        background: rgba($mbsc-mobiscroll-dark, 0.2);
      }

      &.mbsc-active {
        background: rgba($mbsc-mobiscroll-dark, 0.3);
      }
    }

    &.mbsc-button-light.mbsc-button-flat {
      color: darken($mbsc-mobiscroll-light, 20%);

      &.mbsc-hover {
        background: rgba($mbsc-mobiscroll-light, 0.2);
      }

      &.mbsc-active {
        background: rgba($mbsc-mobiscroll-light, 0.3);
      }
    }

    &.mbsc-button-primary.mbsc-button-outline {
      border-color: $mbsc-mobiscroll-primary;
      color: $mbsc-mobiscroll-primary;

      &.mbsc-active {
        background: $mbsc-mobiscroll-primary;
        color: $background;
      }
    }

    &.mbsc-button-secondary.mbsc-button-outline {
      border-color: $mbsc-mobiscroll-secondary;
      color: $mbsc-mobiscroll-secondary;

      &.mbsc-active {
        background: $mbsc-mobiscroll-secondary;
        color: $background;
      }
    }

    &.mbsc-button-success.mbsc-button-outline {
      border-color: $mbsc-mobiscroll-success;
      color: $mbsc-mobiscroll-success;

      &.mbsc-active {
        background: $mbsc-mobiscroll-success;
        color: $background;
      }
    }

    &.mbsc-button-danger.mbsc-button-outline {
      border-color: $mbsc-mobiscroll-danger;
      color: $mbsc-mobiscroll-danger;

      &.mbsc-active {
        background: $mbsc-mobiscroll-danger;
        color: $background;
      }
    }

    &.mbsc-button-warning.mbsc-button-outline {
      border-color: $mbsc-mobiscroll-warning;
      color: $mbsc-mobiscroll-warning;

      &.mbsc-active {
        background: $mbsc-mobiscroll-warning;
        color: $background;
      }
    }

    &.mbsc-button-info.mbsc-button-outline {
      border-color: $mbsc-mobiscroll-info;
      color: $mbsc-mobiscroll-info;

      &.mbsc-active {
        background: $mbsc-mobiscroll-info;
        color: $background;
      }
    }

    &.mbsc-button-dark.mbsc-button-outline {
      border-color: $mbsc-mobiscroll-dark;
      color: $mbsc-mobiscroll-dark;

      &.mbsc-active {
        background: $mbsc-mobiscroll-dark;
        color: $background;
      }
    }

    &.mbsc-button-light.mbsc-button-outline {
      border-color: darken($mbsc-mobiscroll-light, 20%);
      color: darken($mbsc-mobiscroll-light, 20%);

      &.mbsc-active {
        background: darken($mbsc-mobiscroll-light, 20%);
        color: $background;
      }
    }
  }
}


@if ($mbsc-mobiscroll-theme) {
  .mbsc-mobiscroll {
    &.mbsc-icon-button {
      border-radius: 4em;
    }

    &.mbsc-button {
      font-size: 1em;
      margin: 0.5em;
      padding: 0.25em 0.625em;
      line-height: 1.75em;
      border-radius: 0.25em;
      text-transform: uppercase;
      transition: background-color 0.2s ease-out;
    }

    &.mbsc-button-standard {
      &.mbsc-hover {
        opacity: 0.8;
      }

      &.mbsc-active {
        opacity: 0.6;
      }
    }

    &.mbsc-button-outline {
      &.mbsc-hover {
        opacity: 0.8;
      }

      &.mbsc-active {
        opacity: 1;
      }
    }

    &.mbsc-button:disabled,
    &.mbsc-button.mbsc-disabled {
      opacity: 0.3;
    }

    &.mbsc-ltr {
      &.mbsc-button-icon-start {
        padding-right: 0.625em;
      }

      &.mbsc-button-icon-end {
        padding-left: 0.625em;
      }
    }

    &.mbsc-rtl {
      &.mbsc-button-icon-start {
        padding-left: 0.625em;
      }

      &.mbsc-button-icon-end {
        padding-right: 0.625em;
      }
    }

    .mbsc-button-group,
    .mbsc-button-group-justified {
      margin: 0.5em;
    }

    .mbsc-button-group-block {
      margin: 0.5em 1em;
    }
  }

  @include mbsc-mobiscroll-button('mobiscroll', $mbsc-mobiscroll-colors);
  @include mbsc-mobiscroll-button('mobiscroll-dark', $mbsc-mobiscroll-dark-colors);
}



$mbsc-ios-accent: #007aff !default;
$mbsc-ios-background: #f7f7f7 !default;
$mbsc-ios-text: #000000 !default;

$mbsc-ios-dark-accent: #ff9f0a !default;
$mbsc-ios-dark-background: #000000 !default;
$mbsc-ios-dark-text: #ffffff !default;

/* Base colors */
$mbsc-ios-primary: #3f97f6 !default;
$mbsc-ios-secondary: #90979e !default;
$mbsc-ios-success: #43be5f !default;
$mbsc-ios-danger: #f5504e !default;
$mbsc-ios-warning: #f8b042 !default;
$mbsc-ios-info: #5bb7c5 !default;
$mbsc-ios-light: #fff !default;
$mbsc-ios-dark: #47494a !default;

$mbsc-ios-error: #d8332a;

/* Form colors */

$mbsc-ios-form-background: $mbsc-form-background-light !default;
$mbsc-ios-dark-form-background: $mbsc-form-background-dark !default;
$mbsc-ios-form-text: $mbsc-form-text-light !default;
$mbsc-ios-dark-form-text: $mbsc-form-text-dark !default;
$mbsc-ios-form-accent: $mbsc-form-accent-light !default;
$mbsc-ios-dark-form-accent: $mbsc-form-accent-dark !default;
$mbsc-ios-form-error: $mbsc-form-error-light !default;
$mbsc-ios-dark-form-error: $mbsc-form-error-dark !default;

/* Calendar colors (will be used by eventcalendar, calendar, range) */

$mbsc-ios-calendar-background: $mbsc-calendar-background-light !default;
$mbsc-ios-calendar-text: $mbsc-calendar-text-light !default;
$mbsc-ios-calendar-accent: $mbsc-calendar-accent-light !default;
$mbsc-ios-calendar-border: $mbsc-calendar-border-light !default;
$mbsc-ios-calendar-mark: $mbsc-calendar-mark-light !default;
$mbsc-ios-calendar-event: $mbsc-calendar-event-light !default;

$mbsc-ios-dark-calendar-background: $mbsc-calendar-background-dark !default;
$mbsc-ios-dark-calendar-text: $mbsc-calendar-text-dark !default;
$mbsc-ios-dark-calendar-accent: $mbsc-calendar-accent-dark !default;
$mbsc-ios-dark-calendar-border: $mbsc-calendar-border-dark !default;
$mbsc-ios-dark-calendar-mark: $mbsc-calendar-mark-dark !default;
$mbsc-ios-dark-calendar-event: $mbsc-calendar-event-dark !default;

$mbsc-ios-colors: (
  // Colors map
  'background': $mbsc-ios-background,
  'text': $mbsc-ios-text,
  'accent': $mbsc-ios-accent,

  'calendar-background': $mbsc-ios-calendar-background,
  'calendar-text': $mbsc-ios-calendar-text,
  'calendar-accent': $mbsc-ios-calendar-accent,
  'calendar-border': $mbsc-ios-calendar-border,
  'calendar-mark': $mbsc-ios-calendar-mark,
  'calendar-event': $mbsc-ios-calendar-event,

  'form-background': $mbsc-ios-form-background,
  'form-text': $mbsc-ios-form-text,
  'form-accent': $mbsc-ios-form-accent,
  'form-error': $mbsc-ios-form-error
);

$mbsc-ios-dark-colors: (
  // Colors map
  'background': $mbsc-ios-dark-background,
  'text': $mbsc-ios-dark-text,
  'accent': $mbsc-ios-dark-accent,

  'calendar-background': $mbsc-ios-dark-calendar-background,
  'calendar-text': $mbsc-ios-dark-calendar-text,
  'calendar-accent': $mbsc-ios-dark-calendar-accent,
  'calendar-border': $mbsc-ios-dark-calendar-border,
  'calendar-mark': $mbsc-ios-dark-calendar-mark,
  'calendar-event': $mbsc-ios-dark-calendar-event,

  'form-background': $mbsc-ios-dark-form-background,
  'form-text': $mbsc-ios-dark-form-text,
  'form-accent': $mbsc-ios-dark-form-accent,
  'form-error': $mbsc-ios-dark-form-error
);

@function mbsc-ios-colors($params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $background-limited: hsl(hue($background), saturation($background), max(lightness($background), 3%));
  $background-alt: lighten($background, 6%);
  $border-color: '';

  @if (lightness($background) > 50%) {
    $border-color: darken($background-limited, 17%);
  } @else {
    $border-color: lighten($background, 20%);
  }

  @return (
    // General colors
    'background-alt': $background-alt,
    'background-limited': $background-limited,
    'border-color': $border-color
  );
}


$mbsc-ios-button-color: $mbsc-button-color-light !default;
$mbsc-ios-button-text: $mbsc-button-text-light !default;

$mbsc-ios-dark-button-color: $mbsc-button-color-dark !default;
$mbsc-ios-dark-button-text: $mbsc-button-text-dark !default;

$mbsc-ios-colors: map-merge(
  $mbsc-ios-colors,
  (
    'button-color': $mbsc-ios-button-color,
    'button-text': $mbsc-ios-button-text,
  )
);

$mbsc-ios-dark-colors: map-merge(
  $mbsc-ios-dark-colors,
  (
    'button-color': $mbsc-ios-dark-button-color,
    'button-text': $mbsc-ios-dark-button-text,
  )
);

@mixin mbsc-ios-button($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $button-background-param: map-get($params, 'button-color');
  $button-text-param: map-get($params, 'button-text');

  $white: #fff;
  $black: #000;
  $button-background: '';
  $background-contrast: '';

  @if (lightness($background) > 50%) {
    $button-background: lighten($background, 10%);
    $background-contrast: #000;
  } @else {
    $button-background: lighten($background, 23%);
    $background-contrast: #fff;
  }

  $button-background: if($button-background-param, $button-background-param, $button-background);
  $button-text: if($button-text-param, $button-text-param, $accent);

  $form-background: $background;
  // The $form-background is used for the active text color of outlined buttons.
  // Until we'll have the form component specify this, we'll default to the $background.
  // @if($button-background-param) {
  //   @if(lightness($button-background-param) > 50%) {
  //     $form-background: adjust-hue(darken(saturate($button-background-param, 19%), 12%), 240deg);
  //   }
  //   @else {
  //     $form-background: adjust-hue(lighten(desaturate($button-background-param, 19%), 10%), 240deg);
  //   }
  // }
  // @else {
  //   @if(lightness($background) > 50%) {
  //     $form-background: adjust-hue(darken(saturate($background, 19%), 2%), 240deg);
  //   }
  //   @else {
  //     $form-background: adjust-hue(lighten(desaturate($background, 19%), 10%), 240deg);
  //   }
  // }

  $form-selection: '';

  // Light button
  @if (lightness($accent) > 50%) {
    $form-selection: lighten(saturate($accent, 15%), 3%);
  }

  // Dark button
  @else {
    $form-selection: darken(desaturate($accent, 15%), 3%);
  }

  $form-selection: if($button-background-param, $button-background-param, $form-selection);
  $flat-color: if($button-background-param, $button-background-param, $accent);

  .mbsc-#{$theme} {
    &.mbsc-button-standard {
      background: $button-background;
      color: $button-text;
    }

    // &.mbsc-button:disabled {
    //   background: $disabled-background;
    //   color: $disabled-color;
    // }

    /* Flat buttons */
    &.mbsc-button-flat {
      color: $flat-color;
    }

    /* Outline buttons */
    &.mbsc-button-outline {
      border: 1px solid $form-selection;
      color: $form-selection;

      &.mbsc-active {
        background: $form-selection;
        color: $background;
      }
    }

    &.mbsc-button.mbsc-focus {
      background: rgba($background-contrast, 0.05);
      // box-shadow: 0 0 0 2px rgba($text, .3);
    }

    /* Predefined colors */
    &.mbsc-button-primary.mbsc-button-standard {
      background: $mbsc-ios-primary;
      color: $white;
    }

    &.mbsc-button-secondary.mbsc-button-standard {
      background: $mbsc-ios-secondary;
      color: $white;
    }

    &.mbsc-button-success.mbsc-button-standard {
      background: $mbsc-ios-success;
      color: $white;
    }

    &.mbsc-button-danger.mbsc-button-standard {
      background: $mbsc-ios-danger;
      color: $white;
    }

    &.mbsc-button-warning.mbsc-button-standard {
      background: $mbsc-ios-warning;
      color: $white;
    }

    &.mbsc-button-info.mbsc-button-standard {
      background: $mbsc-ios-info;
      color: $white;
    }

    &.mbsc-button-dark.mbsc-button-standard {
      background: $mbsc-ios-dark;
      color: $white;
    }

    &.mbsc-button-light.mbsc-button-standard {
      background: $mbsc-ios-light;
      color: $black;
    }

    &.mbsc-button-primary.mbsc-button-flat {
      color: $mbsc-ios-primary;
    }

    &.mbsc-button-secondary.mbsc-button-flat {
      color: $mbsc-ios-secondary;
    }

    &.mbsc-button-success.mbsc-button-flat {
      color: $mbsc-ios-success;
    }

    &.mbsc-button-danger.mbsc-button-flat {
      color: $mbsc-ios-danger;
    }

    &.mbsc-button-warning.mbsc-button-flat {
      color: $mbsc-ios-warning;
    }

    &.mbsc-button-info.mbsc-button-flat {
      color: $mbsc-ios-info;
    }

    &.mbsc-button-dark.mbsc-button-flat {
      color: $mbsc-ios-dark;
    }

    &.mbsc-button-light.mbsc-button-flat {
      color: darken($mbsc-ios-light, 20%);
    }

    &.mbsc-button-primary.mbsc-button-outline {
      border-color: $mbsc-ios-primary;
      color: $mbsc-ios-primary;

      &.mbsc-active {
        background: $mbsc-ios-primary;
        color: $mbsc-ios-light;
      }
    }

    &.mbsc-button-secondary.mbsc-button-outline {
      border-color: $mbsc-ios-secondary;
      color: $mbsc-ios-secondary;

      &.mbsc-active {
        background: $mbsc-ios-secondary;
        color: $mbsc-ios-light;
      }
    }

    &.mbsc-button-success.mbsc-button-outline {
      border-color: $mbsc-ios-success;
      color: $mbsc-ios-success;

      &.mbsc-active {
        background: $mbsc-ios-success;
        color: $mbsc-ios-light;
      }
    }

    &.mbsc-button-danger.mbsc-button-outline {
      border-color: $mbsc-ios-danger;
      color: $mbsc-ios-danger;

      &.mbsc-active {
        background: $mbsc-ios-danger;
        color: $mbsc-ios-light;
      }
    }

    &.mbsc-button-warning.mbsc-button-outline {
      border-color: $mbsc-ios-warning;
      color: $mbsc-ios-warning;

      &.mbsc-active {
        background: $mbsc-ios-warning;
        color: $mbsc-ios-light;
      }
    }

    &.mbsc-button-info.mbsc-button-outline {
      border-color: $mbsc-ios-info;
      color: $mbsc-ios-info;

      &.mbsc-active {
        background: $mbsc-ios-info;
        color: $mbsc-ios-light;
      }
    }

    &.mbsc-button-dark.mbsc-button-outline {
      border-color: $mbsc-ios-dark;
      color: $mbsc-ios-dark;

      &.mbsc-active {
        background: $mbsc-ios-dark;
        color: $mbsc-ios-light;
      }
    }

    &.mbsc-button-light.mbsc-button-outline {
      border-color: darken($mbsc-ios-light, 25%);
      color: darken($mbsc-ios-light, 25%);

      &.mbsc-active {
        background: darken($mbsc-ios-light, 25%);
        color: $mbsc-ios-light;
      }
    }
  }
}


@if ($mbsc-ios-theme and $mbsc-button) {
  .mbsc-ios {
    &.mbsc-button {
      padding: 0 0.5em;
      margin: 0.5em 0.25em;
      line-height: 2.25em;
      border-radius: 0.25em;
      transition: opacity 0.1s ease-out, background-color 0.1s ease-out;
    }

    &.mbsc-icon-button {
      padding: 0.5em;
      line-height: normal;
      border-radius: 4em;
    }

    &.mbsc-button.mbsc-hover {
      opacity: 0.7;
    }

    &.mbsc-button.mbsc-active {
      opacity: 0.5;
    }

    &.mbsc-button:disabled,
    &.mbsc-button.mbsc-disabled {
      opacity: 0.2;
    }

    &.mbsc-button-outline.mbsc-active {
      opacity: 1;
    }

    &.mbsc-ltr {
      &.mbsc-button-icon-start {
        padding-right: 0.375em;
      }

      &.mbsc-button-icon-end {
        padding-left: 0.375em;
      }
    }

    &.mbsc-rtl {
      &.mbsc-button-icon-start {
        padding-left: 0.375em;
      }

      &.mbsc-button-icon-end {
        padding-right: 0.375em;
      }
    }

    .mbsc-button-group,
    .mbsc-button-group-justified,
    .mbsc-button-group-block {
      margin: 0.5em 0.75em;
    }

    .mbsc-button-group-block {
      margin: 0.5em 1em;
    }
  }

  @include mbsc-ios-button('ios', $mbsc-ios-colors);
  @include mbsc-ios-button('ios-dark', $mbsc-ios-dark-colors);
}



$mbsc-material-accent: #1a73e8 !default;
$mbsc-material-background: #fff !default;
$mbsc-material-text: #303030 !default;

$mbsc-material-dark-accent: #87b0f3 !default;
$mbsc-material-dark-background: #000 !default;
$mbsc-material-dark-text: #fff !default;

/* Base colors */
$mbsc-material-primary: #3f97f6 !default;
$mbsc-material-secondary: #90979e !default;
$mbsc-material-success: #43be5f !default;
$mbsc-material-danger: #f5504e !default;
$mbsc-material-warning: #f8b042 !default;
$mbsc-material-info: #5bb7c5 !default;
$mbsc-material-light: #fff !default;
$mbsc-material-dark: #47494a !default;

$mbsc-material-error: #de3226;

/* Form colors */

$mbsc-material-form-background: $mbsc-form-background-light !default;
$mbsc-material-dark-form-background: $mbsc-form-background-dark !default;
$mbsc-material-form-text: $mbsc-form-text-light !default;
$mbsc-material-dark-form-text: $mbsc-form-text-dark !default;
$mbsc-material-form-accent: $mbsc-form-accent-light !default;
$mbsc-material-dark-form-accent: $mbsc-form-accent-dark !default;
$mbsc-material-form-error: $mbsc-form-error-light !default;
$mbsc-material-dark-form-error: $mbsc-form-error-dark !default;

/* Calendar colors (will be used by eventcalendar, calendar, range) */

$mbsc-material-calendar-background: $mbsc-calendar-background-light !default;
$mbsc-material-calendar-text: $mbsc-calendar-text-light !default;
$mbsc-material-calendar-accent: $mbsc-calendar-accent-light !default;
$mbsc-material-calendar-border: $mbsc-calendar-border-light !default;
$mbsc-material-calendar-mark: $mbsc-calendar-mark-light !default;
$mbsc-material-calendar-event: $mbsc-calendar-event-light !default;

$mbsc-material-dark-calendar-background: $mbsc-calendar-background-dark !default;
$mbsc-material-dark-calendar-text: $mbsc-calendar-text-dark !default;
$mbsc-material-dark-calendar-accent: $mbsc-calendar-accent-dark !default;
$mbsc-material-dark-calendar-border: $mbsc-calendar-border-dark !default;
$mbsc-material-dark-calendar-mark: $mbsc-calendar-mark-dark !default;
$mbsc-material-dark-calendar-event: $mbsc-calendar-event-dark !default;

$mbsc-material-colors: (
  // Colors map
  'background': $mbsc-material-background,
  'text': $mbsc-material-text,
  'accent': $mbsc-material-accent,

  'calendar-background': $mbsc-material-calendar-background,
  'calendar-text': $mbsc-material-calendar-text,
  'calendar-accent': $mbsc-material-calendar-accent,
  'calendar-border': $mbsc-material-calendar-border,
  'calendar-mark': $mbsc-material-calendar-mark,
  'calendar-event': $mbsc-material-calendar-event,

  'form-background': $mbsc-material-form-background,
  'form-text': $mbsc-material-form-text,
  'form-accent': $mbsc-material-form-accent,
  'form-error': $mbsc-material-form-error
);

$mbsc-material-dark-colors: (
  // Colors map
  'background': $mbsc-material-dark-background,
  'text': $mbsc-material-dark-text,
  'accent': $mbsc-material-dark-accent,

  'calendar-background': $mbsc-material-dark-calendar-background,
  'calendar-text': $mbsc-material-dark-calendar-text,
  'calendar-accent': $mbsc-material-dark-calendar-accent,
  'calendar-border': $mbsc-material-dark-calendar-border,
  'calendar-mark': $mbsc-material-dark-calendar-mark,
  'calendar-event': $mbsc-material-dark-calendar-event,

  'form-background': $mbsc-material-dark-form-background,
  'form-text': $mbsc-material-dark-form-text,
  'form-accent': $mbsc-material-dark-form-accent,
  'form-error': $mbsc-material-dark-form-error
);

@function mbsc-material-colors($params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  @return ();
}


$mbsc-material-button-color: $mbsc-button-color-light !default;
$mbsc-material-button-text: $mbsc-button-text-light !default;

$mbsc-material-dark-button-color: $mbsc-button-color-dark !default;
$mbsc-material-dark-button-text: $mbsc-button-text-dark !default;

$mbsc-material-colors: map-merge(
  $mbsc-material-colors,
  (
    'button-color': $mbsc-material-button-color,
    'button-text': $mbsc-material-button-text,
  )
);

$mbsc-material-dark-colors: map-merge(
  $mbsc-material-dark-colors,
  (
    'button-color': $mbsc-material-dark-button-color,
    'button-text': $mbsc-material-dark-button-text,
  )
);

@mixin mbsc-material-button($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $button-background-param: map-get($params, 'button-color');
  $button-text-param: map-get($params, 'button-text');

  $button-active: darken($background, 13%);
  $button-text: '';
  $button-background: '';

  @if (lightness($background) > 50%) {
    $button-text: darken($text, 36%);
    $button-background: darken($background, 19%);
  } @else {
    $button-text: lighten($text, 24%);
    $button-background: lighten($background, 17%);
  }

  $button-background: if($button-background-param, $button-background-param, $button-background);
  $button-text: if($button-text-param, $button-text-param, $button-text);
  $flatout-color: if($button-background-param, $button-background, $button-text);

  .mbsc-#{$theme} {
    &.mbsc-button-standard {
      background: $button-background;
      color: $button-text;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

      &.mbsc-hover {
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
      }

      &.mbsc-focus,
      &.mbsc-active {
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
      }
    }

    /* Flat buttons */
    &.mbsc-button-flat,
    &.mbsc-button-outline {
      color: $flatout-color;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($flatout-color, 0.2);
      }

      &.mbsc-focus {
        background: rgba($flatout-color, 0.3);
      }
    }

    /* Outline buttons */
    &.mbsc-button-outline {
      border-color: $flatout-color;
    }

    /* Predefined colors */
    &.mbsc-button-primary.mbsc-button-standard {
      background: $mbsc-material-primary;
      color: $background;
    }

    &.mbsc-button-secondary.mbsc-button-standard {
      background: $mbsc-material-secondary;
      color: $background;
    }

    &.mbsc-button-success.mbsc-button-standard {
      background: $mbsc-material-success;
      color: $background;
    }

    &.mbsc-button-danger.mbsc-button-standard {
      background: $mbsc-material-danger;
      color: $background;
    }

    &.mbsc-button-warning.mbsc-button-standard {
      background: $mbsc-material-warning;
      color: $background;
    }

    &.mbsc-button-info.mbsc-button-standard {
      background: $mbsc-material-info;
      color: $background;
    }

    &.mbsc-button-dark.mbsc-button-standard {
      background: $mbsc-material-dark;
      color: $background;
    }

    &.mbsc-button-light.mbsc-button-standard {
      background: $mbsc-material-light;
      color: $text;
    }

    &.mbsc-button-primary.mbsc-button-flat {
      color: $mbsc-material-primary;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-primary, 0.2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-primary, 0.3);
      }
    }

    &.mbsc-button-secondary.mbsc-button-flat {
      color: $mbsc-material-secondary;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-secondary, 0.2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-secondary, 0.3);
      }
    }

    &.mbsc-button-success.mbsc-button-flat {
      color: $mbsc-material-success;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-success, 0.2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-success, 0.3);
      }
    }

    &.mbsc-button-danger.mbsc-button-flat {
      color: $mbsc-material-danger;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-danger, 0.2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-danger, 0.3);
      }
    }

    &.mbsc-button-warning.mbsc-button-flat {
      color: $mbsc-material-warning;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-warning, 0.2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-warning, 0.3);
      }
    }

    &.mbsc-button-info.mbsc-button-flat {
      color: $mbsc-material-info;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-info, 0.2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-info, 0.3);
      }
    }

    &.mbsc-button-dark.mbsc-button-flat {
      color: $mbsc-material-dark;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-dark, 0.2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-dark, 0.3);
      }
    }

    &.mbsc-button-light.mbsc-button-flat {
      color: darken($mbsc-material-light, 20%);

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-light, 0.2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-light, 0.3);
      }
    }

    &.mbsc-button-primary.mbsc-button-outline {
      border-color: $mbsc-material-primary;
      color: $mbsc-material-primary;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-primary, 0.2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-primary, 0.3);
      }
    }

    &.mbsc-button-secondary.mbsc-button-outline {
      border-color: $mbsc-material-secondary;
      color: $mbsc-material-secondary;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-secondary, 0.2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-secondary, 0.3);
      }
    }

    &.mbsc-button-success.mbsc-button-outline {
      border-color: $mbsc-material-success;
      color: $mbsc-material-success;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-success, 0.2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-success, 0.3);
      }
    }

    &.mbsc-button-danger.mbsc-button-outline {
      border-color: $mbsc-material-danger;
      color: $mbsc-material-danger;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-danger, 0.2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-danger, 0.3);
      }
    }

    &.mbsc-button-warning.mbsc-button-outline {
      border-color: $mbsc-material-warning;
      color: $mbsc-material-warning;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-warning, 0.2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-warning, 0.3);
      }
    }

    &.mbsc-button-info.mbsc-button-outline {
      border-color: $mbsc-material-info;
      color: $mbsc-material-info;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-info, 0.2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-info, 0.3);
      }
    }

    &.mbsc-button-dark.mbsc-button-outline {
      border-color: $mbsc-material-dark;
      color: $mbsc-material-dark;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-dark, 0.2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-dark, 0.3);
      }
    }

    &.mbsc-button-light.mbsc-button-outline {
      border-color: darken($mbsc-material-light, 20%);
      color: darken($mbsc-material-light, 20%);

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-light, 0.2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-light, 0.3);
      }
    }
  }
}


@if ($mbsc-material-theme and $mbsc-button) {
  .mbsc-material {
    &.mbsc-button {
      min-width: 4.571429em;
      padding: 0.428572em 1.142858em;
      border-radius: 0.285715em;
      font-size: 0.875em;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1.714286em;
      transition: box-shadow 0.2s ease-out, background-color 0.2s ease-out;
      margin: 0.5em;
    }

    &.mbsc-button-outline {
      border: 1px solid;
    }

    &.mbsc-button .mbsc-icon {
      font-size: 1.142858em;
    }

    &.mbsc-icon-button {
      min-width: 0;
      padding: 0.5em;
      border-radius: 2em;
      font-size: 1em;
      line-height: normal;
    }

    &.mbsc-icon-button .mbsc-icon {
      width: 1.5em;
      height: 1.5em;
      font-size: 1em;
    }

    &.mbsc-button:disabled,
    &.mbsc-button.mbsc-disabled {
      opacity: 0.3;
    }

    &.mbsc-ltr {
      &.mbsc-button-icon-start {
        padding-right: 0.5em;
        margin-left: -0.25em;
      }

      &.mbsc-button-icon-end {
        padding-left: 0.5em;
        margin-right: -0.25em;
      }
    }

    &.mbsc-rtl {
      &.mbsc-button-icon-start {
        padding-left: 0.5em;
        margin-right: -0.25em;
      }

      &.mbsc-button-icon-end {
        padding-right: 0.5em;
        margin-left: -0.25em;
      }
    }

    .mbsc-button-group,
    .mbsc-button-group-justified {
      margin: 0.5em;
    }

    .mbsc-button-group-block {
      margin: 0.5em 1em;
    }
  }

  @include mbsc-material-button('material', $mbsc-material-colors);
  @include mbsc-material-button('material-dark', $mbsc-material-dark-colors);
}



$mbsc-windows-accent: #0078d7 !default;
$mbsc-windows-background: #ffffff !default;
$mbsc-windows-text: #333333 !default;

$mbsc-windows-dark-accent: #0078d7 !default;
$mbsc-windows-dark-background: #1a1a1a !default;
$mbsc-windows-dark-text: #ffffff !default;

/* Base colors */
$mbsc-windows-primary: #3f97f6 !default;
$mbsc-windows-secondary: #90979e !default;
$mbsc-windows-success: #43be5f !default;
$mbsc-windows-danger: #f5504e !default;
$mbsc-windows-warning: #f8b042 !default;
$mbsc-windows-info: #5bb7c5 !default;
$mbsc-windows-light: #fff !default;
$mbsc-windows-dark: #47494a !default;

$mbsc-windows-error: #a4262c;

/* Form colors */

$mbsc-windows-form-background: $mbsc-form-background-light !default;
$mbsc-windows-dark-form-background: $mbsc-form-background-dark !default;
$mbsc-windows-form-text: $mbsc-form-text-light !default;
$mbsc-windows-dark-form-text: $mbsc-form-text-dark !default;
$mbsc-windows-form-accent: $mbsc-form-accent-light !default;
$mbsc-windows-dark-form-accent: $mbsc-form-accent-dark !default;
$mbsc-windows-form-error: $mbsc-form-error-light !default;
$mbsc-windows-dark-form-error: $mbsc-form-error-dark !default;

/* Calendar colors (will be used by eventcalendar, calendar, range) */

$mbsc-windows-calendar-background: $mbsc-calendar-background-light !default;
$mbsc-windows-calendar-text: $mbsc-calendar-text-light !default;
$mbsc-windows-calendar-accent: $mbsc-calendar-accent-light !default;
$mbsc-windows-calendar-border: $mbsc-calendar-border-light !default;
$mbsc-windows-calendar-mark: $mbsc-calendar-mark-light !default;
$mbsc-windows-calendar-event: $mbsc-calendar-event-light !default;

$mbsc-windows-dark-calendar-background: $mbsc-calendar-background-dark !default;
$mbsc-windows-dark-calendar-text: $mbsc-calendar-text-dark !default;
$mbsc-windows-dark-calendar-accent: $mbsc-calendar-accent-dark !default;
$mbsc-windows-dark-calendar-border: $mbsc-calendar-border-dark !default;
$mbsc-windows-dark-calendar-mark: $mbsc-calendar-mark-dark !default;
$mbsc-windows-dark-calendar-event: $mbsc-calendar-event-dark !default;

$mbsc-windows-colors: (
  // Colors map
  'background': $mbsc-windows-background,
  'text': $mbsc-windows-text,
  'accent': $mbsc-windows-accent,

  'calendar-background': $mbsc-windows-calendar-background,
  'calendar-text': $mbsc-windows-calendar-text,
  'calendar-accent': $mbsc-windows-calendar-accent,
  'calendar-border': $mbsc-windows-calendar-border,
  'calendar-mark': $mbsc-windows-calendar-mark,
  'calendar-event': $mbsc-windows-calendar-event,

  'form-background': $mbsc-windows-form-background,
  'form-text': $mbsc-windows-form-text,
  'form-accent': $mbsc-windows-form-accent,
  'form-error': $mbsc-windows-form-error
);

$mbsc-windows-dark-colors: (
  // Colors map
  'background': $mbsc-windows-dark-background,
  'text': $mbsc-windows-dark-text,
  'accent': $mbsc-windows-dark-accent,

  'calendar-background': $mbsc-windows-dark-calendar-background,
  'calendar-text': $mbsc-windows-dark-calendar-text,
  'calendar-accent': $mbsc-windows-dark-calendar-accent,
  'calendar-border': $mbsc-windows-dark-calendar-border,
  'calendar-mark': $mbsc-windows-dark-calendar-mark,
  'calendar-event': $mbsc-windows-dark-calendar-event,

  'form-background': $mbsc-windows-dark-form-background,
  'form-text': $mbsc-windows-dark-form-text,
  'form-accent': $mbsc-windows-dark-form-accent,
  'form-error': $mbsc-windows-dark-form-error
);

@function mbsc-windows-colors($params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  @return ();
}


$mbsc-windows-button-color: $mbsc-button-color-light !default;
$mbsc-windows-button-text: $mbsc-button-text-light !default;

$mbsc-windows-dark-button-color: $mbsc-button-color-dark !default;
$mbsc-windows-dark-button-text: $mbsc-button-text-dark !default;

$mbsc-windows-colors: map-merge(
  $mbsc-windows-colors,
  (
    'button-color': $mbsc-windows-button-color,
    'button-text': $mbsc-windows-button-text,
  )
);

$mbsc-windows-dark-colors: map-merge(
  $mbsc-windows-dark-colors,
  (
    'button-color': $mbsc-windows-dark-button-color,
    'button-text': $mbsc-windows-dark-button-text,
  )
);

@mixin mbsc-windows-button($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $button-background-param: map-get($params, 'button-color');
  $button-text-param: map-get($params, 'button-text');

  $button-text: if($button-text-param, $button-text-param, $text);

  $button-bg: '';
  $hover: '';
  $button-border: '';
  $button-border-hover: '';
  $dark-text: darken($mbsc-windows-dark, 30%);

  @if (lightness($background) > 50%) {
    $button-bg: darken($background, 20%);
    $hover: lighten($button-text, 70%);
    $button-border: darken($background, 40%);
    $button-border-hover: darken($background, 50%);
  } @else {
    $button-bg: lighten($background, 15%);
    $hover: darken($button-text, 70%);
    $button-border: lighten($background, 35%);
    $button-border-hover: lighten($background, 45%);
  }

  $button-bg: if($button-background-param, $button-background-param, $button-bg);

  @if ($button-background-param) {
    $button-border: $button-background-param;

    @if (lightness($button-background-param) > 50%) {
      $button-border-hover: darken($button-border, 10%);
    } @else {
      $button-border-hover: lighten($button-border, 10%);
    }
  }

  $active-base: if($button-background-param, $button-background-param, $background);
  $button-active: '';

  @if (lightness($active-base) > 50%) {
    $button-active: lighten($button-text, 28%);
  } @else {
    $button-active: darken($button-text, 33%);
  }

  $flat-color: if($button-background-param, $button-bg, $button-text);
  $flat-active: if($button-background-param, $button-text, $button-bg);

  .mbsc-#{$theme} {
    &.mbsc-button-standard {
      background: $button-bg;
      border-color: $button-bg;
      color: $button-text;

      &.mbsc-hover {
        border-color: $button-active;
      }

      &.mbsc-active {
        border-color: $button-active;
        background: $button-active;
      }
    }

    /* Flat buttons */
    &.mbsc-button-flat {
      color: $flat-color;

      &.mbsc-active {
        background: lighten($button-bg, 15%);
        border-color: lighten($button-bg, 15%);
        color: $button-text;
      }
    }

    /* Outline buttons */
    &.mbsc-button-outline {
      border: 0.125em solid $button-border;
      color: $button-border;

      &.mbsc-hover {
        border-color: $button-border-hover;
      }

      &.mbsc-active {
        border-color: $button-border-hover;
        background: $button-border-hover;
        color: get-contrast-color($button-border-hover);
      }
    }

    &.mbsc-button.mbsc-focus {
      // background: rgba($accent, .3);
      box-shadow: 0 0 0 1px $button-text;
    }

    /* Predefined colors */
    &.mbsc-button-primary.mbsc-button-standard {
      background: $mbsc-windows-primary;
      border-color: $mbsc-windows-primary;
      color: get-contrast-color($mbsc-windows-primary);
    }

    &.mbsc-button-secondary.mbsc-button-standard {
      background: $mbsc-windows-secondary;
      border-color: $mbsc-windows-secondary;
      color: get-contrast-color($mbsc-windows-secondary);
    }

    &.mbsc-button-success.mbsc-button-standard {
      background: $mbsc-windows-success;
      border-color: $mbsc-windows-success;
      color: get-contrast-color($mbsc-windows-success);
    }

    &.mbsc-button-danger.mbsc-button-standard {
      background: $mbsc-windows-danger;
      border-color: $mbsc-windows-danger;
      color: get-contrast-color($mbsc-windows-danger);
    }

    &.mbsc-button-warning.mbsc-button-standard {
      background: $mbsc-windows-warning;
      border-color: $mbsc-windows-warning;
      color: get-contrast-color($mbsc-windows-warning);
    }

    &.mbsc-button-info.mbsc-button-standard {
      background: $mbsc-windows-info;
      border-color: $mbsc-windows-info;
      color: get-contrast-color($mbsc-windows-info);
    }

    &.mbsc-button-dark.mbsc-button-standard {
      background: $mbsc-windows-dark;
      border-color: $mbsc-windows-dark;
      color: get-contrast-color($mbsc-windows-dark);
    }

    &.mbsc-button-light.mbsc-button-standard {
      background: $mbsc-windows-light;
      border-color: $mbsc-windows-light;
      color: $dark-text;
    }

    &.mbsc-button-primary.mbsc-button-flat {
      color: $mbsc-windows-primary;

      &.mbsc-active {
        background: lighten($mbsc-windows-primary, 15%);
        border-color: lighten($mbsc-windows-primary, 15%);
      }
    }

    &.mbsc-button-secondary.mbsc-button-flat {
      color: $mbsc-windows-secondary;

      &.mbsc-active {
        background: lighten($mbsc-windows-secondary, 15%);
        border-color: lighten($mbsc-windows-secondary, 15%);
      }
    }

    &.mbsc-button-success.mbsc-button-flat {
      color: $mbsc-windows-success;

      &.mbsc-active {
        background: lighten($mbsc-windows-success, 15%);
        border-color: lighten($mbsc-windows-success, 15%);
      }
    }

    &.mbsc-button-danger.mbsc-button-flat {
      color: $mbsc-windows-danger;

      &.mbsc-active {
        background: lighten($mbsc-windows-danger, 15%);
        border-color: lighten($mbsc-windows-danger, 15%);
      }
    }

    &.mbsc-button-warning.mbsc-button-flat {
      color: $mbsc-windows-warning;

      &.mbsc-active {
        background: lighten($mbsc-windows-warning, 15%);
        border-color: lighten($mbsc-windows-warning, 15%);
      }
    }

    &.mbsc-button-info.mbsc-button-flat {
      color: $mbsc-windows-info;

      &.mbsc-active {
        background: lighten($mbsc-windows-info, 15%);
        border-color: lighten($mbsc-windows-info, 15%);
      }
    }

    &.mbsc-button-dark.mbsc-button-flat {
      color: $mbsc-windows-dark;

      &.mbsc-active {
        background: lighten($mbsc-windows-dark, 15%);
        border-color: lighten($mbsc-windows-dark, 15%);
      }
    }

    &.mbsc-button-light.mbsc-button-flat {
      color: darken($mbsc-windows-light, 20%);

      &.mbsc-active {
        background: lighten($mbsc-windows-light, 15%);
        border-color: lighten($mbsc-windows-light, 15%);
      }
    }

    &.mbsc-button-primary.mbsc-button-outline {
      border-color: $mbsc-windows-primary;
      color: $mbsc-windows-primary;
    }

    &.mbsc-button-secondary.mbsc-button-outline {
      border-color: $mbsc-windows-secondary;
      color: $mbsc-windows-secondary;
    }

    &.mbsc-button-success.mbsc-button-outline {
      border-color: $mbsc-windows-success;
      color: $mbsc-windows-success;
    }

    &.mbsc-button-danger.mbsc-button-outline {
      border-color: $mbsc-windows-danger;
      color: $mbsc-windows-danger;
    }

    &.mbsc-button-warning.mbsc-button-outline {
      border-color: $mbsc-windows-warning;
      color: $mbsc-windows-warning;
    }

    &.mbsc-button-info.mbsc-button-outline {
      border-color: $mbsc-windows-info;
      color: $mbsc-windows-info;
    }

    &.mbsc-button-dark.mbsc-button-outline {
      border-color: $mbsc-windows-dark;
      color: $mbsc-windows-dark;
    }

    &.mbsc-button-light.mbsc-button-outline {
      border-color: darken($mbsc-windows-light, 20%);
      color: darken($mbsc-windows-light, 20%);
    }

    /* Predefined colors - hover for standard and outline buttons */
    &.mbsc-button-primary.mbsc-button-standard,
    &.mbsc-button-primary.mbsc-button-outline {
      &.mbsc-hover {
        border-color: darken($mbsc-windows-primary, 20%);
      }

      &.mbsc-active {
        background: darken($mbsc-windows-primary, 20%);
        color: $mbsc-windows-light;
      }
    }

    &.mbsc-button-secondary.mbsc-button-standard,
    &.mbsc-button-secondary.mbsc-button-outline {
      &.mbsc-hover {
        border-color: darken($mbsc-windows-secondary, 20%);
      }

      &.mbsc-active {
        background: darken($mbsc-windows-secondary, 20%);
        color: $mbsc-windows-light;
      }
    }

    &.mbsc-button-success.mbsc-button-standard,
    &.mbsc-button-success.mbsc-button-outline {
      &.mbsc-hover {
        border-color: darken($mbsc-windows-success, 20%);
      }

      &.mbsc-active {
        background: darken($mbsc-windows-success, 20%);
        color: $mbsc-windows-light;
      }
    }

    &.mbsc-button-danger.mbsc-button-standard,
    &.mbsc-button-danger.mbsc-button-outline {
      &.mbsc-hover {
        border-color: darken($mbsc-windows-danger, 20%);
      }

      &.mbsc-active {
        background: darken($mbsc-windows-danger, 20%);
        color: $mbsc-windows-light;
      }
    }

    &.mbsc-button-warning.mbsc-button-standard,
    &.mbsc-button-warning.mbsc-button-outline {
      &.mbsc-hover {
        border-color: darken($mbsc-windows-warning, 20%);
      }

      &.mbsc-active {
        background: darken($mbsc-windows-warning, 20%);
        color: $mbsc-windows-light;
      }
    }

    &.mbsc-button-info.mbsc-button-standard,
    &.mbsc-button-info.mbsc-button-outline {
      &.mbsc-hover {
        border-color: darken($mbsc-windows-info, 20%);
      }

      &.mbsc-active {
        background: darken($mbsc-windows-info, 20%);
        color: $mbsc-windows-light;
      }
    }

    &.mbsc-button-dark.mbsc-button-standard,
    &.mbsc-button-dark.mbsc-button-outline {
      &.mbsc-hover {
        border-color: darken($mbsc-windows-dark, 30%);
      }

      &.mbsc-active {
        background: darken($mbsc-windows-dark, 30%);
        color: $mbsc-windows-light;
      }
    }

    &.mbsc-button-light.mbsc-button-standard,
    &.mbsc-button-light.mbsc-button-outline {
      &.mbsc-hover {
        border-color: darken($mbsc-windows-light, 40%);
      }

      &.mbsc-active {
        background: darken($mbsc-windows-light, 40%);
        color: $mbsc-windows-dark;
      }
    }
  }
}


@if ($mbsc-windows-theme and $mbsc-button) {
  .mbsc-windows {
    &.mbsc-button {
      padding: 0 0.5em;
      line-height: 1.75em;
      margin: 0.5em 0.25em;
      border: 0.125em solid transparent;
      transition: border-color 0.1s ease-out, background-color 0.1s ease-out;
    }

    &.mbsc-icon-button {
      width: 2.75em;
      height: 2em;
    }

    &.mbsc-button-flat {
      transition: background-color 0.1s ease-out;

      &.mbsc-hover {
        opacity: 0.7;
      }

      &.mbsc-active {
        opacity: 1;
      }
    }

    &.mbsc-button:disabled,
    &.mbsc-button.mbsc-disabled {
      opacity: 0.3;
    }

    &.mbsc-ltr {
      &.mbsc-button-icon-start {
        padding-right: 0.5em;
      }

      &.mbsc-button-icon-end {
        padding-left: 0.5em;
      }
    }

    &.mbsc-rtl {
      &.mbsc-button-icon-start {
        padding-left: 0.5em;
      }

      &.mbsc-button-icon-end {
        padding-right: 0.5em;
      }
    }

    .mbsc-button-group,
    .mbsc-button-group-justified {
      margin: 0.5em 0.75em;
    }

    .mbsc-button-group-block {
      margin: 0.5em 1em;
    }
  }

  @include mbsc-windows-button('windows', $mbsc-windows-colors);
  @include mbsc-windows-button('windows-dark', $mbsc-windows-dark-colors);
}


@if $mbsc-button {
  .mbsc-button {
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: middle;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    font-size: 1em;
    box-sizing: border-box;
    touch-action: manipulation;
    user-select: none;
    white-space: nowrap;
    cursor: pointer;
  }

  .mbsc-button:disabled,
  .mbsc-button.mbsc-disabled {
    cursor: not-allowed;
  }

  .mbsc-button:focus {
    outline: 0;
  }

  .mbsc-button::-moz-focus-inner {
    border: 0;
  }

  .mbsc-icon-button {
    width: 2.5em;
    height: 2.5em;
    padding: 0.5em;
    flex: 0 0 auto;
  }

  .mbsc-button-icon > *,
  .mbsc-button-icon > * > * {
    // For custom icon markup
    height: 100%;
  }

  .mbsc-button-icon-end {
    // Needed by javascript/jquery component, where end icon is before the button text in the markup
    order: 1;
  }

  .mbsc-button-group,
  .mbsc-button-group-block,
  .mbsc-button-group-justified {
    // Prevent margin collision
    border: 1px solid transparent;
  }

  .mbsc-button-group-block .mbsc-button,
  .mbsc-button-block {
    display: block;
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mbsc-button-group-justified,
  .mbsc-button-group-justified mbsc-button {
    display: flex;
    flex-wrap: wrap;
  }

  .mbsc-button-group-justified .mbsc-button,
  .mbsc-button-group-justified mbsc-button {
    flex: 1 auto;
  }
}



@mixin mbsc-mobiscroll-popup($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  .mbsc-#{$theme} {
    &.mbsc-popup {
      background: $background;
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
      color: $text;
    }

    &.mbsc-popup-arrow {
      background: $background;
      box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
    }
  }
}


@if ($mbsc-mobiscroll-theme and $mbsc-popup) {
  @include mbsc-mobiscroll-popup('mobiscroll', $mbsc-mobiscroll-colors);
  @include mbsc-mobiscroll-popup('mobiscroll-dark', $mbsc-mobiscroll-dark-colors);
}



@mixin mbsc-ios-popup($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $is-dark: false;
  $button-hover: '';
  $buttons-background: '';
  $popup-border: '';
  $popup-background: '';

  @if (lightness($background) > 50%) {
    $button-hover: darken($background, 5%);
    $buttons-background: $background; // darken($background, 3%);
    $popup-border: darken($background, 17%);
    $popup-background: adjust-hue(darken(saturate($background, 24%), 1%), 240deg);
  } @else {
    $is-dark: true;
    $button-hover: lighten($background, 14%);
    $buttons-background: lighten($background, 16%);
    $popup-border: lighten($background, 20%);
    $popup-background: lighten($background, 11%);
  }

  .mbsc-#{$theme} {
    &.mbsc-popup-arrow {
      background: $popup-background;
      // border-color: $popup-border;
      box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
    }

    &.mbsc-popup-top,
    &.mbsc-popup-bottom {
      border-color: $popup-border;
    }

    &.mbsc-popup-body {
      background: $popup-background;
      // border-color: $popup-border;
      color: $text;
    }

    &.mbsc-popup-body-round,
    &.mbsc-popup-body-center,
    &.mbsc-popup-body-anchored {
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    }

    &.mbsc-popup-header-center {
      border-color: $popup-border;
    }

    &.mbsc-popup-buttons,
    &.mbsc-popup-header-no-buttons {
      border-color: $popup-border;
    }

    &.mbsc-popup-buttons-bottom,
    &.mbsc-popup-buttons-top {
      background: $buttons-background;
    }

    @if ($is-dark) {
      &.mbsc-popup-buttons-anchored {
        background: $buttons-background;
      }
    }

    &.mbsc-popup-button-flex.mbsc-button-flat.mbsc-font {
      border-color: $popup-border;
    }

    &.mbsc-popup-button-flex.mbsc-font.mbsc-hover,
    &.mbsc-popup-button-flex.mbsc-font.mbsc-focus {
      background: $button-hover;
    }

    &.mbsc-popup-button-flex.mbsc-font.mbsc-active {
      background: $popup-border;
    }

    &.mbsc-popup-button-flex.mbsc-button.mbsc-disabled {
      color: rgba($accent, 0.2);
    }
  }
}


@if ($mbsc-ios-theme and $mbsc-popup) {
  .mbsc-ios {
    &.mbsc-popup-wrapper-bottom-full {
      padding-top: 1em;
    }

    &.mbsc-popup-wrapper-top-full {
      padding-bottom: 1em;
    }

    // &.mbsc-popup-overlay {
    //   background: rgba(0, 0, 0, .2);
    // }

    &.mbsc-popup-top {
      border-bottom: 1px solid;
    }

    &.mbsc-popup-bottom {
      border-top: 1px solid;
    }

    &.mbsc-popup-body-center {
      border-radius: 0.9375em;
    }

    &.mbsc-popup-body-bottom-full {
      border-radius: 0.75em 0.75em 0 0;
    }

    &.mbsc-popup-body-top-full {
      border-radius: 0 0 0.75em 0.75em;
    }

    &.mbsc-popup-body-anchored {
      border-radius: 0.9375em;
    }

    &.mbsc-popup-arrow {
      border-radius: 0 0.375em;
    }

    &.mbsc-popup-header {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      left: 0;
      line-height: 1.25em;
      padding: 0.75em 4.375em;
      font-weight: bold;
      text-align: center;
    }

    &.mbsc-popup-header-no-buttons {
      position: relative;
      border-bottom: 1px solid;
    }

    &.mbsc-popup-header-center {
      position: relative;
      padding: 0.75em 0.5em;
      border-bottom: 1px solid;
    }

    &.mbsc-popup-top-full .mbsc-popup-header,
    &.mbsc-popup-bottom-full .mbsc-popup-header {
      padding: 1.125em 4.375em;
    }

    &.mbsc-popup-buttons {
      order: -1;
      border-bottom: 1px solid;
    }

    &.mbsc-popup-buttons.mbsc-ltr {
      text-align: right;
    }

    &.mbsc-popup-buttons.mbsc-rtl {
      text-align: left;
    }

    &.mbsc-popup-button.mbsc-font {
      // line-height: 2.75em;
      margin-top: 0.25em;
      margin-bottom: 0.25em;
      z-index: 2;
    }

    &.mbsc-popup-button-primary {
      font-weight: bold;
    }

    &.mbsc-popup-button-close.mbsc-ltr {
      float: left;
    }

    &.mbsc-popup-button-close.mbsc-rtl {
      float: right;
    }

    &.mbsc-popup-buttons-anchored,
    &.mbsc-popup-buttons-top,
    &.mbsc-popup-buttons-bottom {
      padding: 0 0.25em;
    }

    &.mbsc-popup-buttons-top-full,
    &.mbsc-popup-buttons-bottom-full {
      padding: 0.375em 0.25em;
    }

    /* Button styling for centered popup and desktop styled top/bottom popup */

    &.mbsc-popup-buttons-flex {
      order: 0;
      border: 0;
      padding: 0;
    }

    &.mbsc-popup-buttons-flex.mbsc-popup-buttons {
      background: none;
    }

    &.mbsc-popup-button-flex.mbsc-font {
      flex: 1 1 100%;
      display: block;
    }

    &.mbsc-popup-button-flex.mbsc-font.mbsc-button {
      opacity: 1;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }

    &.mbsc-popup-button-flex.mbsc-font.mbsc-button-flat {
      background: none;
      border-top: 1px solid;
      border-radius: 0;
      margin: 0;
      line-height: 2.75em;
    }

    &.mbsc-popup-button-flex.mbsc-button-flat.mbsc-ltr {
      border-right-style: solid;
      border-right-width: 1px;
    }

    &.mbsc-popup-button-flex.mbsc-button-flat.mbsc-rtl {
      border-left-style: solid;
      border-left-width: 1px;
    }

    &.mbsc-popup-button-flex.mbsc-button-flat:last-child {
      border-left: 0;
      border-right: 0;
    }

    /* Desktop styling */

    &.mbsc-popup-body-round {
      border-radius: 0.9375em;
    }

    &.mbsc-popup-pointer {
      .mbsc-popup-body-round {
        border-radius: 0.5em;
      }
    }

    &.mbsc-popup-round {
      .mbsc-popup {
        border: 0;
      }

      // .mbsc-popup-body-bottom.mbsc-popup-short,
      .mbsc-popup-body-bottom-full {
        border-radius: 0.75em 0.75em 0 0;
      }

      // .mbsc-popup-body-top.mbsc-popup-short,
      .mbsc-popup-body-top-full {
        border-radius: 0 0 0.75em 0.75em;
      }
    }
  }

  @include mbsc-ios-popup('ios', $mbsc-ios-colors);
  @include mbsc-ios-popup('ios-dark', $mbsc-ios-dark-colors);
}



@mixin mbsc-material-popup($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $popup-background: '';

  @if (lightness($background) > 50%) {
    $popup-background: $background;
  } @else {
    $popup-background: lighten($background, 19%);
  }

  .mbsc-#{$theme} {
    &.mbsc-popup-body {
      background: $popup-background;
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
      color: $text;
    }

    &.mbsc-popup-arrow {
      background: $popup-background;
      box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
    }

    &.mbsc-popup-button.mbsc-font {
      color: $accent;
    }
  }
}


@if ($mbsc-material-theme and $mbsc-popup) {
  .mbsc-material {
    &.mbsc-popup-body-center,
    &.mbsc-popup-body-anchored,
    &.mbsc-popup-body-round {
      border-radius: 0.25em;
    }

    &.mbsc-popup-header {
      font-size: 1.25em;
      font-weight: 500;
      padding: 0.8em 0.8em 0 0.8em;
      line-height: 1.4em;
    }

    &.mbsc-popup-buttons {
      padding: 0.5em;
    }

    &.mbsc-popup-buttons.mbsc-ltr {
      text-align: right;
    }

    &.mbsc-popup-buttons.mbsc-rtl {
      text-align: right;
    }

    &.mbsc-popup-button.mbsc-button-flat {
      margin-left: 0;
      margin-right: 0;
    }

    &.mbsc-popup-button.mbsc-font {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 500;
      font-size: 0.9375em;
    }

    &.mbsc-popup-round {
      .mbsc-popup-body-bottom-full {
        border-radius: 0.25em 0.25em 0 0;
      }

      .mbsc-popup-body-top-full {
        border-radius: 0 0 0.25em 0.25em;
      }
    }
  }

  @include mbsc-material-popup('material', $mbsc-material-colors);
  @include mbsc-material-popup('material-dark', $mbsc-material-dark-colors);
}



@mixin mbsc-windows-popup($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $border: '';

  @if (lightness($background) > 50%) {
    $border: darken($background, 10%);
  } @else {
    $border: lighten($background, 10%);
  }

  .mbsc-#{$theme} {
    &.mbsc-popup {
      background: $background;
      border-color: $border;
      box-shadow: rgba(0, 0, 0, 0.133) 0 6px 14px 0, rgba(0, 0, 0, 0.11) 0 1px 4px 0;
      color: $text;
    }

    &.mbsc-popup-arrow {
      background: $background;
      border-color: $border;
    }

    &.mbsc-popup-header,
    &.mbsc-popup-buttons {
      border-color: $border;
    }
  }
}


@if ($mbsc-windows-theme and $mbsc-popup) {
  .mbsc-windows {
    &.mbsc-popup {
      border: 1px solid;
    }

    &.mbsc-popup-anchored-top {
      margin-top: -0.75em;
    }

    &.mbsc-popup-anchored-bottom {
      margin-top: 0.75em;
    }

    &.mbsc-popup-arrow {
      width: 1em;
      height: 1em;
      border: 1px solid;
    }

    &.mbsc-popup-arrow-bottom,
    &.mbsc-popup-arrow-top {
      margin-left: -0.5em;
    }

    &.mbsc-popup-arrow-left,
    &.mbsc-popup-arrow-right {
      margin-top: -0.5em;
    }

    &.mbsc-popup-arrow-bottom {
      top: 1.5em;
    }

    &.mbsc-popup-arrow-top {
      bottom: 1.5em;
    }

    &.mbsc-popup-arrow-left {
      right: 1.5em;
    }

    &.mbsc-popup-arrow-right {
      left: 1.5em;
    }

    &.mbsc-popup-header {
      padding: 0.5em;
      font-weight: bold;
      text-align: center;
      border-bottom: 1px solid;
    }

    &.mbsc-popup-buttons {
      display: flex;
      flex-direction: row-reverse;
      border-top: 1px solid;
    }

    &.mbsc-popup-button.mbsc-font {
      flex: 1 1;
      padding-top: 0.25em;
      padding-bottom: 0.25em;
    }

    &.mbsc-popup-button.mbsc-button-flat {
      margin: 0;
    }
  }

  @include mbsc-windows-popup('windows', $mbsc-windows-colors);
  @include mbsc-windows-popup('windows-dark', $mbsc-windows-dark-colors);
}


@if $mbsc-popup {
  @keyframes mbsc-fade-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes mbsc-fade-out {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @keyframes mbsc-pop-in {
    from {
      opacity: 0;
      transform: scale(0.8);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes mbsc-pop-out {
    from {
      opacity: 1;
      transform: scale(1);
    }

    to {
      opacity: 0;
      transform: scale(0.8);
    }
  }

  @keyframes mbsc-slide-up-in {
    from {
      transform: translateY(100%);
    }

    to {
      transform: translateY(0);
    }
  }

  @keyframes mbsc-slide-up-out {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(100%);
    }
  }

  @keyframes mbsc-slide-down-in {
    from {
      transform: translateY(-100%);
    }

    to {
      transform: translateY(0);
    }
  }

  @keyframes mbsc-slide-down-out {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(-100%);
    }
  }

  .mbsc-popup-open-ios {
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
  }

  .mbsc-popup-ctx {
    position: relative;
    --mbsc-safe-top: 0;
    --mbsc-safe-right: 0;
    --mbsc-safe-bottom: 0;
    --mbsc-safe-left: 0;
  }

  .mbsc-popup-limits {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .mbsc-popup-limits-anchored,
  .mbsc-popup-limits-center {
    margin: 1em;
  }

  .mbsc-popup,
  .mbsc-popup-wrapper {
    box-sizing: border-box;
  }

  .mbsc-popup-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    justify-content: center;
    border: 0 solid transparent;
    z-index: 99998;
    user-select: none;
  }

  .mbsc-popup-wrapper-ctx {
    position: absolute;
    right: auto;
    bottom: auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .mbsc-popup-wrapper-center {
    padding: 1em;
    align-items: center;
    border-width: var(--mbsc-safe-top) var(--mbsc-safe-right) var(--mbsc-safe-bottom) var(--mbsc-safe-left);
  }

  .mbsc-popup-wrapper-top {
    align-items: flex-start;
    border-bottom: var(--mbsc-safe-bottom) solid transparent;
  }

  .mbsc-popup-wrapper-bottom {
    align-items: flex-end;
    border-top-width: var(--mbsc-safe-top);
  }

  .mbsc-popup-wrapper-anchored {
    border-width: 0 var(--mbsc-safe-right) var(--mbsc-safe-bottom) var(--mbsc-safe-left);
  }

  .mbsc-popup-hidden {
    opacity: 0;
  }

  .mbsc-popup-overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -10em;
    pointer-events: auto;
    background: rgba(0, 0, 0, 0.5);
    transform: translateZ(0);
  }

  .mbsc-popup-overlay-in {
    opacity: 1;
    animation: mbsc-fade-in 150ms cubic-bezier(0, 0, 0.2, 1);
  }

  .mbsc-popup-overlay-out {
    opacity: 0;
    animation: mbsc-fade-out 75ms cubic-bezier(0.4, 0, 1, 1);
  }

  .mbsc-popup {
    max-height: 100%;
    min-width: 12em;
    position: relative;
    z-index: 2;
    pointer-events: auto;
    text-shadow: none;
    user-select: none;
  }

  .mbsc-popup-center {
    min-width: 16em;
  }

  .mbsc-popup-bottom {
    width: 100%;
    bottom: 0;
  }

  .mbsc-popup-top {
    width: 100%;
    top: 0;
  }

  .mbsc-popup-anchored {
    position: absolute;
    // max-width: calc(100% - 16px);
  }

  // .mbsc-popup-anchored-top {
  //   margin-top: -1em;
  // }

  // .mbsc-popup-anchored-bottom {
  //   margin-top: 1em;
  // }

  .mbsc-popup-anchored-left {
    margin-left: -1em;
  }

  .mbsc-popup-anchored-right {
    margin-left: 1em;
  }

  .mbsc-popup-arrow-wrapper {
    position: absolute;
    z-index: 1;
    overflow: hidden;
    pointer-events: none;
  }

  .mbsc-popup-arrow-wrapper-top,
  .mbsc-popup-arrow-wrapper-bottom {
    left: 0;
    right: 0;
    height: 2em;
  }

  .mbsc-popup-arrow-wrapper-left,
  .mbsc-popup-arrow-wrapper-right {
    top: 0;
    bottom: 0;
    width: 2em;
  }

  .mbsc-popup-arrow-wrapper-top {
    top: 100%;
  }

  .mbsc-popup-arrow-wrapper-bottom {
    bottom: 100%;
  }

  .mbsc-popup-arrow-wrapper-left {
    left: 100%;
  }

  .mbsc-popup-arrow-wrapper-right {
    right: 100%;
  }

  .mbsc-popup-arrow {
    position: absolute;
    box-sizing: border-box;
    width: 1.5em;
    height: 1.5em;
    pointer-events: auto;
  }

  .mbsc-popup-arrow-top {
    bottom: 1.25em;
  }

  .mbsc-popup-arrow-bottom {
    top: 1.25em;
  }

  .mbsc-popup-arrow-top,
  .mbsc-popup-arrow-bottom {
    left: 50%;
    margin-left: -0.75em;
    transform: rotate(-45deg);
  }

  .mbsc-popup-arrow-left {
    right: 1.25em;
  }

  .mbsc-popup-arrow-right {
    left: 1.25em;
  }

  .mbsc-popup-arrow-left,
  .mbsc-popup-arrow-right {
    top: 50%;
    margin-top: -0.75em;
    transform: rotate(45deg);
  }

  .mbsc-popup-focus {
    outline: 0;
  }

  .mbsc-popup-body {
    overflow: hidden;
    transform: translateZ(0);
  }

  .mbsc-popup-body-top {
    padding-top: var(--mbsc-safe-top);
  }

  .mbsc-popup-body-bottom {
    padding-bottom: var(--mbsc-safe-bottom);
  }

  .mbsc-popup-body-top,
  .mbsc-popup-body-bottom {
    padding-left: var(--mbsc-safe-left);
    padding-right: var(--mbsc-safe-right);
  }

  .mbsc-popup-body-round {
    padding: 0;
  }

  .mbsc-popup-header {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .mbsc-popup-content {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .mbsc-popup-padding {
    padding: 1em;
  }

  /* Animations */

  .mbsc-popup-pop-in {
    opacity: 1;
    animation: mbsc-pop-in 150ms cubic-bezier(0, 0, 0.2, 1);
  }

  .mbsc-popup-pop-out {
    opacity: 0;
    animation: mbsc-pop-out 75ms cubic-bezier(0.4, 0, 1, 1);
  }

  .mbsc-popup-slide-up-in {
    animation: mbsc-slide-up-in 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .mbsc-popup-slide-up-out {
    animation: mbsc-slide-up-out 200ms cubic-bezier(0.4, 0, 1, 1);
  }

  .mbsc-popup-slide-down-in {
    animation: mbsc-slide-down-in 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .mbsc-popup-slide-down-out {
    animation: mbsc-slide-down-out 200ms cubic-bezier(0.4, 0, 1, 1);
  }

  /* Desktop styling */

  .mbsc-popup-pointer {
    &.mbsc-picker .mbsc-popup-overlay,
    .mbsc-popup-overlay-anchored {
      background: none;
    }
  }

  .mbsc-popup-round {
    .mbsc-popup-slide-up-in {
      animation-name: mbsc-slide-up-in, mbsc-fade-in;
    }

    .mbsc-popup-slide-up-out {
      animation-name: mbsc-slide-up-out, mbsc-fade-out;
    }

    .mbsc-popup-slide-down-in {
      animation-name: mbsc-slide-down-in, mbsc-fade-in;
    }

    .mbsc-popup-slide-down-out {
      animation-name: mbsc-slide-down-out, mbsc-fade-out;
    }

    .mbsc-popup-top,
    .mbsc-popup-bottom {
      width: auto;
      margin-top: 3em;
      margin-bottom: 3em;
    }

    // .mbsc-popup-short {
    //   margin-top: 0;
    //   margin-bottom: 0;
    // }
  }
}



@mixin mbsc-mobiscroll-calendar-view($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  .mbsc-#{$theme} {
    &.mbsc-calendar-wrapper-fixed {
      border-bottom: 1px solid $accent;
    }

    &.mbsc-calendar-week-day {
      border-bottom: 1px solid $accent;
      color: $accent;
    }

    &.mbsc-focus .mbsc-calendar-day-text {
      box-shadow: 0 0 0 2px rgba($text, 0.5);
    }

    &.mbsc-hover .mbsc-calendar-day-text {
      background: rgba($accent, 0.3);
    }

    &.mbsc-selected .mbsc-calendar-day-text {
      background: $accent;
      color: $background;
    }

    /* Week numbers */
    .mbsc-calendar-week-nr {
      color: $accent;
    }

    /* Marks */
    &.mbsc-calendar-mark {
      background: $text;
    }

    /* Labels */
    &.mbsc-calendar-label {
      color: $background;
      background: $text;
    }
  }
}


@if ($mbsc-mobiscroll-theme and $mbsc-calendar-view) {
  .mbsc-mobiscroll {
    &.mbsc-calendar-header {
      padding: 0.5em;
    }

    &.mbsc-calendar-slide {
      padding: 0 0.5em 0.5em 0.5em;
    }

    &.mbsc-calendar-day-text {
      width: 2.153847em;
      height: 2.153847em;
      margin: 0.307692em auto;
      line-height: 2.153847em;
      font-size: 0.8125em;
      border: 2px solid transparent;
      border-radius: 2em;
      box-sizing: content-box;
    }

    /* Week numbers */
    .mbsc-calendar-week-nr {
      width: 2.363637em;
      font-size: 0.6875em;
    }
  }

  @include mbsc-mobiscroll-calendar-view('mobiscroll', $mbsc-mobiscroll-colors);
  @include mbsc-mobiscroll-calendar-view('mobiscroll-dark', $mbsc-mobiscroll-dark-colors);
}



@mixin mbsc-ios-calendar-view($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $background-param: map-get($params, 'calendar-background');
  $text-param: map-get($params, 'calendar-text');
  $accent-param: map-get($params, 'calendar-accent');
  $border-param: map-get($params, 'calendar-border');
  $mark-param: map-get($params, 'calendar-mark');
  $event-param: map-get($params, 'calendar-event');

  $background: if($background-param, $background-param, $background);
  $text: if($text-param, $text-param, if($background-param, get-contrast-color($background), $text));
  $accent: if($accent-param, $accent-param, $accent);
  $event: if($event-param, $event-param, #5ac8fa);

  $background-alt: '';
  $border-color: '';
  $popup-background: '';
  $popup-cell: '';
  $hover: '';

  @if (lightness($background) > 50%) {
    $background-alt: lighten($background, 3%);
    $border-color: darken($background, 17%);
    $popup-background: $background-alt;
    $popup-cell: $background-alt;
    $hover: darken($background, 10%);
  } @else {
    $background-alt: $background;
    $border-color: lighten($background, 20%);
    $popup-background: lighten($background, 16%);
    $popup-cell: lighten($background, 11%);
    $hover: lighten($background, 17%);
  }

  $highlight: '';
  $highlight-contrast: '';

  @if (lightness($background) > 50%) {
    $highlight: lighten(desaturate($accent, 14%), 39%);
    $highlight-contrast: #000;
  } @else {
    $highlight: darken(desaturate($accent, 14%), 39%);
    $highlight-contrast: #fff;
  }

  $border-color: if($border-param, $border-param, $border-color);
  $mark: if($mark-param, $mark-param, $border-color);

  .mbsc-#{$theme} {
    &.mbsc-calendar {
      background: $background-alt;
      color: $text;
    }

    &.mbsc-calendar-wrapper {
      border-color: $border-color;
    }

    &.mbsc-calendar-header {
      border-color: $border-color;
    }

    &.mbsc-calendar-button.mbsc-button {
      color: $accent;
    }

    &.mbsc-calendar-cell {
      background: $background-alt;
      border-color: $border-color;
      color: $text;
    }

    &.mbsc-calendar-day:after {
      border-color: $border-color;
    }

    &.mbsc-calendar-week-nr,
    &.mbsc-calendar-today {
      color: $accent;
    }

    &.mbsc-hover .mbsc-calendar-cell-text {
      background-color: rgba($accent, 0.3);
    }

    /* range highlight and selection */

    &.mbsc-range-day::after {
      background-color: $highlight;
    }

    &.mbsc-range-day .mbsc-calendar-cell-text {
      color: $highlight-contrast;
    }

    &.mbsc-range-hover::before {
      border-color: $hover;
    }

    &.mbsc-selected .mbsc-calendar-cell-text {
      border-color: $accent;
      background: $accent;
      color: get-contrast-color($accent);
    }

    &.mbsc-focus .mbsc-calendar-cell-text {
      box-shadow: 0 0 0 2px rgba($text, 0.5);
    }

    &.mbsc-focus .mbsc-calendar-day-text {
      box-shadow: none;
      border-color: rgba($text, 0.5);
    }

    &.mbsc-calendar-mark {
      background: $mark;
    }

    &.mbsc-calendar-label {
      color: $event;
    }

    &.mbsc-calendar-label-text {
      color: get-contrast-color($background);
    }

    &.mbsc-calendar-label-active,
    &.mbsc-calendar-label-dragging {
      .mbsc-calendar-label-inner {
        color: get-contrast-color($event);
      }

      .mbsc-calendar-label-text {
        color: inherit;
      }
    }

    &.mbsc-calendar-text-more .mbsc-calendar-label-text {
      color: $text;
    }

    /* Picker */
    &.mbsc-calendar-popup {
      .mbsc-popup-arrow,
      .mbsc-popup-body {
        background: $popup-background;
      }

      .mbsc-calendar-cell {
        background: $popup-cell;
      }
    }

    /* Multi month grid view */

    &.mbsc-calendar-grid {
      border-color: $border-color;
    }

    &.mbsc-calendar-month-title {
      color: $accent;
    }
  }
}


@if ($mbsc-ios-theme and $mbsc-calendar-view) {
  .mbsc-ios {
    &.mbsc-calendar-controls {
      /* IE11 needs px size here to avoid subpixel values */
      padding: 2px;
      /* padding: .125em; */
    }

    &.mbsc-calendar-cell {
      border-top-style: solid;
      border-top-width: 1px;
    }

    &.mbsc-calendar-cell-text {
      height: 2em;
      padding: 0 0.5em;
      line-height: 2em;
      border-radius: 2em;
    }

    &.mbsc-calendar-week-day {
      height: 1.7em;
      line-height: 1.7em;
      font-size: 0.625em;
    }

    &.mbsc-calendar-week-nr {
      width: 2.363636em;
      height: auto;
      font-size: 0.75em;
      font-weight: bold;
      line-height: 3em;
    }

    &.mbsc-calendar-day-text {
      width: 1.625em;
      height: 1.625em;
      margin: 0.1875em;
      padding: 0;
      line-height: 1.625em;
      border: 2px solid transparent;
      border-radius: 2em;
    }

    /* Range Highlight */

    &.mbsc-range-day .mbsc-calendar-cell-inner {
      z-index: 1;
    }

    &.mbsc-range-day::after,
    &.mbsc-range-hover::before {
      content: '';
      height: 1.875em;
      position: absolute;
      left: -1px;
      right: -1px;
      top: 0.25em;
      z-index: 0;
      box-sizing: border-box;
    }

    &.mbsc-range-hover::before {
      border-top: 2px dashed;
      border-bottom: 2px dashed;
    }

    &.mbsc-range-day-start.mbsc-ltr::after,
    &.mbsc-range-day-end.mbsc-rtl::after,
    &.mbsc-range-hover-start.mbsc-ltr::before,
    &.mbsc-range-hover-end.mbsc-rtl::before {
      left: 50%;
      margin-left: -0.9375em;
      border-radius: 2em 0 0 2em;
    }

    &.mbsc-range-day-end.mbsc-ltr::after,
    &.mbsc-range-day-start.mbsc-rtl::after,
    &.mbsc-range-hover-end.mbsc-ltr::before,
    &.mbsc-range-hover-start.mbsc-rtl::before {
      right: 50%;
      margin-right: -0.9375em;
      border-radius: 0 2em 2em 0;
    }

    &.mbsc-range-day-start.mbsc-range-day-end::after,
    &.mbsc-range-hover-start.mbsc-range-hover-end::before {
      display: none;
    }

    /* Marks */

    &.mbsc-calendar-day-marked {
      padding-bottom: 0.4375em;
    }

    &.mbsc-calendar-marks {
      margin-top: -0.0625em;
    }

    &.mbsc-calendar-mark {
      width: 0.375em;
      height: 0.375em;
      border-radius: 0.375em;
      margin: 0 0.0625em;
    }

    /* Colors */

    &.mbsc-calendar-day-colors .mbsc-calendar-day-text {
      background-clip: padding-box;
    }

    &.mbsc-calendar-day-colors.mbsc-hover .mbsc-calendar-cell-text {
      background-clip: border-box;
    }

    /* Labels */

    &.mbsc-calendar-text:before {
      border-radius: 0.4em;
    }

    &.mbsc-calendar-label {
      font-weight: 600;
    }

    &.mbsc-calendar-label-background {
      margin: 0 -0.1em;
      background: currentColor;
      opacity: 0.3;
      transition: opacity 0.15s ease-in-out;
    }

    &.mbsc-calendar-label-start.mbsc-ltr .mbsc-calendar-label-background,
    &.mbsc-calendar-label-end.mbsc-rtl .mbsc-calendar-label-background {
      margin-left: 0;
      border-top-left-radius: 0.4em;
      border-bottom-left-radius: 0.4em;
    }

    &.mbsc-calendar-label-end.mbsc-ltr .mbsc-calendar-label-background,
    &.mbsc-calendar-label-start.mbsc-rtl .mbsc-calendar-label-background {
      margin-right: 0;
      border-top-right-radius: 0.4em;
      border-bottom-right-radius: 0.4em;
    }

    &.mbsc-calendar-label-hover .mbsc-calendar-label-background {
      opacity: 0.6;
    }

    &.mbsc-calendar-label.mbsc-calendar-label-hover:before {
      background: none;
    }

    &.mbsc-calendar-label-dragging .mbsc-calendar-label-background,
    &.mbsc-calendar-label-active .mbsc-calendar-label-background {
      opacity: 0.9;
    }

    /* Desktop styling */

    &.mbsc-calendar-height-md {
      .mbsc-calendar-day:after {
        position: absolute;
        top: 0;
        right: 100%;
        bottom: 0;
        z-index: 1;
        margin-right: -1px;
        border-left-width: 1px;
        border-left-style: solid;
        content: '';
      }

      .mbsc-calendar-week-nr {
        font-size: 0.75em;
      }
    }

    &.mbsc-calendar-width-md {
      .mbsc-calendar-title {
        font-size: 1.5em;
        line-height: 1.666667em;
        padding: 0 0.166667em;
      }

      // .mbsc-calendar-year {
      //   font-weight: 200;
      // }

      .mbsc-calendar-week-day {
        height: 2.5em;
        padding: 0 0.5em;
        line-height: 2.5em;
        font-size: 1em;
        border-left: 1px solid transparent;
      }

      .mbsc-calendar-week-nr {
        font-size: 0.75em;
        padding: 0;
      }

      .mbsc-calendar-day-inner {
        min-height: 2.5em;
      }

      .mbsc-calendar-day-labels .mbsc-calendar-day-inner {
        min-height: 4.75em;
      }

      .mbsc-calendar-marks {
        padding: 0 0.75em;
      }

      .mbsc-calendar-day-text {
        width: 1.375em;
        height: 1.375em;
        line-height: 1.375em;
      }

      .mbsc-calendar-text {
        height: 1.8em;
        line-height: 1.8em;
        margin: 0 0.5em 0.2em 0.6em;
      }

      .mbsc-calendar-label {
        padding: 0 0.4em;
      }

      .mbsc-calendar-label-text {
        font-size: 1.2em;
      }

      .mbsc-calendar-label-background {
        margin: 0 -0.4em;
      }
    }

    &.mbsc-calendar-height-md.mbsc-calendar-width-md {
      .mbsc-calendar-week-day.mbsc-ltr,
      .mbsc-calendar-day.mbsc-ltr,
      .mbsc-calendar-marks.mbsc-ltr {
        text-align: right;
      }

      .mbsc-calendar-week-day.mbsc-rtl,
      .mbsc-calendar-day.mbsc-rtl,
      .mbsc-calendar-marks.mbsc-rtl {
        text-align: left;
      }
    }

    /* Multi month grid view */

    &.mbsc-calendar-grid-view .mbsc-calendar-title {
      font-size: 1.5em;
      line-height: 1.666667em;
      padding: 0 0.166667em;
    }

    &.mbsc-calendar-grid {
      border-top: 1px solid;
    }

    &.mbsc-calendar-grid .mbsc-calendar-grid-item .mbsc-calendar-week-days {
      background: none;
    }

    &.mbsc-calendar-grid .mbsc-calendar-cell {
      border: 0;
    }
  }

  @include mbsc-ios-calendar-view('ios', $mbsc-ios-colors);
  @include mbsc-ios-calendar-view('ios-dark', $mbsc-ios-dark-colors);
}



@mixin mbsc-material-calendar-view($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $background-param: map-get($params, 'calendar-background');
  $text-param: map-get($params, 'calendar-text');
  $accent-param: map-get($params, 'calendar-accent');
  $border-param: map-get($params, 'calendar-border');
  $mark-param: map-get($params, 'calendar-mark');
  $event-param: map-get($params, 'calendar-event');

  $background: if($background-param, $background-param, $background);
  $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
  $accent: if($accent-param, $accent-param, $accent);
  $mark: if($mark-param, $mark-param, $accent);
  $event: if($event-param, $event-param, $accent);

  $border: '';
  $cell-hover: '';
  $picker-background: '';
  $hover: '';

  // Light background
  @if (lightness($background) > 50%) {
    $border: darken($background, 19%);
    $cell-hover: #000;
    $picker-background: $background;
    $hover: darken($background, 10%);
  }

  // Dark background
  @else {
    $border: lighten($background, 17%);
    $cell-hover: #fff;
    $picker-background: lighten($background, 19%);
    $hover: lighten($background, 10%);
  }

  $border: if($border-param, $border-param, $border);

  .mbsc-#{$theme} {
    &.mbsc-calendar-wrapper:after {
      box-shadow: inset 0 0.5em 0.25em -0.5em rgba($text, 0.5);
    }

    &.mbsc-calendar-button.mbsc-button {
      color: $text;
    }

    &.mbsc-calendar-slide {
      background: $background;
    }

    &.mbsc-calendar-picker-slide {
      background: $picker-background;
    }

    &.mbsc-calendar-week-day {
      color: rgba($text, 0.7);
    }

    &.mbsc-calendar-cell-text {
      color: $text;
    }

    &.mbsc-calendar-week-nr,
    &.mbsc-calendar-today {
      color: $accent;
    }

    &.mbsc-focus .mbsc-calendar-cell-text {
      box-shadow: 0 0 0 2px rgba($text, 0.7);
      // border-color: rgba($text, .7);
    }

    &.mbsc-hover .mbsc-calendar-cell-text {
      background: rgba($cell-hover, 0.1);
    }

    /* range highlight and selection */

    &.mbsc-range-hover::before {
      border-color: $hover;
    }

    &.mbsc-range-day::after {
      background-color: rgba($accent, 0.25);
    }

    &.mbsc-selected .mbsc-calendar-cell-text {
      background: $accent;
      border-color: $accent;
      color: $background;
    }

    /* Marks */
    &.mbsc-calendar-mark {
      background: $mark;
    }

    &.mbsc-calendar-label {
      color: $event;
    }

    &.mbsc-calendar-label-inner {
      color: get-contrast-color($event);
    }

    &.mbsc-calendar-label.mbsc-calendar-label-active,
    &.mbsc-calendar-label.mbsc-calendar-label-dragging {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 1px 3px -1px rgba(0, 0, 0, 0.2);
    }

    .mbsc-calendar-label.mbsc-calendar-label-active .mbsc-calendar-label-background {
      box-shadow: inset 0 0 0 1px rgba(#fff, 0.5);
    }

    &.mbsc-calendar-text-more {
      box-shadow: none;
    }

    &.mbsc-calendar-text-more .mbsc-calendar-label-text {
      color: $text;
    }

    /* Picker */
    &.mbsc-calendar-popup {
      .mbsc-popup-arrow,
      .mbsc-popup-body {
        background: $picker-background;
      }
    }

    /* Desktop style */
    &.mbsc-calendar-height-md {
      .mbsc-calendar-week-day,
      .mbsc-calendar-day,
      .mbsc-calendar-day:after {
        border-color: $border;
      }
    }

    /* Multi month grid view */

    &.mbsc-calendar-month-title {
      color: $accent;
    }
  }
}


@if ($mbsc-material-theme and $mbsc-calendar-view) {
  .mbsc-material {
    &.mbsc-calendar-wrapper-fixed:after {
      content: '';
      position: absolute;
      z-index: 7;
      bottom: -0.5em;
      left: 0;
      right: 0;
      height: 0.5em;
      pointer-events: none;
    }

    &.mbsc-calendar-controls {
      padding: 0.5em;
    }

    &.mbsc-calendar-week-day {
      height: 2.5em;
      line-height: 2.5em;
      font-size: 0.75em;
      font-weight: bold;
    }

    &.mbsc-calendar-week-nr {
      width: 2.363637em;
      height: auto;
      font-size: 0.6875em;
      line-height: 3em;
      font-weight: bold;
    }

    &.mbsc-calendar-button.mbsc-button {
      padding: 0.428572em;
    }

    &.mbsc-calendar-title {
      font-size: 1.428572em;
      font-weight: 400;
      text-transform: none;
      line-height: 1.4em;
    }

    &.mbsc-calendar-cell-text {
      height: 2em;
      padding: 0 0.5em;
      line-height: 2em;
      border-radius: 2em;
    }

    &.mbsc-calendar-month-name {
      font-size: 0.8125em;
    }

    &.mbsc-calendar-month-name.mbsc-ltr {
      padding-left: 0.461539em;
      // margin-right: -1em;
    }

    &.mbsc-calendar-month-name.mbsc-rtl {
      padding-right: 0.461539em;
      // margin-left: -1em;
    }

    &.mbsc-calendar-day-text {
      height: 1.846154em;
      width: 1.846154em;
      line-height: 1.846154em;
      margin: 0.230769em auto;
      padding: 0;
      font-size: 0.8125em;
      border: 2px solid transparent;
    }

    /* Range Highlight */

    &.mbsc-range-day::after,
    &.mbsc-range-hover::before {
      content: '';
      height: 1.75em;
      position: absolute;
      left: 0;
      right: 0;
      top: 0.25em;
      z-index: -1;
    }

    &.mbsc-range-hover::before {
      box-sizing: content-box;
      height: 1.5em;
      border-top: 2px dashed;
      border-bottom: 2px dashed;
    }

    &.mbsc-range-day-start.mbsc-ltr::after,
    &.mbsc-range-day-end.mbsc-rtl::after {
      margin-left: -0.875em;
      border-radius: 2em 0 0 2em;
      left: 50%;
      right: 0;
    }

    &.mbsc-range-day-end.mbsc-ltr::after,
    &.mbsc-range-day-start.mbsc-rtl::after {
      margin-right: -0.875em;
      border-radius: 0 2em 2em 0;
      right: 50%;
      left: 0;
    }

    &.mbsc-range-hover-start.mbsc-ltr::before,
    &.mbsc-range-hover-end.mbsc-rtl::before {
      left: 50%;
      right: 0;
    }

    &.mbsc-range-hover-end.mbsc-ltr::before,
    &.mbsc-range-hover-start.mbsc-rtl::before {
      right: 50%;
      left: 0;
    }

    &.mbsc-range-day-start.mbsc-range-day-end::after,
    &.mbsc-range-hover-start.mbsc-range-hover-end::before {
      display: none;
    }

    /* Marks */

    &.mbsc-calendar-marks {
      margin-top: -0.375em;
      transition: transform 0.1s ease-out;
    }

    &.mbsc-focus .mbsc-calendar-marks,
    &.mbsc-hover .mbsc-calendar-marks,
    &.mbsc-selected .mbsc-calendar-marks,
    &.mbsc-calendar-day-colors .mbsc-calendar-marks,
    &.mbsc-highlighted .mbsc-calendar-marks,
    &.mbsc-hover-highlighted .mbsc-calendar-marks {
      transform: translate3d(0, 0.25em, 0);
    }

    /* Labels */
    &.mbsc-calendar-label {
      font-weight: 600;
      border-radius: 0.4em;
    }

    &.mbsc-calendar-label-background {
      margin: 0 -0.1em;
      background: currentColor;
    }

    &.mbsc-calendar-text:before {
      border-radius: 0.4em;
    }

    &.mbsc-calendar-label:before {
      margin: 0 -0.1em;
      border-radius: 0;
    }

    &.mbsc-calendar-label-start.mbsc-ltr .mbsc-calendar-label-background,
    &.mbsc-calendar-label-end.mbsc-rtl .mbsc-calendar-label-background,
    &.mbsc-calendar-label-start.mbsc-ltr.mbsc-calendar-text:before,
    &.mbsc-calendar-label-end.mbsc-rtl.mbsc-calendar-text:before {
      margin-left: 0;
      border-top-left-radius: 0.4em;
      border-bottom-left-radius: 0.4em;
    }

    &.mbsc-calendar-label-end.mbsc-ltr .mbsc-calendar-label-background,
    &.mbsc-calendar-label-start.mbsc-rtl .mbsc-calendar-label-background,
    &.mbsc-calendar-label-end.mbsc-ltr.mbsc-calendar-text:before,
    &.mbsc-calendar-label-start.mbsc-rtl.mbsc-calendar-text:before {
      margin-right: 0;
      border-top-right-radius: 0.4em;
      border-bottom-right-radius: 0.4em;
    }

    /* Desktop style */
    &.mbsc-calendar-height-md {
      .mbsc-calendar-slide {
        padding: 0;
      }

      .mbsc-calendar-day {
        border-bottom: 1px solid;
      }

      .mbsc-calendar-day:after {
        position: absolute;
        top: 0;
        right: 100%;
        bottom: 0;
        z-index: 1;
        margin-right: -1px;
        border-left-width: 1px;
        border-left-style: solid;
        content: '';
      }

      .mbsc-calendar-week-nr {
        font-size: 0.6875em;
        line-height: 3.636363em;
      }
    }

    &.mbsc-calendar-width-md {
      .mbsc-calendar-week-day {
        padding: 0 1em;
      }

      .mbsc-calendar-week-nr {
        padding: 0;
      }

      .mbsc-calendar-day-inner {
        min-height: 4em;
      }

      .mbsc-calendar-day-labels .mbsc-calendar-day-inner {
        min-height: 5.5em;
      }

      .mbsc-calendar-day-text {
        margin: 0.461539em;
      }

      .mbsc-calendar-marks {
        padding: 0 1.125em;
        margin-left: -1px;
        margin-right: -1px;
      }

      .mbsc-calendar-text {
        height: 1.8em;
        line-height: 1.8em;
        margin: 0 0.5em 0.2em 0.6em;
      }

      .mbsc-calendar-label {
        padding: 0 0.4em;
      }

      .mbsc-calendar-label-text {
        font-size: 1.2em;
      }

      .mbsc-calendar-label:before,
      .mbsc-calendar-label-background {
        margin: 0 -0.4em;
      }
    }

    &.mbsc-calendar-height-md.mbsc-calendar-width-md {
      .mbsc-calendar-week-day.mbsc-ltr,
      .mbsc-calendar-day.mbsc-ltr,
      .mbsc-calendar-marks.mbsc-ltr {
        text-align: left;
      }

      .mbsc-calendar-week-day.mbsc-rtl,
      .mbsc-calendar-day.mbsc-rtl,
      .mbsc-calendar-marks.mbsc-rtl {
        text-align: right;
      }
    }
  }

  @include mbsc-material-calendar-view('material', $mbsc-material-colors);
  @include mbsc-material-calendar-view('material-dark', $mbsc-material-dark-colors);
}



@mixin mbsc-windows-calendar-view($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $background-param: map-get($params, 'calendar-background');
  $text-param: map-get($params, 'calendar-text');
  $accent-param: map-get($params, 'calendar-accent');
  $border-param: map-get($params, 'calendar-border');
  $mark-param: map-get($params, 'calendar-mark');
  $event-param: map-get($params, 'calendar-event');

  $background: if($background-param, $background-param, $background);
  $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
  $accent: if($accent-param, $accent-param, $accent);
  $mark: if($mark-param, $mark-param, rgba($text, 0.5));
  $event: if($event-param, $event-param, $accent);

  $alt-text: '';
  $border: '';
  $button-bg: '';
  $hover: '';

  @if (lightness($background) > 50%) {
    $alt-text: lighten($text, 20%);
    $border: darken($background, 10%);
    $button-bg: darken($background, 20%);
    $hover: rgba($text, 0.1);
  } @else {
    $alt-text: darken($text, 20%);
    $border: lighten($background, 10%);
    $button-bg: lighten($background, 15%);
    $hover: rgba($text, 0.22);
  }

  $border: if($border-param, $border-param, $border);

  .mbsc-#{$theme} {
    &.mbsc-calendar {
      color: $text;
    }

    &.mbsc-calendar-button.mbsc-button {
      color: $text;

      &.mbsc-active {
        background: lighten($button-bg, 15%);
        border-color: lighten($button-bg, 15%);
      }

      &.mbsc-focus {
        box-shadow: 0 0 0 1px $text;
      }
    }

    &.mbsc-calendar-wrapper-fixed {
      border-bottom: 1px solid $border;
    }

    &.mbsc-calendar-slide {
      background: $background;
      color: $text;
    }

    &.mbsc-calendar-week-day {
      color: $alt-text;
    }

    &.mbsc-calendar-week-nr,
    &.mbsc-calendar-today {
      color: $accent;
    }

    /* range highlight and selection */

    &.mbsc-range-day {
      border-left-color: rgba($accent, 0.25);
    }

    &.mbsc-range-day-start.mbsc-ltr,
    &.mbsc-range-day-end.mbsc-rtl {
      border-left-color: transparent;
    }

    &.mbsc-range-hover .mbsc-calendar-cell-inner {
      border-top-color: $hover;
      border-bottom-color: $hover;
      border-style: dashed;
    }

    // &.mbsc-range-hover-start .mbsc-calendar-cell-inner {
    //   border-left-color: $hover;
    // }

    // &.mbsc-range-hover-end .mbsc-calendar-cell-inner {
    //   border-right-color: $hover;
    // }

    // &.mbsc-selected .mbsc-calendar-cell-inner {
    //   border-color: transparent;
    // }

    // &.mbsc-range-day-start,
    // &.mbsc-range-day-end {
    //   border-left-color: $accent;
    //   border-right-color: $accent;
    // }

    // &.mbsc-range-day-start.mbsc-range-day-end {
    //   border-left-color: transparent;
    // }

    &.mbsc-selected,
    &.mbsc-range-day {
      .mbsc-calendar-cell-inner {
        background: rgba($accent, 0.25);
      }
    }

    &.mbsc-selected.mbsc-range-day {
      .mbsc-calendar-cell-inner {
        background-color: $accent;
      }

      .mbsc-calendar-cell-text {
        color: get-contrast-color($accent);
      }
    }

    &.mbsc-calendar-day-colors.mbsc-selected:after {
      border: 2px solid $accent;
    }

    &.mbsc-calendar-cell.mbsc-focus:after {
      border: 1px solid $text;
    }

    &.mbsc-calendar-cell.mbsc-hover:after {
      background: $hover;
    }

    /* Marks */
    &.mbsc-calendar-mark {
      background: $mark;
    }

    &.mbsc-calendar-label {
      color: $event;
    }

    &.mbsc-calendar-label-inner {
      color: get-contrast-color($event);
    }

    &.mbsc-calendar-label.mbsc-calendar-label-active {
      outline: 1px solid $text;
    }

    &.mbsc-calendar-text-more .mbsc-calendar-label-text {
      color: $text;
    }

    &.mbsc-calendar-height-md {
      .mbsc-calendar-week-day {
        border-color: $border;
      }
    }

    &.mbsc-calendar-width-md {
      .mbsc-calendar-day,
      .mbsc-calendar-day:after {
        border-color: $border;
      }
    }

    /* Picker */
    &.mbsc-calendar-popup {
      .mbsc-popup-arrow,
      .mbsc-popup {
        background: $background;
        border-color: $border;
      }
    }

    /* Multi month grid view */

    &.mbsc-calendar-month-title {
      color: $accent;
    }
  }
}


@if ($mbsc-windows-theme and $mbsc-calendar-view) {
  .mbsc-windows {
    &.mbsc-calendar-header {
      margin-bottom: -1px;
    }

    &.mbsc-calendar-button.mbsc-button.mbsc-font {
      margin: 1px;
    }

    &.mbsc-calendar-title-wrapper .mbsc-calendar-button {
      padding: 0;
    }

    &.mbsc-calendar-controls {
      padding: 0.5em;
      min-height: 2.125em;
    }

    &.mbsc-calendar-week-day {
      height: 2.5em;
      font-size: 0.75em;
      line-height: 2.5em;
    }

    &.mbsc-calendar-body .mbsc-calendar-week-day {
      border: 0;
    }

    &.mbsc-calendar-week-nr {
      width: 2.166667em;
      font-size: 0.75em;
      font-weight: bold;
      line-height: 3em;
    }

    &.mbsc-calendar-picker-slide {
      padding: 0.25em;
    }

    &.mbsc-calendar-cell {
      border-width: 0;
      border-style: solid;
      border-top-width: 1px;
      border-color: transparent;
      background-clip: padding-box;
      padding: 0;
    }

    &.mbsc-calendar-cell:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }

    &.mbsc-calendar-cell-inner {
      display: table;
      position: relative;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 1px solid transparent;
      border-top-width: 2px;
      border-bottom-width: 2px;
    }

    &.mbsc-calendar-cell-text {
      display: table-cell;
      vertical-align: middle;
      min-width: 0;
    }

    &.mbsc-calendar-month-name {
      font-size: 0.875em;
      vertical-align: middle;

      &.mbsc-ltr {
        margin-left: 0.5em;
      }

      &.mbsc-rtl {
        margin-right: 0.5em;
      }
    }

    &.mbsc-calendar-day-inner {
      display: block;
    }

    &.mbsc-calendar-day-empty:after {
      display: none;
    }

    &.mbsc-calendar-day-text {
      display: inline-block;
      font-size: 0.875em;
      line-height: 2.285715em;
      border-radius: 2em;
      // width: 2.285715em;
      margin: 0 0.5em;
    }

    /* Marks */
    &.mbsc-calendar-marks {
      bottom: 0.25em;
      // margin-top: -0.5em;
    }

    &.mbsc-calendar-label-background {
      background: currentColor;
    }

    &.mbsc-calendar-width-md {
      .mbsc-calendar-title {
        font-size: 1.625em;
        font-weight: 300;
        line-height: 1.461538em;
        padding: 0 0.307693em;
      }

      .mbsc-calendar-week-day {
        height: 2.142858em;
        padding: 0 0.5em;
        font-size: 0.875em;
        line-height: 2.142858em;
      }

      .mbsc-calendar-week-nr {
        padding: 0;
        font-size: 0.75em;
      }

      .mbsc-calendar-day:after {
        border-left-width: 1px;
        border-left-style: solid;
      }

      .mbsc-calendar-day-inner {
        min-height: 4em;
      }

      .mbsc-calendar-day-text {
        text-align: center;
      }

      .mbsc-calendar-day-labels .mbsc-calendar-day-inner {
        min-height: 4.75em;
      }

      .mbsc-calendar-marks {
        // margin: 0;
        bottom: 0.5em;
        padding: 0 0.625em;
      }

      .mbsc-calendar-text {
        height: 1.8em;
        line-height: 1.8em;
        padding: 0 0.6em;
      }

      .mbsc-calendar-label-text {
        font-size: 1.2em;
      }

      .mbsc-calendar-week-day.mbsc-ltr,
      .mbsc-calendar-day.mbsc-ltr,
      .mbsc-calendar-marks.mbsc-ltr {
        text-align: left;
      }

      .mbsc-calendar-week-day.mbsc-rtl,
      .mbsc-calendar-day.mbsc-rtl,
      .mbsc-calendar-marks.mbsc-rtl {
        text-align: right;
      }

      .mbsc-calendar-day.mbsc-calendar-day-labels {
        text-align: center;
      }
    }

    &.mbsc-calendar-grid-view .mbsc-calendar-title {
      font-size: 1.625em;
      font-weight: 300;
      line-height: 1.461538em;
      padding: 0 0.307693em;
    }
  }

  @include mbsc-windows-calendar-view('windows', $mbsc-windows-colors);
  @include mbsc-windows-calendar-view('windows-dark', $mbsc-windows-dark-colors);
}


@if $mbsc-calendar-view {
  @keyframes mbsc-zoom-in-up {
    from {
      opacity: 0;
      transform: scale(2);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes mbsc-zoom-in-down {
    from {
      opacity: 0;
      transform: scale(0.5);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes mbsc-zoom-out-up {
    from {
      opacity: 1;
      transform: scale(1);
    }

    to {
      opacity: 0;
      transform: scale(2);
    }
  }

  @keyframes mbsc-zoom-out-down {
    from {
      opacity: 1;
      transform: scale(1);
    }

    to {
      opacity: 0;
      transform: scale(0.5);
    }
  }

  .mbsc-calendar {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    position: relative;
  }

  .mbsc-calendar-wrapper {
    position: relative;
    z-index: 1;
    // width: 100%;
    height: 100%;
    overflow: hidden;
  }

  /* Header */

  .mbsc-calendar-header {
    position: relative;
    z-index: 1;
    will-change: opacity;

    .mbsc-calendar-week-days {
      margin-left: -1px;
    }
  }

  .mbsc-calendar-controls {
    align-items: center;
    box-sizing: content-box;
    min-height: 2.5em;
  }

  .mbsc-calendar-button.mbsc-button.mbsc-reset {
    margin: 0;
  }

  .mbsc-calendar-button-prev-multi {
    order: -1;
  }

  .mbsc-calendar-button-next-multi {
    order: 1;
  }

  .mbsc-calendar-body {
    // height: 100%;
    box-sizing: border-box;
    overflow: hidden;
  }

  .mbsc-calendar-body-inner {
    // height: 100%;
    position: relative;
    overflow: hidden;
    margin-left: -1px;
  }

  .mbsc-calendar-wrapper-fixed {
    // display: block;
    height: auto;
    overflow: visible;

    // .mbsc-calendar-header {
    //   display: block;
    // }

    // .mbsc-calendar-body {
    //   display: block;
    //   height: auto;
    // }
  }

  .mbsc-calendar-title-wrapper {
    align-items: center;
    overflow: hidden;
  }

  .mbsc-calendar-title {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.125em;
    // line-height: 2.222223em;
    padding: 0 0.25em;
    display: inline-block;
    vertical-align: middle;
  }

  /* Scrollview */

  .mbsc-calendar-scroll-wrapper {
    display: block;
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  .mbsc-calendar-picker-wrapper {
    position: relative;
    width: 15em;
    height: 15em;
    overflow: hidden;
  }

  .mbsc-calendar-picker {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .mbsc-calendar-picker-main {
    position: relative;
    z-index: 0;
  }

  .mbsc-calendar-picker-in-up {
    opacity: 0;
    animation: mbsc-zoom-in-up 200ms cubic-bezier(0, 0, 0.2, 1) forwards;
  }

  .mbsc-calendar-picker-in-down {
    opacity: 0;
    animation: mbsc-zoom-in-down 200ms cubic-bezier(0, 0, 0.2, 1) forwards;
  }

  .mbsc-calendar-picker-out-up {
    opacity: 0;
    animation: mbsc-zoom-out-up 200ms cubic-bezier(0.4, 0, 1, 1) forwards;
  }

  .mbsc-calendar-picker-out-down {
    opacity: 0;
    animation: mbsc-zoom-out-down 200ms cubic-bezier(0, 0, 0.2, 1) forwards;
  }

  .mbsc-calendar-scroll-wrapper > div {
    height: 100%;
  }

  .mbsc-calendar-scroll-wrapper > div > div {
    height: 100%;
    transform: translate3d(0, 0, 0);
  }

  .mbsc-calendar-slide {
    position: absolute;
    z-index: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  .mbsc-calendar-slide.mbsc-ltr {
    left: 0;
  }

  .mbsc-calendar-slide.mbsc-rtl {
    right: 0;
  }

  .mbsc-calendar-slide:first-child {
    position: relative;
  }

  .mbsc-calendar-table {
    height: 100%;
    // Needed for iOS Safari to prevent animation flicker
    transform: translateZ(0);
  }

  .mbsc-calendar-cell {
    position: relative;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
  }

  .mbsc-calendar-cell:focus {
    outline: 0;
  }

  .mbsc-calendar-cell-inner {
    display: inline-block;
    padding: 0 0.25em;
    vertical-align: middle;
  }

  .mbsc-calendar-cell.mbsc-disabled {
    cursor: not-allowed;
  }

  .mbsc-calendar-week-day {
    height: 2em;
    font-size: 0.6875em;
    line-height: 2em;
    text-align: center;
    border-bottom: 1px solid transparent;
  }

  .mbsc-calendar-year,
  .mbsc-calendar-month {
    vertical-align: middle;
  }

  .mbsc-calendar-year-text,
  .mbsc-calendar-month-text {
    margin: 0.875em 0;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 4.5em;
    box-sizing: border-box;
  }

  .mbsc-calendar-day {
    /* if only width is used, layout is broken with week numbers displayed */
    max-width: 14.285715%;
    text-align: center;
    vertical-align: top;
  }

  .mbsc-calendar-day-empty {
    cursor: default;
  }

  .mbsc-calendar-day-text {
    display: inline-block;
    text-align: center;
    box-sizing: content-box;
  }

  .mbsc-calendar-day-inner {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    border: 1px solid transparent;
    box-sizing: border-box;
  }

  .mbsc-calendar-day-hidden {
    visibility: hidden;
  }

  .mbsc-calendar-month-name {
    display: none;
  }

  .mbsc-calendar-width-md {
    .mbsc-calendar-month-name {
      display: inline-block;
      font-weight: bold;
    }
  }

  .mbsc-calendar-day-outer .mbsc-calendar-day-text,
  .mbsc-calendar-day-outer .mbsc-calendar-month-name {
    opacity: 0.5;
  }

  .mbsc-calendar-day-outer.mbsc-selected .mbsc-calendar-day-text,
  .mbsc-calendar-day-outer.mbsc-selected .mbsc-calendar-month-name {
    opacity: 1;
  }

  .mbsc-disabled .mbsc-calendar-cell-text,
  .mbsc-disabled .mbsc-calendar-month-name {
    opacity: 0.2;
  }

  /* Day highlight */

  .mbsc-calendar-day-highlight:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
  }

  /* Marks */

  .mbsc-calendar-day-marked {
    padding-bottom: 0.25em;
  }

  .mbsc-calendar-marks {
    position: absolute;
    left: 0;
    right: 0;
    height: 0.375em;
    margin-top: -0.125em;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
  }

  .mbsc-calendar-mark {
    display: inline-block;
    vertical-align: top;
    width: 0.25em;
    height: 0.25em;
    margin: 0.0625em;
    border-radius: 0.25em;
  }

  /* Labels */

  .mbsc-calendar-labels {
    position: absolute;
    left: -1px;
    right: -1px;
  }

  .mbsc-calendar-label-wrapper {
    position: absolute;
    z-index: 2;
    pointer-events: none;
  }

  .mbsc-calendar-label {
    padding: 0 0.3em;
  }

  .mbsc-calendar-text:focus {
    outline: 0;
  }

  .mbsc-calendar-label-text {
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    z-index: 2;
  }

  .mbsc-calendar-text:before,
  .mbsc-calendar-label-background {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s;
  }

  .mbsc-calendar-label.mbsc-calendar-label-hover:before {
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .mbsc-calendar-text-more {
    padding: 0 0.3em;
  }

  .mbsc-calendar-text-more.mbsc-calendar-label-active:before,
  .mbsc-calendar-text-more.mbsc-calendar-label-hover:before {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .mbsc-calendar-text {
    display: block;
    position: relative;
    height: 1.6em;
    line-height: 1.6em;
    // overflow: hidden;
    font-size: 0.625em;
    white-space: nowrap;
    margin: 0 0.1em 0.2em 0.2em;
  }

  .mbsc-calendar-text-placeholder {
    z-index: -1;
  }

  .mbsc-calendar-text.mbsc-ltr {
    text-align: left;
  }

  .mbsc-calendar-text.mbsc-rtl {
    text-align: right;
  }

  /* Label drag & drop */

  .mbsc-calendar-label-resize {
    position: absolute;
    top: 0;
    height: 100%;
    width: 0.5em;
    z-index: 2;
    cursor: ew-resize;
  }

  .mbsc-calendar-label-resize:after {
    content: '';
    display: none;
    position: absolute;
    top: 50%;
    width: 0.8em;
    height: 0.8em;
    margin-top: -0.4em;
    background: #fff;
    border-radius: 0.8em;
    border: 1px solid currentColor;
    box-sizing: border-box;
  }

  .mbsc-calendar-label-resize-start.mbsc-ltr,
  .mbsc-calendar-label-resize-end.mbsc-rtl {
    left: 0;
  }

  .mbsc-calendar-label-resize-start.mbsc-rtl,
  .mbsc-calendar-label-resize-end.mbsc-ltr {
    right: 0;
  }

  .mbsc-calendar-label-resize-start.mbsc-ltr:after,
  .mbsc-calendar-label-resize-end.mbsc-rtl:after {
    left: -0.4em;
  }

  .mbsc-calendar-label-resize-end.mbsc-ltr:after,
  .mbsc-calendar-label-resize-start.mbsc-rtl:after {
    right: -0.4em;
  }

  .mbsc-calendar-label-resize-start-touch:before,
  .mbsc-calendar-label-resize-end-touch:before {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -1em;
    width: 2em;
    height: 2em;
  }

  .mbsc-calendar-label-resize-start-touch:before {
    left: -1em;
  }

  .mbsc-calendar-label-resize-end-touch:before {
    right: -1em;
  }

  .mbsc-calendar-label-hover,
  .mbsc-calendar-label-dragging {
    .mbsc-calendar-label-resize:after {
      display: block;
    }
  }

  .mbsc-calendar-label-inactive {
    opacity: 0.4;
  }

  .mbsc-calendar-label-hidden {
    visibility: hidden;
  }

  .mbsc-calendar-labels-dragging {
    z-index: 3;
  }

  /* Multi month grid view */

  .mbsc-calendar-grid {
    overflow: auto;
    padding: 0 2%;

    .mbsc-calendar-table {
      margin: 0 5%;
      width: 90%;
    }
  }

  .mbsc-calendar-grid-item {
    margin: 1em 0;
    // Space for month title, needed for height 100% to work correctly for .mbsc-calendar-table in Safari
    padding-top: 2.5em;
  }

  .mbsc-calendar-month-title {
    padding-bottom: 0.8em;
    margin: 0 10%;
    margin-top: -2em;
    font-size: 1.25em;
    font-weight: bold;
    line-height: 1.2em;
  }

  /* Variable row */

  .mbsc-calendar-body-inner-variable {
    overflow-y: auto;

    .mbsc-calendar-table {
      min-height: 20em;
    }
  }
}



@mixin mbsc-ios-calendar($theme, $params) {
}


@if ($mbsc-ios-theme) {
  @include mbsc-ios-calendar('ios', $mbsc-ios-colors);
  @include mbsc-ios-calendar('ios-dark', $mbsc-ios-dark-colors);
}



@mixin mbsc-material-calendar($theme, $params) {
}


@if ($mbsc-material-theme) {
  @include mbsc-material-calendar('material', $mbsc-material-colors);
  @include mbsc-material-calendar('material-dark', $mbsc-material-dark-colors);
}



@mixin mbsc-windows-calendar($theme, $params) {
}


@if ($mbsc-windows-theme) {
  @include mbsc-windows-calendar('windows', $mbsc-windows-colors);
  @include mbsc-windows-calendar('windows-dark', $mbsc-windows-dark-colors);
}




@mixin mbsc-mobiscroll-form-controls($theme, $params) {
}


@if ($mbsc-mobiscroll-theme and $mbsc-forms) {
  .mbsc-mobiscroll {
    &.mbsc-form-control-label.mbsc-disabled,
    &.mbsc-description.mbsc-disabled {
      opacity: 0.4;
    }
  }

  @include mbsc-mobiscroll-form-controls('mobiscroll', $mbsc-mobiscroll-colors);
  @include mbsc-mobiscroll-form-controls('mobiscroll-dark', $mbsc-mobiscroll-dark-colors);
}



@mixin mbsc-ios-form-controls($theme, $params) {
  $text: map-get($params, 'text');
  $background: map-get($params, 'background');

  $bg-param: map-get($params, 'form-background');
  $err-param: map-get($params, 'form-error');

  $background: if($bg-param, $bg-param, $background);

  $is-dark: false;
  $border-color: '';
  $wrapper-background: '';
  $wrapper-background-popup: '';

  @if (lightness($background) > 50%) {
    $border-color: darken($background, 17%);
    $wrapper-background: lighten($background, 6%);
    $wrapper-background-popup: $wrapper-background;
  } @else {
    $is-dark: true;
    $border-color: lighten($background, 20%);
    $wrapper-background: lighten($background, 11%);
    $wrapper-background-popup: lighten($background, 17%);
  }

  $error: if($err-param, $err-param, $mbsc-ios-error);

  .mbsc-#{$theme} {
    /* Wrapper */

    &.mbsc-form-control-wrapper {
      background: $wrapper-background;
    }

    &.mbsc-form-control-wrapper:before,
    &.mbsc-form-control-wrapper:after {
      border-color: $border-color;
    }

    &.mbsc-form-control-wrapper.mbsc-error:after,
    &.mbsc-form-control-wrapper.mbsc-error + .mbsc-form-control-wrapper:before {
      border-color: $error;
    }

    /* Inside popup */

    @if ($is-dark) {
      &.mbsc-popup &.mbsc-form-control-wrapper {
        background: $wrapper-background-popup;
      }
    }
  }
}


@if ($mbsc-ios-theme and $mbsc-forms) {
  .mbsc-ios {
    &.mbsc-form-control-wrapper {
      margin-top: -1px;
    }

    &.mbsc-form-control-wrapper.mbsc-error {
      z-index: 2;
    }

    &.mbsc-form-control-wrapper:before,
    &.mbsc-form-control-wrapper:after {
      content: '';
      position: absolute;
      border-top: 1px solid transparent;
    }

    &.mbsc-form-control-wrapper.mbsc-ltr:after,
    &.mbsc-form-control-wrapper.mbsc-ltr:before {
      right: 0;
      left: 1em;
    }

    &.mbsc-form-control-wrapper.mbsc-rtl:after,
    &.mbsc-form-control-wrapper.mbsc-rtl:before {
      left: 0;
      right: 1em;
    }

    &.mbsc-form-control-wrapper:before {
      top: 0;
    }

    &.mbsc-form-control-wrapper:after {
      bottom: 0;
    }

    .mbsc-block-title + &.mbsc-form-control-wrapper.mbsc-ltr:before,
    .mbsc-form-group-title + &.mbsc-form-control-wrapper.mbsc-ltr:before,
    &.mbsc-form-control-wrapper.mbsc-ltr:first-child:before,
    &.mbsc-form-control-wrapper.mbsc-ltr:last-child:after {
      left: 0;
    }

    .mbsc-block-title + &.mbsc-form-control-wrapper.mbsc-rtl:before,
    .mbsc-form-group-title + &.mbsc-form-control-wrapper.mbsc-rtl:before,
    &.mbsc-form-control-wrapper.mbsc-rtl:first-child:before,
    &.mbsc-form-control-wrapper.mbsc-rtl:last-child:after {
      right: 0;
    }

    &.mbsc-form-control-label.mbsc-disabled,
    &.mbsc-description.mbsc-disabled {
      opacity: 0.3;
    }

    .mbsc-form-group-inset .mbsc-form-group-title + .mbsc-form-control-wrapper,
    .mbsc-form-group-inset .mbsc-form-control-wrapper:first-child {
      border-top-left-radius: 0.5em;
      border-top-right-radius: 0.5em;
    }

    .mbsc-form-group-inset .mbsc-form-control-wrapper:last-child {
      border-bottom-left-radius: 0.5em;
      border-bottom-right-radius: 0.5em;
    }

    .mbsc-form-group-inset .mbsc-form-group-title,
    .mbsc-form-group-inset .mbsc-form-group-title + .mbsc-form-control-wrapper:before,
    .mbsc-form-group-inset .mbsc-form-control-wrapper:first-child:before,
    .mbsc-form-group-inset .mbsc-form-control-wrapper:last-child:after {
      border-width: 0;
    }
  }

  @include mbsc-ios-form-controls('ios', $mbsc-ios-colors);
  @include mbsc-ios-form-controls('ios-dark', $mbsc-ios-dark-colors);
}



@mixin mbsc-material-form-controls($theme, $params) {
}


@if ($mbsc-material-theme and $mbsc-forms) {
  .mbsc-material {
    &.mbsc-form-control-label.mbsc-disabled,
    &.mbsc-description.mbsc-disabled {
      opacity: 0.3;
    }
  }

  @include mbsc-material-form-controls('material', $mbsc-material-colors);
  @include mbsc-material-form-controls('material-dark', $mbsc-material-dark-colors);
}



@mixin mbsc-windows-form-controls($theme, $params) {
}


@if ($mbsc-windows-theme and $mbsc-forms) {
  .mbsc-windows {
    &.mbsc-form-control-label.mbsc-disabled,
    &.mbsc-description.mbsc-disabled {
      opacity: 0.3;
    }
  }

  @include mbsc-windows-form-controls('windows', $mbsc-windows-colors);
  @include mbsc-windows-form-controls('windows-dark', $mbsc-windows-dark-colors);
}


@if $mbsc-forms {
  .mbsc-form-control-label {
    display: block;
  }

  .mbsc-form-control-input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 0;
    opacity: 0;
    margin: 0;
    z-index: 3;
  }
}



$mbsc-ios-input-background: $mbsc-input-background-light !default;
$mbsc-ios-input-text: $mbsc-input-text-light !default;
$mbsc-ios-input-accent: $mbsc-input-accent-light !default;
$mbsc-ios-input-border: $mbsc-input-border-light !default;

$mbsc-ios-dark-input-background: $mbsc-input-background-dark !default;
$mbsc-ios-dark-input-text: $mbsc-input-text-dark !default;
$mbsc-ios-dark-input-accent: $mbsc-input-accent-dark !default;
$mbsc-ios-dark-input-border: $mbsc-input-border-dark !default;

$mbsc-ios-colors: map-merge(
  $mbsc-ios-colors,
  (
    // Colors map
    'input-background': $mbsc-ios-input-background,
    'input-text': $mbsc-ios-input-text,
    'input-accent': $mbsc-ios-input-accent,
    'input-border': $mbsc-ios-input-border
  )
);

$mbsc-ios-dark-colors: map-merge(
  $mbsc-ios-dark-colors,
  (
    // Colors map
    'input-background': $mbsc-ios-dark-input-background,
    'input-text': $mbsc-ios-dark-input-text,
    'input-accent': $mbsc-ios-dark-input-accent,
    'input-border': $mbsc-ios-dark-input-border
  )
);

@mixin mbsc-ios-input($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');

  $bg-param: map-get($params, 'input-background');
  $text-param: map-get($params, 'input-text');
  $brd-param: map-get($params, 'input-border');
  $err-param: map-get($params, 'form-error');

  $background: if($bg-param, $bg-param, $background);
  $text: if($text-param, $text-param, $text);
  $error: if($err-param, $err-param, $mbsc-ios-error);

  $is-dark: false;
  $border-color: '';
  $input-background: '';
  $input-background-popup: '';
  $input-tag-background: '';
  $textfield-text: $text;
  $textfield-icon-color: '';

  // Light background
  @if (lightness($background) > 50%) {
    $input-background: lighten($background, 6%);
    $input-background-popup: $input-background;
    $input-tag-background: darken($background, 10%);
    $textfield-icon-color: lighten($text, 54.9);
    $border-color: darken($background, 17%);
  }

  // Dark background
  @else {
    $is-dark: true;
    $input-background: lighten($background, 11%);
    $input-background-popup: lighten($background, 17%);
    $input-tag-background: lighten($background, 23%);
    $textfield-icon-color: $mbsc-ios-dark-text;
    $border-color: lighten($background, 20%);
  }

  $border-color: if($brd-param, $brd-param, $border-color);

  .mbsc-#{$theme} {
    /* Wrapper */

    &.mbsc-textfield-wrapper {
      background: none;
    }

    &.mbsc-textfield-wrapper-underline {
      background: $input-background;
    }

    /* Form element */

    &.mbsc-label,
    &.mbsc-textfield {
      color: $textfield-text;
    }

    &.mbsc-textfield-box,
    &.mbsc-textfield-outline {
      background: $input-background;
    }

    &.mbsc-textfield-outline {
      border-color: $border-color;
    }

    /* Icon */

    &.mbsc-textfield-icon,
    &.mbsc-select-icon {
      color: $textfield-icon-color;
    }

    /* Error */

    &.mbsc-textfield-outline.mbsc-error,
    &.mbsc-textfield-box.mbsc-error {
      border-color: $error;
    }

    &.mbsc-error-message {
      color: $error;
    }

    /* Select */

    &.mbsc-select {
      background: $input-background;
    }

    /* Textarea */

    &.mbsc-textarea-inner.mbsc-textfield-inner-box,
    &.mbsc-textarea-inner.mbsc-textfield-inner-outline {
      background: $input-background;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-outline {
      border-color: $border-color;
    }

    &.mbsc-textarea-inner.mbsc-error {
      border-color: $error;
    }

    /* Inside popup */

    @if ($is-dark) {
      &.mbsc-popup &.mbsc-textfield-wrapper {
        background: none;
      }

      &.mbsc-popup &.mbsc-select,
      &.mbsc-popup &.mbsc-textfield-wrapper-underline,
      &.mbsc-popup &.mbsc-textfield-box,
      &.mbsc-popup &.mbsc-textfield-outline,
      &.mbsc-popup &.mbsc-textarea-inner.mbsc-textfield-inner-box,
      &.mbsc-popup &.mbsc-textarea-inner.mbsc-textfield-inner-outline {
        background: $input-background-popup;
      }
    }

    /* Input tags */

    &.mbsc-textfield-tag {
      background: $input-tag-background;
    }

    &.mbsc-textfield-tag-clear {
      color: $textfield-icon-color;
    }
  }
}


@if ($mbsc-ios-theme and $mbsc-input) {
  .mbsc-ios {
    /* Wrapper */

    &.mbsc-textfield-wrapper-has-icon-right.mbsc-rtl,
    &.mbsc-textfield-wrapper-has-icon-left.mbsc-ltr {
      z-index: 1;
    }

    &.mbsc-textfield-wrapper-has-icon-left.mbsc-ltr:before,
    &.mbsc-textfield-wrapper-has-icon-left.mbsc-ltr:after {
      left: 3.375em;
    }

    &.mbsc-textfield-wrapper-has-icon-right.mbsc-rtl:after,
    &.mbsc-textfield-wrapper-has-icon-right.mbsc-rtl:before {
      right: 3.375em;
    }

    /* Inner element */

    &.mbsc-textfield-inner {
      position: static;
      flex: 1 auto;
      z-index: -1;
    }

    &.mbsc-textfield-inner.mbsc-disabled {
      opacity: 0.5;
    }

    /* Form element */

    &.mbsc-textfield {
      display: flex;
      height: 2.75em;
      padding: 0 1em;
      background: transparent;
      height: 2.75em;
    }

    &.mbsc-textfield-has-icon-left {
      padding-left: 3.375em;
    }

    &.mbsc-textfield-has-icon-right {
      padding-right: 3.375em;
    }

    &.mbsc-textfield-stacked,
    &.mbsc-textfield-floating {
      height: 3.5em;
      padding-top: 1.25em;
    }

    /* Icons */

    &.mbsc-textfield-icon {
      top: 0.625em;
      z-index: 1;
    }

    &.mbsc-textfield-icon-left {
      left: 0.9375em;
    }

    &.mbsc-textfield-icon-right {
      right: 0.9375em;
    }

    &.mbsc-textfield-icon-floating,
    &.mbsc-textfield-icon-stacked {
      top: 1em;
    }

    /* Label */

    &.mbsc-label-inline {
      line-height: 2.75em;
    }

    &.mbsc-label-inline.mbsc-ltr {
      padding-left: 1em;
    }

    &.mbsc-label-inline.mbsc-rtl {
      padding-right: 1em;
    }

    &.mbsc-label-stacked {
      top: 0.666667em;
      font-size: 0.75em;
      line-height: 1em;
    }

    &.mbsc-label-stacked.mbsc-ltr {
      left: 1.333334em;
    }

    &.mbsc-label-stacked.mbsc-rtl {
      right: 1.333334em;
    }

    &.mbsc-label-floating {
      top: 0.875em;
      line-height: 2em;
    }

    &.mbsc-label-floating.mbsc-ltr {
      left: 1em;
    }

    &.mbsc-label-floating.mbsc-rtl {
      right: 1em;
    }

    &.mbsc-label-floating-active.mbsc-label {
      transform: translateY(-0.75em) scale(0.75);
    }

    &.mbsc-label.mbsc-disabled {
      opacity: 0.5;
    }

    /* Error message */

    &.mbsc-error-message {
      display: block;
      padding: 0 1.333334em;
      line-height: 1.666667em;
    }

    &.mbsc-error-message-underline {
      position: static;
    }

    &.mbsc-error-message-has-icon-left.mbsc-ltr {
      padding-left: 4.5em;
    }

    &.mbsc-error-message-has-icon-right.mbsc-rtl {
      padding-right: 4.5em;
    }

    /* Underline input --------------------------------------------------------------------------- */

    .mbsc-textfield-wrapper-underline {
      // Cut corners of select dropdown in case of inset form group
      overflow: hidden;
    }

    /* Form element*/

    &.mbsc-textfield-underline-inline-has-icon-left.mbsc-ltr {
      padding-left: 1em;
    }

    &.mbsc-textfield-underline-inline-has-icon-right.mbsc-rtl {
      padding-right: 1em;
    }

    /* Label */

    &.mbsc-label-underline {
      margin: 0;
    }

    &.mbsc-label-underline-stacked-has-icon-left.mbsc-ltr {
      left: 4.5em;
    }

    &.mbsc-label-underline-stacked-has-icon-right.mbsc-rtl {
      right: 4.5em;
    }

    &.mbsc-label-underline-inline-has-icon-left.mbsc-ltr {
      padding-left: 3.375em;
    }

    &.mbsc-label-underline-inline-has-icon-right.mbsc-rtl {
      padding-right: 3.375em;
    }

    &.mbsc-label-underline-floating-has-icon-left.mbsc-ltr {
      left: 3.375em;
    }

    &.mbsc-label-underline-floating-has-icon-right.mbsc-rtl {
      right: 3.375em;
    }

    /* Error message */

    &.mbsc-error-message-underline.mbsc-error-message-inline.mbsc-ltr {
      padding-left: 1.333334em;
    }

    &.mbsc-error-message-underline.mbsc-error-message-inline.mbsc-rtl {
      padding-right: 1.333334em;
    }

    /* ------------------------------------------------------------------------------------------- */

    /* Box & outline input common styles --------------------------------------------------------- */

    /* Wrapper */

    &.mbsc-textfield-wrapper-outline,
    &.mbsc-textfield-wrapper-box {
      margin: 1.5em 1em;
    }

    &.mbsc-textfield-wrapper-outline.mbsc-font::after,
    &.mbsc-textfield-wrapper-outline.mbsc-font::before,
    &.mbsc-textfield-wrapper-box.mbsc-font::after,
    &.mbsc-textfield-wrapper-box.mbsc-font::before {
      border: none;
    }

    /* Form element */

    &.mbsc-textfield-box,
    &.mbsc-textfield-outline {
      border-radius: 0.5em;
      border: 1px solid transparent;
    }

    &.mbsc-textfield-inner-box,
    &.mbsc-textfield-inner-outline {
      position: relative;
    }

    /* Label */

    &.mbsc-label-box-stacked-has-icon-left.mbsc-ltr,
    &.mbsc-label-outline-stacked-has-icon-left.mbsc-ltr {
      left: 4.5em;
    }

    &.mbsc-label-outline-stacked-has-icon-right.mbsc-rtl,
    &.mbsc-label-box-stacked-has-icon-right.mbsc-rtl {
      right: 4.5em;
    }

    &.mbsc-label-box-stacked,
    &.mbsc-label-outline-stacked,
    &.mbsc-label-box-floating,
    &.mbsc-label-outline-floating {
      margin: 0 1px;
    }

    &.mbsc-label-outline-floating-has-icon-left.mbsc-ltr,
    &.mbsc-label-box-floating-has-icon-left.mbsc-ltr {
      left: 3.375em;
    }

    &.mbsc-label-outline-floating-has-icon-right.mbsc-rtl,
    &.mbsc-label-box-floating-has-icon-right.mbsc-rtl {
      right: 3.375em;
    }

    /* Error message */

    &.mbsc-error-message-outline,
    &.mbsc-error-message-box {
      margin: 0 1px;
    }

    /* ------------------------------------------------------------------------------------------- */

    /* Select */

    &.mbsc-select.mbsc-ltr {
      padding-right: 3.375em;
    }

    &.mbsc-select.mbsc-rtl {
      padding-left: 3.375em;
    }

    &.mbsc-select.mbsc-textfield-has-icon-right.mbsc-ltr {
      padding-right: 4.875em;
    }

    &.mbsc-select.mbsc-textfield-has-icon-left.mbsc-rtl {
      padding-left: 4.875em;
    }

    /* Select icon */

    &.mbsc-select-icon {
      top: 0.625em;
    }

    &.mbsc-select-icon-stacked,
    &.mbsc-select-icon-floating {
      top: 1em;
    }

    &.mbsc-select-icon.mbsc-ltr {
      right: 0.9375em;
    }

    &.mbsc-select-icon.mbsc-rtl {
      left: 0.9375em;
    }

    &.mbsc-select-icon-right.mbsc-ltr {
      right: 3.375em;
    }

    &.mbsc-select-icon-left.mbsc-rtl {
      left: 3.375em;
    }

    /* Textarea */

    &.mbsc-textarea {
      height: 3em;
      border: 0;
    }

    &.mbsc-textarea.mbsc-textfield-stacked,
    &.mbsc-textarea.mbsc-textfield-floating {
      padding-top: 0;
    }

    &.mbsc-textarea-inner {
      padding-top: 0.625em;
      padding-bottom: 0.625em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-box,
    &.mbsc-textarea-inner.mbsc-textfield-inner-outline {
      border: 1px solid transparent;
      border-radius: 0.5em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-stacked,
    &.mbsc-textarea-inner.mbsc-textfield-inner-floating {
      padding-top: 1.375em;
    }

    /* Input tags */

    &.mbsc-textfield-tags-inner {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.mbsc-textfield.mbsc-textfield-tags {
      padding-top: 0.3125em;
      padding-bottom: 0.3125em;
      min-height: 2.75em;
    }

    &.mbsc-textfield-tags.mbsc-textfield-stacked,
    &.mbsc-textfield-tags.mbsc-textfield-floating {
      min-height: 2.125em;
      padding-top: 0;
      padding-bottom: 0;
    }

    &.mbsc-textfield-tag {
      border-radius: 1em;
    }
  }

  @include mbsc-ios-input('ios', $mbsc-ios-colors);
  @include mbsc-ios-input('ios-dark', $mbsc-ios-dark-colors);
}



$mbsc-material-input-background: $mbsc-input-background-light !default;
$mbsc-material-input-text: $mbsc-input-text-light !default;
$mbsc-material-input-accent: $mbsc-input-accent-light !default;
$mbsc-material-input-border: $mbsc-input-border-light !default;

$mbsc-material-dark-input-background: $mbsc-input-background-dark !default;
$mbsc-material-dark-input-text: $mbsc-input-text-dark !default;
$mbsc-material-dark-input-accent: $mbsc-input-accent-dark !default;
$mbsc-material-dark-input-border: $mbsc-input-border-dark !default;

$mbsc-material-colors: map-merge(
  $mbsc-material-colors,
  (
    // Colors map
    'input-background': $mbsc-material-input-background,
    'input-text': $mbsc-material-input-text,
    'input-accent': $mbsc-material-input-accent,
    'input-border': $mbsc-material-input-border
  )
);

$mbsc-material-dark-colors: map-merge(
  $mbsc-material-dark-colors,
  (
    // Colors map
    'input-background': $mbsc-material-dark-input-background,
    'input-text': $mbsc-material-dark-input-text,
    'input-accent': $mbsc-material-dark-input-accent,
    'input-border': $mbsc-material-dark-input-border
  )
);

@mixin mbsc-material-input($theme, $params) {
  $background: map-get($params, 'background');
  $accent: map-get($params, 'accent');
  $text: map-get($params, 'text');

  $bg-param: map-get($params, 'input-background');
  $text-param: map-get($params, 'input-text');
  $acc-param: map-get($params, 'input-accent');
  $err-param: map-get($params, 'form-error');
  $brd-param: map-get($params, 'input-border');

  $text: if($text-param, $text-param, $text);
  $accent: if($acc-param, $acc-param, $accent);
  $error: if($err-param, $err-param, $mbsc-material-error);

  $input-base-color: '';
  $input-text: '';
  $input-icon-color: '';
  $input-border-color: '';
  $input-label-color: '';
  $input-background: '';
  $input-box-background: '';
  $input-box-hover-background: '';
  $input-box-focus-background: '';
  $input-tag-background: '';

  // Light background
  @if (lightness($background) > 50%) {
    $input-base-color: darken($text, 19%);
    $input-text: rgba($input-base-color, 0.87);
    $input-border-color: rgba($input-base-color, 0.42);
    $input-icon-color: rgba($input-base-color, 0.54);
    $input-label-color: rgba($input-base-color, 0.6);
    $input-background: if($bg-param, $bg-param, $background);
    $input-box-background: if($bg-param, $bg-param, darken($background, 4%));
    $input-box-hover-background: darken($input-box-background, 3%);
    $input-box-focus-background: darken($input-box-background, 7%);
    $input-tag-background: darken($background, 17%);
  }

  // Dark background
  @else {
    $input-base-color: $text;
    $input-text: $input-base-color;
    $input-border-color: $input-base-color;
    $input-icon-color: $input-base-color;
    $input-label-color: rgba($input-base-color, 0.6);
    $input-background: if($bg-param, $bg-param, $background);
    $input-box-background: if($bg-param, $bg-param, lighten($background, 4%));
    $input-box-hover-background: lighten($input-box-background, 3%);
    $input-box-focus-background: lighten($input-box-background, 7%);
    $input-tag-background: lighten($background, 27%);
  }

  $input-text: if($text-param, $text-param, $input-text);
  $input-icon-color: if($text-param, $text-param, $input-icon-color);
  $input-label-color: if($text-param, $text-param, $input-label-color);
  $input-border-color: if($brd-param, $brd-param, $input-border-color);

  .mbsc-#{$theme} {
    /* Form element */

    &.mbsc-textfield {
      border-bottom-color: $input-border-color;
      color: $input-text;
    }

    &.mbsc-textfield.mbsc-hover {
      border-color: $input-text;
    }

    &.mbsc-textfield.mbsc-error {
      border-color: $error;
    }

    /* Icon */

    &.mbsc-textfield-icon,
    &.mbsc-select-icon {
      color: $input-icon-color;
    }

    /* Ripple */

    &.mbsc-textfield-ripple {
      background-color: $accent;
    }

    &.mbsc-textfield-ripple.mbsc-error {
      background-color: $error;
    }

    /* Label */

    &.mbsc-label {
      color: $input-label-color;
    }

    &.mbsc-label-stacked.mbsc-focus,
    &.mbsc-label-floating.mbsc-focus {
      color: $accent;
    }

    &.mbsc-label.mbsc-error {
      color: $error;
    }

    /* Error message */

    &.mbsc-error-message {
      color: $error;
    }

    /* Select */

    &.mbsc-select {
      background: $input-background;
    }

    /* Box input ------------------------------------------------------------------------------- */

    &.mbsc-textfield-box.mbsc-select,
    &.mbsc-textfield-inner-box {
      background: $input-box-background;
    }

    &.mbsc-textfield-box.mbsc-select.mbsc-hover,
    &.mbsc-textfield-inner-box.mbsc-hover {
      background: $input-box-hover-background;
    }

    &.mbsc-textfield-box.mbsc-select.mbsc-focus,
    &.mbsc-textfield-inner-box.mbsc-focus {
      background: $input-box-focus-background;
    }

    /* ----------------------------------------------------------------------------------------- */

    /* Outline input --------------------------------------------------------------------------- */

    &.mbsc-textfield-fieldset {
      border-color: $input-border-color;
    }

    &.mbsc-textfield-fieldset.mbsc-hover {
      border-color: $input-text;
    }

    &.mbsc-textfield-fieldset.mbsc-focus {
      border-color: $accent;
    }

    &.mbsc-textfield-fieldset.mbsc-error {
      border-color: $error;
    }

    &.mbsc-textfield-fieldset.disabled {
      border-color: $input-text;
    }

    /* Input tag */

    &.mbsc-textfield-tag {
      background: $input-tag-background;
    }

    &.mbsc-textfield-tag-clear {
      color: $input-icon-color;
    }

    /* ----------------------------------------------------------------------------------------- */
  }
}


@if ($mbsc-material-theme and $mbsc-input) {
  .mbsc-material {
    /* Wrapper */

    &.mbsc-textfield-wrapper {
      margin: 1.5em 1em;
      box-sizing: border-box;
    }

    /* Inner wrapper */

    &.mbsc-textfield-inner.mbsc-disabled {
      opacity: 0.5;
    }

    /* Form element */

    &.mbsc-textfield {
      display: block;
      width: 100%;
      height: 2.25em;
      background-color: transparent;
      border-bottom: 1px solid;
      font-size: 1em;
      transition: border-color 0.2s;
    }

    &.mbsc-textfield-has-icon-left {
      padding-left: 2em;
    }

    &.mbsc-textfield-has-icon-right {
      padding-right: 2em;
    }

    /* Icon */

    &.mbsc-textfield-icon {
      top: 0.375em;
    }

    &.mbsc-textfield-icon-floating,
    &.mbsc-textfield-icon-stacked,
    &.mbsc-textfield-icon-outline {
      top: 1em;
    }

    /* Ripple */

    .mbsc-textfield-ripple {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      transform: scaleX(0);
      transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1);
      z-index: 2;
    }

    .mbsc-textfield-ripple-active {
      transform: scaleX(1);
    }

    /* Label */

    &.mbsc-label {
      font-size: 0.75em;
    }

    &.mbsc-label-inline {
      line-height: 2em;
      font-size: 1em;
    }

    &.mbsc-label-inline.mbsc-ltr {
      padding-right: 0.5em;
    }

    &.mbsc-label-inline.mbsc-rtl {
      padding-left: 0.5em;
    }

    &.mbsc-label-floating {
      font-size: 1em;
      line-height: 1.5em;
      top: 1.125em;
    }

    &.mbsc-label-floating-active {
      transform: translateY(-1.125em) scale(0.75);
    }

    &.mbsc-label.mbsc-disabled {
      opacity: 0.5;
    }

    /* Error message */

    &.mbsc-error-message.mbsc-ltr {
      left: 0;
    }

    &.mbsc-error-message.mbsc-rtl {
      right: 0;
    }

    &.mbsc-error-message-has-icon-left.mbsc-ltr {
      left: 2.66667em;
    }

    &.mbsc-error-message-has-icon-right.mbsc-rtl {
      right: 2.66667em;
    }

    /* Underline input --------------------------------------------------------------------------- */

    /* Form element */

    &.mbsc-textfield-underline-stacked,
    &.mbsc-textfield-underline-floating {
      height: 3em;
      padding-top: 0.875em;
    }

    /* Icon */

    &.mbsc-textfield-icon-underline {
      top: 0.25em;
    }

    &.mbsc-textfield-icon-underline.mbsc-textfield-icon-floating,
    &.mbsc-textfield-icon-underline.mbsc-textfield-icon-stacked {
      top: 1.125em;
    }

    /* Label */

    &.mbsc-label-underline-inline {
      padding-top: 1px;
    }

    &.mbsc-label-underline-stacked-has-icon-left.mbsc-ltr {
      left: 2.66667em;
    }

    &.mbsc-label-underline-stacked-has-icon-right.mbsc-rtl {
      right: 2.66667em;
    }

    &.mbsc-label-underline-floating-has-icon-left.mbsc-ltr {
      left: 2em;
    }

    &.mbsc-label-underline-floating-has-icon-right.mbsc-rtl {
      right: 2em;
    }

    /* ------------------------------------------------------------------------------------------- */

    /* Box input --------------------------------------------------------------------------------- */

    /* Inner wrapper */

    &.mbsc-textfield-box,
    &.mbsc-textfield-inner-box {
      border-radius: 0.25em 0.25em 0 0;
    }

    /* Form element */

    &.mbsc-textfield-box {
      padding: 0 1em;
    }

    &.mbsc-textfield-box-stacked,
    &.mbsc-textfield-box-floating {
      height: 3.5em;
      padding-top: 1.25em;
    }

    &.mbsc-textfield-underline.mbsc-disabled {
      border-style: dotted;
    }

    /* Label */

    &.mbsc-label-box-inline {
      padding-top: 1px;
    }

    &.mbsc-label-box-stacked {
      top: 0.666667em;
    }

    &.mbsc-label-box-floating {
      top: 1em;
    }

    &.mbsc-label-box-floating.mbsc-label-floating-active {
      transform: translateY(-0.625em) scale(0.75);
    }

    /* ------------------------------------------------------------------------------------------- */

    /* Outline input ----------------------------------------------------------------------------- */

    /* Inner wrapper */

    &.mbsc-textfield-inner-outline {
      padding: 0 2px;
    }

    /* Form element */

    &.mbsc-textfield-outline {
      height: 3.5em;
      padding: 2px 1em;
      border: 0;
      border-radius: 4px;
    }

    &.mbsc-textfield-fieldset {
      top: -0.5em;
      border: 1px solid;
      border-radius: 4px;
      transition: border-color 0.2s;
    }

    &.mbsc-textfield-fieldset-has-icon-left {
      padding-left: 3em;
    }

    &.mbsc-textfield-fieldset-has-icon-right {
      padding-right: 3em;
    }

    &.mbsc-textfield-fieldset.mbsc-focus {
      border-width: 2px;
    }

    &.mbsc-textfield-legend {
      padding: 0;
      margin: 0;
      font-size: 0.75em;
      color: transparent;
      width: 0.01px; // Fraction needed for IE11
      white-space: nowrap;
    }

    &.mbsc-textfield-legend-active {
      width: auto;
      padding: 0 0.333334em;
      margin: 0 -0.333334em;
    }

    /* Label */

    &.mbsc-label-outline-inline {
      line-height: 3.375em;
    }

    &.mbsc-label-outline-stacked {
      top: -0.5em;
      margin: 0 2px; // Compensate borders
    }

    &.mbsc-label-outline-floating {
      top: 1em;
      margin: 0 2px; // Compensate borders
    }

    &.mbsc-label-outline-floating.mbsc-label-floating-active {
      margin-top: -1px;
      transform: translateY(-1.5em) scale(0.75);
    }

    /* Error message */

    &.mbsc-error-message-outline {
      margin: 0 2px; // Compensate border
    }

    /* ------------------------------------------------------------------------------------------- */

    /* Box & outline input common styles --------------------------------------------------------- */

    /* Form element */

    &.mbsc-textfield-outline-has-icon-left,
    &.mbsc-textfield-box-has-icon-left {
      padding-left: 3em;
    }

    &.mbsc-textfield-outline-has-icon-right,
    &.mbsc-textfield-box-has-icon-right {
      padding-right: 3em;
    }

    /* Icon */

    &.mbsc-textfield-icon-outline-left,
    &.mbsc-textfield-icon-box-left {
      left: 0.75em;
    }

    &.mbsc-textfield-icon-outline-right,
    &.mbsc-textfield-icon-box-right {
      right: 0.75em;
    }

    /* Label */

    &.mbsc-label-box-stacked.mbsc-ltr,
    &.mbsc-label-outline-stacked.mbsc-ltr {
      left: 1.333334em;
    }

    &.mbsc-label-box-stacked.mbsc-rtl,
    &.mbsc-label-outline-stacked.mbsc-rtl {
      right: 1.333334em;
    }

    &.mbsc-label-outline-stacked-has-icon-left.mbsc-ltr,
    &.mbsc-label-box-stacked-has-icon-left.mbsc-ltr {
      left: 4em;
    }

    &.mbsc-label-box-stacked-has-icon-right.mbsc-rtl,
    &.mbsc-label-outline-stacked-has-icon-right.mbsc-rtl {
      right: 4em;
    }

    &.mbsc-label-box-floating.mbsc-ltr,
    &.mbsc-label-outline-floating.mbsc-ltr {
      left: 1em;
    }

    &.mbsc-label-box-floating.mbsc-rtl,
    &.mbsc-label-outline-floating.mbsc-rtl {
      right: 1em;
    }

    &.mbsc-label-outline-floating-has-icon-left.mbsc-ltr,
    &.mbsc-label-box-floating-has-icon-left.mbsc-ltr {
      left: 3em;
    }

    &.mbsc-label-outline-floating-has-icon-left.mbsc-rtl,
    &.mbsc-label-box-floating-has-icon-left.mbsc-rtl {
      right: 1em;
    }

    &.mbsc-label-outline-floating-has-icon-right.mbsc-rtl,
    &.mbsc-label-box-floating-has-icon-right.mbsc-rtl {
      right: 3em;
    }

    /* Error message */

    &.mbsc-error-message-box.mbsc-ltr,
    &.mbsc-error-message-outline.mbsc-ltr {
      left: 1.333334em;
    }

    &.mbsc-error-message-box.mbsc-rtl,
    &.mbsc-error-message-outline.mbsc-rtl {
      right: 1.333334em;
    }

    &.mbsc-error-message-box.mbsc-error-message-has-icon-left.mbsc-ltr,
    &.mbsc-error-message-outline.mbsc-error-message-has-icon-left.mbsc-ltr {
      left: 4em;
    }

    &.mbsc-error-message-box.mbsc-error-message-has-icon-right.mbsc-rtl,
    &.mbsc-error-message-outline.mbsc-error-message-has-icon-right.mbsc-rtl {
      right: 4em;
    }

    /* ------------------------------------------------------------------------------------------- */

    /* Select */

    &.mbsc-select.mbsc-ltr {
      padding-right: 3em;
    }

    &.mbsc-select.mbsc-rtl {
      padding-left: 3em;
    }

    &.mbsc-select.mbsc-textfield-has-icon-right.mbsc-ltr {
      padding-right: 4.5em;
    }

    &.mbsc-select.mbsc-textfield-has-icon-left.mbsc-rtl {
      padding-left: 4.5em;
    }

    &.mbsc-select.mbsc-textfield-underline-has-icon-right.mbsc-ltr {
      padding-right: 3.75em;
    }

    &.mbsc-select.mbsc-textfield-underline-has-icon-left.mbsc-rtl {
      padding-left: 3.75em;
    }

    /* Select icon */

    &.mbsc-select-icon {
      top: 0.375em;
    }

    &.mbsc-select-icon.mbsc-ltr {
      right: 0.75em;
    }

    &.mbsc-select-icon.mbsc-rtl {
      left: 0.75em;
    }

    &.mbsc-select-icon-right.mbsc-ltr {
      right: 3em;
    }

    &.mbsc-select-icon-left.mbsc-rtl {
      left: 3em;
    }

    &.mbsc-select-icon-stacked,
    &.mbsc-select-icon-floating,
    &.mbsc-select-icon-outline {
      top: 1em;
    }

    &.mbsc-select-icon-underline {
      top: 0.25em;
    }

    &.mbsc-select-icon-underline.mbsc-ltr {
      right: 0;
    }

    &.mbsc-select-icon-underline.mbsc-rtl {
      left: 0;
    }

    &.mbsc-select-icon-underline.mbsc-select-icon-right.mbsc-ltr {
      right: 2.25em;
    }

    &.mbsc-select-icon-underline.mbsc-select-icon-left.mbsc-rtl {
      left: 2.25em;
    }

    &.mbsc-select-icon-underline.mbsc-select-icon-floating,
    &.mbsc-select-icon-underline.mbsc-select-icon-stacked {
      top: 1.125em;
    }

    /* Textarea */

    &.mbsc-textarea {
      height: 1.875em;
      padding-bottom: 0.375em;
    }

    &.mbsc-textarea.mbsc-textfield-stacked,
    &.mbsc-textarea.mbsc-textfield-floating {
      padding-top: 0;
    }

    &.mbsc-textarea.mbsc-textfield-outline {
      height: 1.5em;
      padding-top: 0;
      padding-bottom: 0;
    }

    &.mbsc-textarea.mbsc-textfield-underline {
      padding-bottom: 0.3125em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-box {
      padding-top: 0.375em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-stacked,
    &.mbsc-textarea-inner.mbsc-textfield-inner-floating {
      padding-top: 1.625em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-outline {
      padding-top: 1em;
      padding-bottom: 1em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-underline {
      padding-top: 0.25em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-underline.mbsc-textfield-inner-stacked,
    &.mbsc-textarea-inner.mbsc-textfield-inner-underline.mbsc-textfield-inner-floating {
      padding-top: 1.125em;
    }

    /* Input tags */

    &.mbsc-textfield-tags-inner.mbsc-textfield-inner-underline,
    &.mbsc-textfield-tags-inner.mbsc-textfield-inner-inline {
      padding-top: 0;
    }

    &.mbsc-textfield-tags-inner.mbsc-textfield-inner-outline {
      padding-top: 0.625em;
      padding-bottom: 0.625em;
    }

    &.mbsc-textfield-tags.mbsc-textfield {
      padding-bottom: 0;
      min-height: 2.25em;
    }

    &.mbsc-textfield-tags.mbsc-textfield-outline {
      padding-top: 1px;
      padding-bottom: 1px;
    }

    &.mbsc-textfield-tag {
      border-radius: 1em;
    }
  }

  @include mbsc-material-input('material', $mbsc-material-colors);
  @include mbsc-material-input('material-dark', $mbsc-material-dark-colors);
}



$mbsc-mobiscroll-input-background: $mbsc-input-background-light !default;
$mbsc-mobiscroll-input-text: $mbsc-input-text-light !default;
$mbsc-mobiscroll-input-accent: $mbsc-input-accent-light !default;
$mbsc-mobiscroll-input-border: $mbsc-input-border-light !default;

$mbsc-mobiscroll-dark-input-background: $mbsc-input-background-dark !default;
$mbsc-mobiscroll-dark-input-text: $mbsc-input-text-dark !default;
$mbsc-mobiscroll-dark-input-accent: $mbsc-input-accent-dark !default;
$mbsc-mobiscroll-dark-input-border: $mbsc-input-border-dark !default;

$mbsc-mobiscroll-colors: map-merge(
  $mbsc-mobiscroll-colors,
  (
    // Colors map
    'input-background': $mbsc-mobiscroll-input-background,
    'input-text': $mbsc-mobiscroll-input-text,
    'input-accent': $mbsc-mobiscroll-input-accent,
    'input-border': $mbsc-mobiscroll-input-border
  )
);

$mbsc-mobiscroll-dark-colors: map-merge(
  $mbsc-mobiscroll-dark-colors,
  (
    // Colors map
    'input-background': $mbsc-mobiscroll-dark-input-background,
    'input-text': $mbsc-mobiscroll-dark-input-text,
    'input-accent': $mbsc-mobiscroll-dark-input-accent,
    'input-border': $mbsc-mobiscroll-dark-input-border
  )
);

@mixin mbsc-mobiscroll-input($theme, $params) {
  $background: map-get($params, 'background');
  $accent: map-get($params, 'accent');
  $text: map-get($params, 'text');

  $bg-param: map-get($params, 'input-background');
  $text-param: map-get($params, 'input-text');
  $brd-param: map-get($params, 'input-border');
  $acc-param: map-get($params, 'input-accent');
  $err-param: map-get($params, 'form-error');

  $text: if($text-param, $text-param, $text);
  $accent: if($acc-param, $acc-param, $accent);
  $error: if($err-param, $err-param, $mbsc-mobiscroll-error);

  $input-base-color: '';
  $input-text: '';
  $input-icon-color: '';
  $input-border-color: '';
  $input-label-color: '';
  $input-background: '';
  $input-box-background: '';
  $input-box-hover-background: '';
  $input-box-focus-background: '';

  // Light background
  @if (lightness($background) > 50%) {
    $input-base-color: darken($text, 27%);
    $input-text: rgba($input-base-color, 0.87);
    $input-border-color: rgba($input-base-color, 0.42);
    $input-icon-color: rgba($input-base-color, 0.54);
    $input-label-color: rgba($input-base-color, 0.6);
    $input-background: if($bg-param, $bg-param, $background);
    $input-box-background: if($bg-param, $bg-param, darken($background, 4%));
    $input-box-hover-background: darken($input-box-background, 3%);
    $input-box-focus-background: darken($input-box-background, 7%);
  }

  // Dark background
  @else {
    $input-base-color: $text;
    $input-text: $input-base-color;
    $input-border-color: $input-base-color;
    $input-icon-color: $input-base-color;
    $input-label-color: rgba($input-base-color, 0.6);
    $input-background: if($bg-param, $bg-param, $background);
    $input-box-background: if($bg-param, $bg-param, lighten($background, 4%));
    $input-box-hover-background: lighten($input-box-background, 3%);
    $input-box-focus-background: lighten($input-box-background, 7%);
  }

  $input-border-color: if($brd-param, $brd-param, $input-border-color);
  $input-text: if($text-param, $text-param, $input-text);
  $input-icon-color: if($text-param, $text-param, $input-icon-color);
  $input-label-color: if($text-param, $text-param, $input-label-color);

  .mbsc-#{$theme} {
    /* Form element */

    &.mbsc-textfield {
      border-bottom-color: $input-border-color;
      color: $input-text;
    }

    &.mbsc-textfield.mbsc-hover {
      border-color: $input-text;
    }

    &.mbsc-textfield.mbsc-error {
      border-color: $error;
    }

    /* Icon */

    &.mbsc-textfield-icon,
    &.mbsc-select-icon {
      color: $input-icon-color;
    }

    /* Ripple */

    &.mbsc-textfield-ripple {
      background-color: $accent;
    }

    &.mbsc-textfield-ripple.mbsc-error {
      background-color: $error;
    }

    /* Label */

    &.mbsc-label {
      color: $input-label-color;
    }

    &.mbsc-label-stacked.mbsc-focus,
    &.mbsc-label-floating.mbsc-focus {
      color: $accent;
    }

    &.mbsc-label.mbsc-error {
      color: $error;
    }

    /* Error message */

    &.mbsc-error-message {
      color: $error;
    }

    /* Select */

    &.mbsc-select {
      background: $input-background;
    }

    /* Box input ------------------------------------------------------------------------------- */

    &.mbsc-textfield-box.mbsc-select,
    &.mbsc-textfield-inner-box {
      background: $input-box-background;
    }

    &.mbsc-textfield-box.mbsc-select.mbsc-hover,
    &.mbsc-textfield-inner-box.mbsc-hover {
      background: $input-box-hover-background;
    }

    &.mbsc-textfield-box.mbsc-select.mbsc-focus,
    &.mbsc-textfield-inner-box.mbsc-focus {
      background: $input-box-focus-background;
    }

    /* ----------------------------------------------------------------------------------------- */

    /* Outline input --------------------------------------------------------------------------- */

    &.mbsc-textfield-fieldset {
      border-color: $input-border-color;
    }

    &.mbsc-textfield-fieldset.mbsc-hover {
      border-color: $input-text;
    }

    &.mbsc-textfield-fieldset.mbsc-focus {
      border-color: $accent;
    }

    &.mbsc-textfield-fieldset.mbsc-error {
      border-color: $error;
    }

    &.mbsc-textfield-fieldset.disabled {
      border-color: $input-text;
    }

    /* ----------------------------------------------------------------------------------------- */
  }
}


@if ($mbsc-mobiscroll-theme and $mbsc-input) {
  .mbsc-mobiscroll {
    /* Wrapper */

    &.mbsc-textfield-wrapper {
      margin: 1.5em 1em;
      box-sizing: border-box;
    }

    /* Inner wrapper */

    &.mbsc-textfield-inner.mbsc-disabled {
      opacity: 0.5;
    }

    /* Form element */

    &.mbsc-textfield {
      display: block;
      width: 100%;
      height: 2.125em;
      background-color: transparent;
      border-bottom: 1px solid;
      font-size: 1em;
      transition: border-color 0.2s;
    }

    &.mbsc-textfield-has-icon-left {
      padding-left: 2em;
    }

    &.mbsc-textfield-has-icon-right {
      padding-right: 2em;
    }

    /* Icon */

    &.mbsc-textfield-icon {
      top: 0.375em;
    }

    &.mbsc-textfield-icon-floating,
    &.mbsc-textfield-icon-stacked,
    &.mbsc-textfield-icon-outline {
      top: 1em;
    }

    /* Ripple */

    .mbsc-textfield-ripple {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      transform: scaleX(0);
      transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1);
      z-index: 2;
    }

    .mbsc-textfield-ripple-active {
      transform: scaleX(1);
    }

    /* Label */

    &.mbsc-label {
      font-size: 0.75em;
    }

    &.mbsc-label-inline {
      line-height: 2em;
      font-size: 1em;
    }

    &.mbsc-label-inline.mbsc-ltr {
      padding-right: 0.5em;
    }

    &.mbsc-label-inline.mbsc-rtl {
      padding-left: 0.5em;
    }

    &.mbsc-label-floating {
      font-size: 1em;
      line-height: 1.5em;
      top: 1.125em;
    }

    &.mbsc-label-floating-active {
      transform: translateY(-1.125em) scale(0.75);
    }

    &.mbsc-label.mbsc-disabled {
      opacity: 0.5;
    }

    /* Error message */

    &.mbsc-error-message.mbsc-ltr {
      left: 0;
    }

    &.mbsc-error-message.mbsc-rtl {
      right: 0;
    }

    &.mbsc-error-message-has-icon-left.mbsc-ltr {
      left: 2.66667em;
    }

    &.mbsc-error-message-has-icon-right.mbsc-rtl {
      right: 2.66667em;
    }

    /* Underline input --------------------------------------------------------------------------- */

    /* Form element */

    &.mbsc-textfield-underline-stacked,
    &.mbsc-textfield-underline-floating {
      height: 3em;
      padding-top: 0.875em;
    }

    /* Icon */

    &.mbsc-textfield-icon-underline {
      top: 0.25em;
    }

    &.mbsc-textfield-icon-underline.mbsc-textfield-icon-floating,
    &.mbsc-textfield-icon-underline.mbsc-textfield-icon-stacked {
      top: 1.125em;
    }

    /* Label */

    &.mbsc-label-underline-inline {
      padding-top: 1px;
    }

    &.mbsc-label-underline-stacked-has-icon-left.mbsc-ltr {
      left: 2.66667em;
    }

    &.mbsc-label-underline-stacked-has-icon-right.mbsc-rtl {
      right: 2.66667em;
    }

    &.mbsc-label-underline-floating-has-icon-left.mbsc-ltr {
      left: 2em;
    }

    &.mbsc-label-underline-floating-has-icon-right.mbsc-rtl {
      right: 2em;
    }

    /* ------------------------------------------------------------------------------------------- */

    /* Box input --------------------------------------------------------------------------------- */

    /* Inner wrapper */

    &.mbsc-textfield-box,
    &.mbsc-textfield-inner-box {
      border-radius: 0.25em 0.25em 0 0;
    }

    /* Form element */

    &.mbsc-textfield-box {
      height: 2.25em;
      padding: 0 1em;
    }

    &.mbsc-textfield-box-stacked,
    &.mbsc-textfield-box-floating {
      height: 3.5em;
      padding-top: 1.25em;
    }

    &.mbsc-textfield-underline.mbsc-disabled {
      border-style: dotted;
    }

    /* Label */

    &.mbsc-label-box-inline {
      padding-top: 1px;
    }

    &.mbsc-label-box-stacked {
      top: 0.666667em;
    }

    &.mbsc-label-box-floating {
      top: 1em;
    }

    &.mbsc-label-box-floating.mbsc-label-floating-active {
      transform: translateY(-0.625em) scale(0.75);
    }

    /* ------------------------------------------------------------------------------------------- */

    /* Outline input ----------------------------------------------------------------------------- */

    /* Inner wrapper */

    &.mbsc-textfield-inner-outline {
      padding: 0 2px;
    }

    /* Form element */

    &.mbsc-textfield-outline {
      height: 3.5em;
      padding: 2px 1em;
      border: 0;
      border-radius: 4px;
    }

    &.mbsc-textfield-fieldset {
      top: -0.5em;
      border: 1px solid;
      border-radius: 4px;
      transition: border-color 0.2s;
    }

    &.mbsc-textfield-fieldset-has-icon-left {
      padding-left: 3em;
    }

    &.mbsc-textfield-fieldset-has-icon-right {
      padding-right: 3em;
    }

    &.mbsc-textfield-fieldset.mbsc-focus {
      border-width: 2px;
    }

    &.mbsc-textfield-legend {
      padding: 0;
      margin: 0;
      font-size: 0.75em;
      color: transparent;
      width: 0.01px; // Fraction needed for IE11
      white-space: nowrap;
    }

    &.mbsc-textfield-legend-active {
      width: auto;
      padding: 0 0.333334em;
      margin: 0 -0.333334em;
    }

    /* Label */

    &.mbsc-label-outline-inline {
      line-height: 3.375em;
    }

    &.mbsc-label-outline-stacked {
      top: -0.5em;
      margin: 0 2px; // Compensate borders
    }

    &.mbsc-label-outline-floating {
      top: 1em;
      margin: 0 2px; // Compensate borders
    }

    &.mbsc-label-outline-floating.mbsc-label-floating-active {
      margin-top: -1px;
      transform: translateY(-1.5em) scale(0.75);
    }

    /* Error message */

    &.mbsc-error-message-outline {
      margin: 0 2px; // Compensate border
    }

    /* ------------------------------------------------------------------------------------------- */

    /* Box & outline input common styles --------------------------------------------------------- */

    /* Form element */

    &.mbsc-textfield-outline-has-icon-left,
    &.mbsc-textfield-box-has-icon-left {
      padding-left: 3em;
    }

    &.mbsc-textfield-outline-has-icon-right,
    &.mbsc-textfield-box-has-icon-right {
      padding-right: 3em;
    }

    /* Icon */

    &.mbsc-textfield-icon-outline-left,
    &.mbsc-textfield-icon-box-left {
      left: 0.75em;
    }

    &.mbsc-textfield-icon-outline-right,
    &.mbsc-textfield-icon-box-right {
      right: 0.75em;
    }

    /* Label */

    &.mbsc-label-box-stacked.mbsc-ltr,
    &.mbsc-label-outline-stacked.mbsc-ltr {
      left: 1.333334em;
    }

    &.mbsc-label-box-stacked.mbsc-rtl,
    &.mbsc-label-outline-stacked.mbsc-rtl {
      right: 1.333334em;
    }

    &.mbsc-label-outline-stacked-has-icon-left.mbsc-ltr,
    &.mbsc-label-box-stacked-has-icon-left.mbsc-ltr {
      left: 4em;
    }

    &.mbsc-label-box-stacked-has-icon-right.mbsc-rtl,
    &.mbsc-label-outline-stacked-has-icon-right.mbsc-rtl {
      right: 4em;
    }

    &.mbsc-label-box-floating.mbsc-ltr,
    &.mbsc-label-outline-floating.mbsc-ltr {
      left: 1em;
    }

    &.mbsc-label-box-floating.mbsc-rtl,
    &.mbsc-label-outline-floating.mbsc-rtl {
      right: 1em;
    }

    &.mbsc-label-outline-floating-has-icon-left.mbsc-ltr,
    &.mbsc-label-box-floating-has-icon-left.mbsc-ltr {
      left: 3em;
    }

    &.mbsc-label-outline-floating-has-icon-left.mbsc-rtl,
    &.mbsc-label-box-floating-has-icon-left.mbsc-rtl {
      right: 1em;
    }

    &.mbsc-label-outline-floating-has-icon-right.mbsc-rtl,
    &.mbsc-label-box-floating-has-icon-right.mbsc-rtl {
      right: 3em;
    }

    /* Error message */

    &.mbsc-error-message-box.mbsc-ltr,
    &.mbsc-error-message-outline.mbsc-ltr {
      left: 1.333334em;
    }

    &.mbsc-error-message-box.mbsc-rtl,
    &.mbsc-error-message-outline.mbsc-rtl {
      right: 1.333334em;
    }

    &.mbsc-error-message-box.mbsc-error-message-has-icon-left.mbsc-ltr,
    &.mbsc-error-message-outline.mbsc-error-message-has-icon-left.mbsc-ltr {
      left: 4em;
    }

    &.mbsc-error-message-box.mbsc-error-message-has-icon-right.mbsc-rtl,
    &.mbsc-error-message-outline.mbsc-error-message-has-icon-right.mbsc-rtl {
      right: 4em;
    }

    /* ------------------------------------------------------------------------------------------- */

    /* Select */

    &.mbsc-select.mbsc-ltr {
      padding-right: 3em;
    }

    &.mbsc-select.mbsc-rtl {
      padding-left: 3em;
    }

    &.mbsc-select.mbsc-textfield-has-icon-right.mbsc-ltr {
      padding-right: 4.5em;
    }

    &.mbsc-select.mbsc-textfield-has-icon-left.mbsc-rtl {
      padding-left: 4.5em;
    }

    &.mbsc-select.mbsc-textfield-underline-has-icon-right.mbsc-ltr {
      padding-right: 3.75em;
    }

    &.mbsc-select.mbsc-textfield-underline-has-icon-left.mbsc-rtl {
      padding-left: 3.75em;
    }

    /* Select icon */

    &.mbsc-select-icon {
      top: 0.375em;
    }

    &.mbsc-select-icon.mbsc-ltr {
      right: 0.75em;
    }

    &.mbsc-select-icon.mbsc-rtl {
      left: 0.75em;
    }

    &.mbsc-select-icon-right.mbsc-ltr {
      right: 3em;
    }

    &.mbsc-select-icon-left.mbsc-rtl {
      left: 3em;
    }

    &.mbsc-select-icon-stacked,
    &.mbsc-select-icon-floating,
    &.mbsc-select-icon-outline {
      top: 1em;
    }

    &.mbsc-select-icon-underline {
      top: 0.25em;
    }

    &.mbsc-select-icon-underline.mbsc-ltr {
      right: 0;
    }

    &.mbsc-select-icon-underline.mbsc-rtl {
      left: 0;
    }

    &.mbsc-select-icon-underline.mbsc-select-icon-right.mbsc-ltr {
      right: 2.25em;
    }

    &.mbsc-select-icon-underline.mbsc-select-icon-left.mbsc-rtl {
      left: 2.25em;
    }

    &.mbsc-select-icon-underline.mbsc-select-icon-floating,
    &.mbsc-select-icon-underline.mbsc-select-icon-stacked {
      top: 1.125em;
    }

    /* Textarea */

    &.mbsc-textarea {
      height: 1.875em;
      padding-bottom: 0.375em;
    }

    &.mbsc-textarea.mbsc-textfield-stacked,
    &.mbsc-textarea.mbsc-textfield-floating {
      padding-top: 0;
    }

    &.mbsc-textarea.mbsc-textfield-outline {
      height: 1.5em;
      padding-top: 0;
      padding-bottom: 0;
    }

    &.mbsc-textarea.mbsc-textfield-underline {
      padding-bottom: 0.3125em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-box {
      padding-top: 0.375em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-stacked,
    &.mbsc-textarea-inner.mbsc-textfield-inner-floating {
      padding-top: 1.625em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-outline {
      padding-top: 1em;
      padding-bottom: 1em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-underline {
      padding-top: 0.25em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-underline.mbsc-textfield-inner-stacked,
    &.mbsc-textarea-inner.mbsc-textfield-inner-underline.mbsc-textfield-inner-floating {
      padding-top: 1.125em;
    }
  }

  @include mbsc-mobiscroll-input('mobiscroll', $mbsc-mobiscroll-colors);
  @include mbsc-mobiscroll-input('mobiscroll-dark', $mbsc-mobiscroll-dark-colors);
}



$mbsc-windows-input-background: $mbsc-input-background-light !default;
$mbsc-windows-input-text: $mbsc-input-text-light !default;
$mbsc-windows-input-accent: $mbsc-input-accent-light !default;
$mbsc-windows-input-border: $mbsc-input-border-light !default;

$mbsc-windows-dark-input-background: $mbsc-input-background-dark !default;
$mbsc-windows-dark-input-text: $mbsc-input-text-dark !default;
$mbsc-windows-dark-input-accent: $mbsc-input-accent-dark !default;
$mbsc-windows-dark-input-border: $mbsc-input-border-dark !default;

$mbsc-windows-colors: map-merge(
  $mbsc-windows-colors,
  (
    // Colors map
    'input-background': $mbsc-windows-input-background,
    'input-text': $mbsc-windows-input-text,
    'input-accent': $mbsc-windows-input-accent,
    'input-border': $mbsc-windows-input-border
  )
);

$mbsc-windows-dark-colors: map-merge(
  $mbsc-windows-dark-colors,
  (
    // Colors map
    'input-background': $mbsc-windows-dark-input-background,
    'input-text': $mbsc-windows-dark-input-text,
    'input-accent': $mbsc-windows-dark-input-accent,
    'input-border': $mbsc-windows-dark-input-border
  )
);

@mixin mbsc-windows-input($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $bg-param: map-get($params, 'input-background');
  $text-param: map-get($params, 'input-text');
  $brd-param: map-get($params, 'input-border');
  $acc-param: map-get($params, 'input-accent');
  $err-param: map-get($params, 'form-error');

  $background: if($bg-param, $bg-param, $background);
  $text: if($text-param, $text-param, $text);
  $accent: if($acc-param, $acc-param, $accent);
  $error: if($err-param, $err-param, $mbsc-windows-error);

  $input-backgound: $background;
  $input-hover: $text;
  $input-border: '';
  $input-disabled-background: '';
  $input-disabled-color: '';
  $input-tag-background: '';

  // Light background
  @if (lightness($background) > 50%) {
    $input-border: lighten($text, 33%);
    $input-disabled-background: darken($background, 5%);
    $input-disabled-color: lighten($text, 42%);
    $input-tag-background: darken($background, 10%);
  }

  // Dark background
  @else {
    $input-border: darken($text, 33%);
    $input-disabled-background: lighten($background, 5%);
    $input-disabled-color: darken($text, 42%);
    $input-tag-background: lighten($background, 27%);
  }

  $input-border: if($brd-param, $brd-param, $input-border);

  .mbsc-#{$theme} {
    /* Wrapper */

    &.mbsc-textfield-wrapper {
      color: $text;
    }

    &.mbsc-textfield-wrapper.mbsc-disabled {
      color: $input-disabled-color;
    }

    /* Form element */

    &.mbsc-textfield {
      background: $input-backgound;
      color: $text;
    }

    &.mbsc-textfield-box,
    &.mbsc-textfield-outline {
      border-color: $input-border;
    }

    &.mbsc-textfield.mbsc-hover {
      border-color: $input-hover;
    }

    &.mbsc-textfield-box.mbsc-focus,
    &.mbsc-textfield-outline.mbsc-focus {
      border-color: $accent;
      box-shadow: 0 0 0 1px $accent inset;
    }

    &.mbsc-textfield.mbsc-disabled {
      background: $input-disabled-background;
      border-color: $input-disabled-background;
    }

    &.mbsc-textfield.mbsc-error {
      border-color: $error;
    }

    &.mbsc-textfield-box.mbsc-error.mbsc-focus,
    &.mbsc-textfield-outline.mbsc-error.mbsc-focus {
      box-shadow: 0 0 0 1px $error inset;
    }

    /* Error message */

    &.mbsc-error-message {
      color: $error;
    }

    /* Label */

    &.mbsc-label {
      color: $text;
    }

    /* Underline input --------------------------------------------------------------------------- */

    &.mbsc-textfield-wrapper-underline {
      border-color: $input-border;
    }

    &.mbsc-textfield-wrapper-underline.mbsc-hover {
      border-color: $input-hover;
    }

    &.mbsc-textfield-wrapper-underline.mbsc-focus {
      border-color: $accent;
      box-shadow: 0 1px 0 0 $accent;
    }

    &.mbsc-textfield-wrapper-underline.mbsc-disabled {
      border-color: $input-disabled-background;
    }

    &.mbsc-textfield-wrapper-underline.mbsc-error {
      border-color: $error;
    }

    &.mbsc-textfield-wrapper-underline.mbsc-error.mbsc-focus {
      box-shadow: 0 1px 0 0 $error;
    }

    /* ----------------------------------------------------------------------------------------- */

    /* Input tags */

    &.mbsc-textfield-tag {
      background: $input-tag-background;
    }
  }
}


@if ($mbsc-windows-theme and $mbsc-input) {
  .mbsc-windows {
    /* Wrapper */

    &.mbsc-textfield-wrapper {
      margin: 1em;
    }

    &.mbsc-textfield-wrapper-inline {
      margin: 1.75em 1em;
    }

    &.mbsc-textfield-wrapper-floating {
      padding-top: 1.75em;
    }

    /* Form element */

    &.mbsc-textfield {
      height: 2em;
      padding: 0 0.5em;
      border: 1px solid;
      border-radius: 2px;
    }

    /* Icons */

    &.mbsc-textfield-icon {
      top: 0.4375em;
      width: 1.125em;
      height: 1.125em;
      line-height: 1.125em;
      margin: 0 1px; // For border
    }

    &.mbsc-textfield-has-icon-right {
      padding-right: 2em;
    }

    &.mbsc-textfield-has-icon-left {
      padding-left: 2em;
    }

    &.mbsc-textfield-icon-left {
      left: 0.4375em;
    }

    &.mbsc-textfield-icon-right {
      right: 0.4375em;
    }

    /* Error  */

    &.mbsc-error-message {
      font-size: 0.75em;
    }

    /* Label */

    &.mbsc-label {
      line-height: 2em;
      font-size: 0.875em;
      font-weight: 600;
    }

    &.mbsc-label-inline {
      line-height: 2.285715em;
    }

    &.mbsc-label-floating {
      top: 2.142858em;
    }

    &.mbsc-label-stacked {
      position: static;
    }

    &.mbsc-label-floating.mbsc-ltr {
      transform: translateX(0.571429em);
    }

    &.mbsc-label-floating.mbsc-rtl {
      transform: translateX(-0.5em);
    }

    // TODO: check if simplify elsewhere too if there is a separate mbsc-label-{labeltype}-has-icon-{align} class?
    &.mbsc-label-underline-floating-has-icon-left.mbsc-ltr,
    &.mbsc-label-outline-floating-has-icon-left.mbsc-ltr,
    &.mbsc-label-box-floating-has-icon-left.mbsc-ltr {
      transform: translateX(2.285715em);
    }

    &.mbsc-label-underline-floating-has-icon-right.mbsc-rtl,
    &.mbsc-label-outline-floating-has-icon-right.mbsc-rtl,
    &.mbsc-label-box-floating-has-icon-right.mbsc-rtl {
      transform: translateX(-2.285715em);
    }

    &.mbsc-label.mbsc-label-floating-active {
      transform: translate(0, -2.142858em);
    }

    /* Underline input --------------------------------------------------------------------------- */

    /* Wrapper */
    &.mbsc-textfield-wrapper-underline {
      border-bottom: 1px solid;
    }

    /* Form element */
    &.mbsc-textfield-underline {
      border: 0;
      border-radius: 0;
    }

    /* ------------------------------------------------------------------------------------------- */

    /* Select */

    &.mbsc-select.mbsc-ltr {
      padding-right: 2em;
    }

    &.mbsc-select.mbsc-rtl {
      padding-left: 2em;
    }

    &.mbsc-select.mbsc-textfield-has-icon-right.mbsc-ltr {
      padding-right: 3.125em;
    }

    &.mbsc-select.mbsc-textfield-has-icon-left.mbsc-rtl {
      padding-left: 3.125em;
    }

    /* Select icon */

    &.mbsc-select-icon {
      top: 0.4375em;
      width: 1.125em;
      height: 1.125em;
      line-height: 1.125em;
      margin: 0 1px; // For border
    }

    &.mbsc-select-icon.mbsc-ltr {
      right: 0.4375em;
    }

    &.mbsc-select-icon.mbsc-rtl {
      left: 0.4375em;
    }

    &.mbsc-select-icon-right.mbsc-ltr {
      right: 2em;
    }

    &.mbsc-select-icon-left.mbsc-rtl {
      left: 2em;
    }

    /* Textarea */

    &.mbsc-textarea {
      height: 3.5em;
      padding-top: 0.1875em;
      padding-bottom: 0.1875em;
    }

    /* Input tags */

    &.mbsc-textfield.mbsc-textfield-tags {
      padding: 0.125em;
      min-height: 2em;
    }

    &.mbsc-textfield-tag {
      margin: 0.125em;
    }

    &.mbsc-textfield-tag.mbsc-ltr {
      margin-right: 0.125em;
    }

    &.mbsc-textfield-tag.mbsc-rtl {
      margin-left: 0.125em;
    }

    &.mbsc-textfield-tag-text {
      line-height: 1.571429em; // 22px
    }

    &.mbsc-textfield-tag-clear.mbsc-icon {
      width: 1.375em;
      height: 1.375em;
      border-width: 0.125em;
    }

    &.mbsc-textfield-tags-placeholder {
      padding: 0 0.375em;
      line-height: 1.625em;
    }
  }

  @include mbsc-windows-input('windows', $mbsc-windows-colors);
  @include mbsc-windows-input('windows-dark', $mbsc-windows-dark-colors);
}


@if $mbsc-input {
  // Dummy animation to detect initial autofill in webkit browsers
  @keyframes autofill {
    from {
      opacity: 1;
    }

    to {
      opacity: 1;
    }
  }

  /* Wrapper */

  .mbsc-textfield-wrapper {
    position: relative;
    display: block;
    margin: 0;
    z-index: 0;
    user-select: none;
  }

  .mbsc-textfield-wrapper-inline {
    display: flex;
    // align-items: center;
  }

  .mbsc-form-grid .mbsc-textfield-wrapper-box,
  .mbsc-form-grid .mbsc-textfield-wrapper-outline {
    margin: 0.75em 1em;
  }

  /* Inner wrapper */

  .mbsc-textfield-inner {
    box-sizing: border-box;
    position: relative;
    display: block;
  }

  .mbsc-textfield-inner-inline {
    flex: 1 auto;
  }

  /* Form element */

  .mbsc-textfield {
    display: block;
    width: 100%;
    font-size: 1em;
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    outline: 0;
    font-family: inherit;
    box-sizing: border-box;
    appearance: none;
    -webkit-appearance: none;
  }

  .mbsc-textfield:-webkit-autofill {
    animation-name: autofill;
  }

  .mbsc-textfield::-webkit-inner-spin-button {
    height: 2em;
    align-self: center;
  }

  .mbsc-textfield::-moz-placeholder {
    opacity: 0.5;
    color: inherit;
  }

  .mbsc-textfield::-webkit-input-placeholder {
    opacity: 0.5;
    color: inherit;
  }

  /* Floating label */

  .mbsc-textfield-floating:-ms-input-placeholder {
    color: transparent;
  }

  .mbsc-textfield-floating::-moz-placeholder {
    opacity: 0;
    transition: opacity 0.2s;
  }

  .mbsc-textfield-floating::-webkit-input-placeholder {
    opacity: 0;
    transition: opacity 0.2s;
  }

  .mbsc-textfield-floating::-webkit-datetime-edit {
    color: transparent;
  }

  .mbsc-textfield-floating-active:-ms-input-placeholder {
    color: inherit;
  }

  .mbsc-textfield-floating-active::-moz-placeholder {
    opacity: 0.5;
  }

  .mbsc-textfield-floating-active::-webkit-input-placeholder {
    opacity: 0.5;
  }

  .mbsc-textfield-floating-active::-webkit-datetime-edit {
    color: inherit;
  }

  .mbsc-textfield-floating .mbsc-textfield-tags-placeholder {
    opacity: 0;
    transition: opacity 0.2s;
  }

  .mbsc-textfield-floating-active .mbsc-textfield-tags-placeholder {
    opacity: 0.5;
  }

  .mbsc-textfield-fieldset {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0 1em;
    pointer-events: none;
  }

  /* Icon */

  .mbsc-textfield-icon {
    position: absolute;
    top: 0;
    text-align: center;
  }

  .mbsc-textfield-icon-left {
    left: 0;
  }

  .mbsc-textfield-icon-right {
    right: 0;
  }

  /* Label */

  .mbsc-label {
    position: absolute;
    z-index: 1;
    top: 0;
    line-height: 1em;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
  }

  .mbsc-label-inline {
    position: static;
    overflow: hidden;
    flex: 0 0 auto;
    width: 30%;
    max-width: 12.5em;
    // box-sizing: content-box;
    box-sizing: border-box;
    pointer-events: auto;
  }

  .mbsc-label-floating-animate {
    transition: transform 0.2s;
  }

  .mbsc-label-floating.mbsc-ltr {
    transform-origin: top left;
  }

  .mbsc-label-floating.mbsc-rtl {
    transform-origin: top right;
  }

  /* Error message */

  .mbsc-error-message {
    position: absolute;
    top: 100%;
    font-size: 0.75em;
    line-height: 1.5em;
  }

  /* File input */

  .mbsc-textfield-file {
    position: absolute;
    left: 0;
    opacity: 0;
  }

  /* Select */

  .mbsc-select {
    cursor: pointer;
  }

  .mbsc-select-icon {
    position: absolute;
    text-align: center;
    pointer-events: none;
  }

  /* Textarea */

  .mbsc-textarea {
    resize: none;
    overflow: hidden;
    line-height: 1.5em;
  }

  /* Password toggle */

  .mbsc-toggle-icon:hover {
    cursor: pointer;
  }

  /* Input tags */

  .mbsc-textfield.mbsc-textfield-hidden.mbsc-textarea {
    padding: 0;
    width: 100%;
    height: 100%;
    border: 0;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 0;
  }

  .mbsc-textfield.mbsc-textfield-tags.mbsc-textarea {
    display: block;
    overflow: auto;
    height: auto;
    max-height: 5.5em;
  }

  .mbsc-textfield-tag {
    display: inline-block;
    margin: 0.25em 0;
    line-height: normal;
  }

  .mbsc-textfield-tag.mbsc-ltr {
    margin-right: 0.5em;
    padding-left: 0.75em;
  }

  .mbsc-textfield-tag.mbsc-rtl {
    margin-left: 0.5em;
    padding-right: 0.75em;
  }

  .mbsc-textfield-tag-text {
    font-size: 0.875em;
    line-height: 1.857143em; // 26px
  }

  .mbsc-textfield-tag-clear.mbsc-icon {
    width: 1.625em;
    height: 1.625em;
    vertical-align: top;
    border: 0.25em solid transparent;
    box-sizing: border-box;
    cursor: pointer;
  }

  .mbsc-textfield-tags-placeholder {
    opacity: 0.5;
    line-height: 2.125em;
  }
}



@mixin mbsc-material-scroller($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  .mbsc-#{$theme} {
    &.mbsc-scroller-wheel-cont::after,
    &.mbsc-scroller-wheel-cont::before {
      border-color: $accent;
    }

    &.mbsc-scroller-wheel-multi::after,
    &.mbsc-scroller-wheel-multi::before {
      border-color: transparent;
    }

    &.mbsc-scroller-wheel-item.mbsc-active,
    &.mbsc-scroller-wheel-item.mbsc-hover,
    &.mbsc-scroller-wheel-item.mbsc-focus,
    &.mbsc-scroller-wheel-header.mbsc-focus {
      background: rgba(0, 0, 0, 0.05);
    }

    &.mbsc-wheel-checkmark::after {
      border-color: $accent;
    }
  }
}


@if ($mbsc-material-theme and $mbsc-scroller) {
  .mbsc-material {
    &.mbsc-scroller-wheel-wrapper {
      margin: 0 0.25em;
      padding: 0.5em 0;
    }

    &.mbsc-scroller-wheel-cont::after,
    &.mbsc-scroller-wheel-cont::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;

      border-width: 2px;
      border-top-style: solid;
      z-index: 1;
    }

    &.mbsc-scroller-wheel-cont::after {
      bottom: -2px;
    }

    &.mbsc-scroller-wheel-cont::before {
      top: -2px;
    }

    &.mbsc-scroller-wheel-group {
      padding: 2em 0.25em;
    }

    &.mbsc-scroller-wheel-item {
      padding: 0 0.272727em;
      font-size: 1.375em;
      text-align: center;
    }

    &.mbsc-wheel-item-multi {
      padding: 0 2em; // .5em added for more space between checkmark and text
    }

    &.mbsc-scroller-wheel-header {
      font-weight: bold;
      font-size: 1.125em;
    }

    /* Checkmark styling */

    &.mbsc-wheel-checkmark {
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      display: none;
      border-radius: 0.1875em;
      width: 1.3em;
      height: 1.3em;
      margin-top: -0.55em;

      &::after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        display: block;

        top: 0.27273em;
        left: 0.27273em;
        width: 0.8125em;
        height: 0.4375em;
        opacity: 0;
        border: 0.125em solid;
        border-top: 0;
        border-right: 0;
        transform: scale(0) rotate(-45deg);
        transition: transform 0.1s ease-out;
      }

      &.mbsc-selected::after {
        opacity: 1;
        transform: scale(1) rotate(-45deg);
      }

      &.mbsc-ltr {
        left: 0.25em;
      }

      &.mbsc-rtl {
        right: 0.25em;
      }
    }

    &.mbsc-wheel-item-multi &.mbsc-wheel-checkmark {
      display: block;
    }

    /* Desktop styling */

    &.mbsc-scroller-pointer {
      .mbsc-scroller-wheel-group {
        padding: 0;
      }

      .mbsc-scroller-wheel-wrapper {
        margin: 0;
        padding: 0;
      }

      .mbsc-scroller-wheel-item {
        font-size: 1em;
        padding: 0 1.25em;
      }

      .mbsc-wheel-item-multi {
        padding: 0 2em;
      }

      .mbsc-wheel-item-multi {
        &.mbsc-ltr {
          text-align: left;
        }

        &.mbsc-rtl {
          text-align: right;
        }
      }
    }
  }

  @include mbsc-material-scroller('material', $mbsc-material-colors);
  @include mbsc-material-scroller('material-dark', $mbsc-material-dark-colors);
}



@mixin mbsc-ios-scroller($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $item-color: hsl(hue($text), saturation($text), 62%);

  $background-alt: '';
  $background-top-bottom: '';
  $item-3d: '';
  $overlay: '';
  $scroller-background: '';
  $scroller-selected-line: '';

  @if (lightness($background) > 50%) {
    $background-alt: lighten($background, 3%);
    $background-top-bottom: adjust-hue(darken(saturate($background, 12%), 13%), 216deg);
    $item-3d: darken($background, 33%);
    $overlay: $background-alt;
    $scroller-background: $background-alt;
    $scroller-selected-line: #000;
  } @else {
    $background-alt: lighten($background, 11%);
    $background-top-bottom: lighten($background, 11%);
    $item-3d: lighten($background, 40%);
    $overlay: $background-alt;
    $scroller-background: $background-alt;
    $scroller-selected-line: #fff;
  }

  .mbsc-#{$theme} {
    &.mbsc-scroller {
      background: $scroller-background;
    }

    &.mbsc-scroller-top,
    &.mbsc-scroller-bottom {
      background: $background-top-bottom;
    }

    &.mbsc-scroller-wheel-overlay {
      background: linear-gradient($overlay, rgba($overlay, 0) 52%, rgba($overlay, 0) 48%, $overlay);
    }

    &.mbsc-scroller-wheel-overlay-top,
    &.mbsc-scroller-wheel-overlay-bottom {
      background: linear-gradient(
        $background-top-bottom,
        rgba($background-top-bottom, 0) 52%,
        rgba($background-top-bottom, 0) 48%,
        $background-top-bottom
      );
    }

    &.mbsc-scroller-wheel-line {
      background: rgba($scroller-selected-line, 0.1);
    }

    &.mbsc-scroller-wheel-item {
      color: $item-color;
    }

    &.mbsc-scroller-wheel-item.mbsc-active,
    &.mbsc-scroller-wheel-item.mbsc-hover,
    &.mbsc-scroller-wheel-item.mbsc-focus,
    &.mbsc-scroller-wheel-header.mbsc-focus {
      background: rgba($accent, 0.15);
    }

    &.mbsc-scroller-wheel-item-2d,
    &.mbsc-scroller-wheel-item.mbsc-selected {
      color: $text;
    }

    /* 3D */

    &.mbsc-scroller-wheel-cont-3d {
      background: $scroller-background;
    }

    &.mbsc-scroller-wheel-item-3d {
      color: $item-3d;
    }

    &.mbsc-scroller-wheel-header,
    &.mbsc-scroller-wheel-item-3d.mbsc-wheel-item-multi {
      color: $text;
    }

    &.mbsc-scroller-wheel-item-3d.mbsc-wheel-item-multi.mbsc-selected-3d {
      color: $accent;
    }

    &.mbsc-scroller-wheel-cont-top.mbsc-scroller-wheel-cont-3d,
    &.mbsc-scroller-wheel-cont-bottom.mbsc-scroller-wheel-cont-3d {
      background: $background-top-bottom;
    }

    &.mbsc-scroller-wheel-cont-inline.mbsc-scroller-wheel-cont-3d {
      background: $background-alt;
    }

    /* Desktop style */

    &.mbsc-scroller-pointer {
      &.mbsc-scroller {
        background: $scroller-background;
      }

      &.mbsc-scroller-inline {
        background: $background-alt;
      }

      .mbsc-scroller-wheel-item {
        color: $text;
      }

      .mbsc-scroller-wheel-item.mbsc-selected {
        color: $accent;
      }
    }
  }
}


@if ($mbsc-ios-theme and $mbsc-scroller) {
  .mbsc-ios {
    &.mbsc-scroller-wheel-multi.mbsc-scroller-wheel-cont-3d {
      visibility: hidden;
    }

    &.mbsc-scroller-wheel-group-cont {
      padding: 0 0.625em;
    }

    &.mbsc-scroller-wheel-group {
      padding: 0.625em;
    }

    &.mbsc-wheel-group-checkmark {
      padding: 0.625em 0;
    }

    &.mbsc-scroller-wheel-3d {
      overflow: visible;
    }

    &.mbsc-scroller-wheel-line {
      display: block;
      z-index: 1;
      border-radius: 0.5em;
      margin: 0 0.625em;
    }

    &.mbsc-scroller-wheel-overlay {
      display: block;
    }

    &.mbsc-scroller-wheel-item {
      padding: 0 0.5em;
      font-size: 1.25em;
      text-align: center;
      box-sizing: border-box;
      border-radius: 0.5em;
    }

    &.mbsc-scroller-wheel-item-3d {
      font-size: 1.125em;
    }

    &.mbsc-wheel-item-checkmark {
      padding-left: 1.75em;
      padding-right: 1.75em;
    }

    &.mbsc-scroller-wheel-header {
      font-size: 0.875em;
    }

    /* checkmark */

    &.mbsc-wheel-checkmark {
      display: none;
      position: absolute;
      width: 1.75em;
      height: 1.75em;
      top: 50%;
      margin-top: -0.875em;

      &::after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        display: block;
        opacity: 0;
        transform: rotate(-45deg);

        top: 32%;
        left: 26%;
        width: 0.75em;
        height: 0.375em;
        border: 0.125em solid currentColor;
        border-top: 0;
        border-right: 0;
        transition: opacity 0.2s ease-in-out;
      }

      &.mbsc-selected::after {
        opacity: 1;
      }

      &.mbsc-ltr {
        left: 0;
      }

      &.mbsc-rtl {
        right: 0;
      }
    }

    &.mbsc-wheel-item-multi &.mbsc-wheel-checkmark,
    &.mbsc-scroller-pointer &.mbsc-wheel-item-checkmark .mbsc-wheel-checkmark {
      display: block;
    }

    /* Desktop styling */

    &.mbsc-scroller-pointer {
      .mbsc-scroller-wheel-group-cont,
      .mbsc-scroller-wheel-group {
        padding: 0;
      }

      .mbsc-scroller-wheel-wrapper {
        padding: 0.1875em 0;
      }

      .mbsc-scroller-wheel-overlay {
        display: none;
      }

      .mbsc-scroller-wheel-item {
        font-size: 1em;
        padding: 0 0.75em;
        margin: 0 0.1875em;
      }

      .mbsc-scroller-wheel-line {
        margin: 0 0.1875em;
      }

      .mbsc-wheel-item-checkmark.mbsc-ltr {
        text-align: left;
        padding-left: 1.75em;
      }

      .mbsc-wheel-item-checkmark.mbsc-rtl {
        text-align: right;
        padding-right: 1.75em;
      }

      // Group styling

      .mbsc-scroller-wheel-header {
        font-size: 0.75em;
        font-weight: 700;
      }
    }
  }

  @include mbsc-ios-scroller('ios', $mbsc-ios-colors);
  @include mbsc-ios-scroller('ios-dark', $mbsc-ios-dark-colors);
}



@mixin mbsc-windows-scroller($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $border: '';
  $wheel-button: '';

  @if (lightness($background) > 50%) {
    $border: darken($background, 10%);
  } @else {
    $border: lighten($background, 10%);
  }

  .mbsc-#{$theme} {
    &.mbsc-scroller-wheel-wrapper {
      color: $text;
      border-color: $border;
    }

    &.mbsc-scroller-wheel-line {
      background: rgba($accent, 0.4);
    }

    &.mbsc-scroller-wheel-item.mbsc-active,
    &.mbsc-scroller-wheel-item.mbsc-hover,
    &.mbsc-scroller-wheel-item.mbsc-focus,
    &.mbsc-scroller-wheel-header.mbsc-focus {
      background: rgba($text, 0.1);
    }

    &.mbsc-wheel-checkmark::after {
      border-color: $accent;
    }
  }
}


@if ($mbsc-windows-theme and $mbsc-scroller) {
  .mbsc-windows {
    &.mbsc-scroller {
      position: relative;
    }

    &.mbsc-scroller-wheel-wrapper.mbsc-ltr {
      border-left-width: 1px;
      border-left-style: solid;
    }

    &.mbsc-scroller-wheel-wrapper-0.mbsc-ltr {
      border-left: 0;
    }

    &.mbsc-scroller-wheel-wrapper.mbsc-rtl {
      border-right-width: 1px;
      border-right-style: solid;
    }

    &.mbsc-scroller-wheel-wrapper-0.mbsc-rtl {
      border-right: 0;
    }

    &.mbsc-scroller-wheel-item {
      padding: 0 0.5em;
      box-sizing: border-box;
      text-align: center;
    }

    &.mbsc-wheel-item-multi {
      padding: 0 2.0625em; // .5em is added for more space between checkmark and text

      &.mbsc-ltr {
        text-align: left;
      }

      &.mbsc-rtl {
        text-align: right;
      }
    }

    &.mbsc-scroller-wheel-line {
      display: block;
      z-index: 1;
    }

    &.mbsc-scroller-wheel-header {
      font-size: 1.375em;
    }

    /* Checkmark styling */

    &.mbsc-wheel-checkmark {
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      display: none;

      margin-top: -0.8125em;
      width: 1.5625em;
      height: 1.5625em;

      &::after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        display: block;
        opacity: 0;
        transform: rotate(-45deg);

        top: 28%;
        left: 21%;
        width: 0.875em;
        height: 0.475em;
        border: 0.125em solid;
        border-top: 0;
        border-right: 0;
      }

      &.mbsc-selected::after {
        opacity: 1;
      }

      &.mbsc-ltr {
        left: 0.25em;
      }

      &.mbsc-rtl {
        right: 0.25em;
      }
    }

    &.mbsc-wheel-item-multi &.mbsc-wheel-checkmark {
      display: block;
    }

    /* Desktop styling */

    &.mbsc-scroller-pointer {
      .mbsc-scroller-wheel-item {
        padding: 0 1.25em;
      }

      .mbsc-wheel-item-multi {
        padding: 0 2.0625em; // .5em is added for more space between checkmark and text
      }
    }

    &.mbsc-scroller-bar::after {
      border-radius: 0;
    }
  }

  @include mbsc-windows-scroller('windows', $mbsc-windows-colors);
  @include mbsc-windows-scroller('windows-dark', $mbsc-windows-dark-colors);
}


@if $mbsc-scroller {
  .mbsc-scroller {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    text-align: center;
    user-select: none;
  }

  .mbsc-scroller-wheel-group-cont {
    position: relative;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
  }

  .mbsc-scroller-wheel-group-cont:first-child:last-child {
    display: block;
  }

  .mbsc-scroller-wheel-group {
    margin: 0 auto;
    position: relative;
    justify-content: center;
  }

  .mbsc-scroller-wheel-group-3d {
    perspective: 100em;
  }

  .mbsc-scroller-wheel-wrapper {
    display: block;
    max-width: 100%;
    position: relative;
    touch-action: none;
    /* Temp */
    min-width: 80px;
  }

  .mbsc-scroller-pointer .mbsc-scroller-wheel-wrapper-0:last-child {
    flex: 1 1 auto; // Expand single wheel in desktop mode
  }

  .mbsc-scroller-wheel-line {
    display: none;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    pointer-events: none;
    transform: translateY(-50%);
  }

  .mbsc-scroller-wheel-overlay {
    display: none;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }

  .mbsc-scroller-wheel {
    display: block;
    overflow: hidden;
    /* Fixes Firefox rendering issues */
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    margin: -1px 0;
  }

  .mbsc-scroller-wheel-cont {
    position: relative;
    z-index: 1;
    top: 50%;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    transform: translateY(-50%);
  }

  .mbsc-scroller-wheel-cont-3d {
    overflow: hidden;
    border: 0;
  }

  .mbsc-scroller-wheel-cont-3d > div {
    position: relative;
    top: -1px;
  }

  .mbsc-scroller-wheel-wrapper-3d,
  .mbsc-scroller-wheel-3d {
    transform-style: preserve-3d;
  }

  .mbsc-scroller-items-3d {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform-style: preserve-3d;
    transform: translateY(-50%);
  }

  .mbsc-scroller .mbsc-scroller-wheel,
  .mbsc-scroller .mbsc-scroller-wheel-cont {
    box-sizing: content-box;
  }

  .mbsc-scroller-wheel-item {
    display: block;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mbsc-scroller-wheel-item:focus,
  .mbsc-scroller-wheel-header:focus {
    outline: 0;
  }

  .mbsc-scroller-wheel-item-3d {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .mbsc-scroller-wheel-item.mbsc-disabled {
    opacity: 0.3;
  }

  .mbsc-scroller-wheel-header {
    display: block; // Needed by Angular
    padding: 0 0.5em;

    &.mbsc-ltr {
      text-align: left;
    }

    &.mbsc-rtl {
      text-align: right;
    }
  }

  .mbsc-scroller-bar-cont {
    position: absolute;
    z-index: 4;
    top: 0;
    bottom: 0;
    width: 10px;
    opacity: 0;
    background: rgba(0, 0, 0, 0.05);
    transform: translateZ(0);
    transition: opacity 0.2s;

    &.mbsc-ltr {
      right: 0;
    }

    &.mbsc-rtl {
      left: 0;
    }
  }

  .mbsc-scroller-bar-hidden {
    display: none;
  }

  .mbsc-scroller-wheel:hover .mbsc-scroller-bar-cont,
  .mbsc-scroller-bar-started {
    opacity: 1;
  }

  .mbsc-scroller-bar {
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
    // height: 100%;

    &::after {
      content: '';
      position: absolute;
      top: 2px;
      right: 2px;
      bottom: 2px;
      left: 2px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 3px;
    }
  }
}



@mixin mbsc-ios-datetime($theme, $params) {
}


@if ($mbsc-ios-theme and $mbsc-datepicker) {
  .mbsc-ios {
    &.mbsc-datetime-year-wheel .mbsc-scroller-wheel-item {
      min-width: 3.8em;
    }

    &.mbsc-datetime-month-wheel .mbsc-scroller-wheel-item {
      text-align: left;
    }

    &.mbsc-datetime-day-wheel .mbsc-scroller-wheel-item,
    &.mbsc-datetime-hour-wheel .mbsc-scroller-wheel-item,
    &.mbsc-datetime-date-wheel .mbsc-scroller-wheel-item {
      text-align: right;
    }
  }
}



@mixin mbsc-material-datetime($theme, $params) {
}




@mixin mbsc-windows-datetime($theme, $params) {
}


@if ($mbsc-windows-theme and $mbsc-datepicker) {
  .mbsc-windows {
    &.mbsc-datetime-date-wheel.mbsc-scroller-wheel-wrapper {
      min-width: 120px;
    }

    &.mbsc-datetime-month-wheel .mbsc-scroller-wheel-item,
    &.mbsc-datetime-date-wheel .mbsc-scroller-wheel-item {
      text-align: left;
    }

    &.mbsc-datetime-month-wheel.mbsc-rtl .mbsc-scroller-wheel-item,
    &.mbsc-datetime-date-wheel.mbsc-rtl .mbsc-scroller-wheel-item {
      text-align: right;
    }
  }
}




@mixin mbsc-mobiscroll-segmented($theme, $params) {
  $background: map-get($params, 'background');
  $accent: map-get($params, 'accent');

  $bg-param: map-get($params, 'form-background');
  $acc-param: map-get($params, 'form-accent');

  $background: if($bg-param, $bg-param, $background);
  $accent: if($acc-param, $acc-param, $accent);

  $btn-light: '';

  @if (lightness($background) > 50%) {
    $btn-light: darken($background, 3%);
  } @else {
    $btn-light: $background;
  }

  .mbsc-#{$theme} {
    &.mbsc-segmented-button.mbsc-button {
      color: $accent;
      border-color: $accent;

      &.mbsc-hover {
        background: rgba($accent, 0.1);
      }

      &.mbsc-active {
        background: rgba($accent, 0.3);
      }

      &.mbsc-selected {
        background: $accent;
        color: $btn-light;
      }
    }
  }
}


@if ($mbsc-mobiscroll-theme and $mbsc-segmented) {
  .mbsc-mobiscroll {
    &.mbsc-segmented {
      padding: 0.5em 1em;
    }

    &.mbsc-segmented-item:first-child {
      .mbsc-segmented-button {
        border-top-left-radius: 0.25em;
        border-bottom-left-radius: 0.25em;
      }
    }

    &.mbsc-segmented-item:last-child {
      .mbsc-segmented-button {
        border-top-right-radius: 0.25em;
        border-bottom-right-radius: 0.25em;
        border-right-width: 0.142858em;
      }
    }

    &.mbsc-segmented-button.mbsc-button {
      margin: 0;
      padding: 0.428572em 1.142858em;
      background: none;
      border: 0.142858em solid transparent;
      border-right-width: 0;
      border-radius: 0;
      font-size: 0.875em;
      line-height: 1.428572em;

      &.mbsc-active,
      &.mbsc-hover {
        opacity: 1;
      }

      /* Color presets */

      &.mbsc-button-primary {
        color: $mbsc-mobiscroll-primary;
        border-color: $mbsc-mobiscroll-primary;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-primary, 0.2);
        }

        &.mbsc-focus {
          background: rgba($mbsc-mobiscroll-primary, 0.3);
        }

        &.mbsc-selected {
          background: $mbsc-mobiscroll-primary;
        }
      }

      &.mbsc-button-secondary {
        color: $mbsc-mobiscroll-secondary;
        border-color: $mbsc-mobiscroll-secondary;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-secondary, 0.2);
        }

        &.mbsc-focus {
          background: rgba($mbsc-mobiscroll-secondary, 0.3);
        }

        &.mbsc-selected {
          background: $mbsc-mobiscroll-secondary;
        }
      }

      &.mbsc-button-success {
        color: $mbsc-mobiscroll-success;
        border-color: $mbsc-mobiscroll-success;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-success, 0.2);
        }

        &.mbsc-focus {
          background: rgba($mbsc-mobiscroll-success, 0.3);
        }

        &.mbsc-selected {
          background: $mbsc-mobiscroll-success;
        }
      }

      &.mbsc-button-danger {
        color: $mbsc-mobiscroll-danger;
        border-color: $mbsc-mobiscroll-danger;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-danger, 0.2);
        }

        &.mbsc-focus {
          background: rgba($mbsc-mobiscroll-danger, 0.3);
        }

        &.mbsc-selected {
          background: $mbsc-mobiscroll-danger;
        }
      }

      &.mbsc-button-warning {
        color: $mbsc-mobiscroll-warning;
        border-color: $mbsc-mobiscroll-warning;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-warning, 0.2);
        }

        &.mbsc-focus {
          background: rgba($mbsc-mobiscroll-warning, 0.3);
        }

        &.mbsc-selected {
          background: $mbsc-mobiscroll-warning;
        }
      }

      &.mbsc-button-info {
        color: $mbsc-mobiscroll-info;
        border-color: $mbsc-mobiscroll-info;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-info, 0.2);
        }

        &.mbsc-focus {
          background: rgba($mbsc-mobiscroll-info, 0.3);
        }

        &.mbsc-selected {
          background: $mbsc-mobiscroll-info;
        }
      }
    }
  }

  @include mbsc-mobiscroll-segmented('mobiscroll', $mbsc-mobiscroll-colors);
  @include mbsc-mobiscroll-segmented('mobiscroll-dark', $mbsc-mobiscroll-dark-colors);
}



@mixin mbsc-ios-segmented($theme, $params) {
  $background: map-get($params, 'background');
  $accent: map-get($params, 'accent');

  $bg-param: map-get($params, 'form-background');
  $acc-param: map-get($params, 'form-accent');

  $background: if($bg-param, $bg-param, $background);
  $accent: if($acc-param, $acc-param, $accent);

  $is-dark: false;
  $selectbox: '';
  $selectbox-contrast: '';
  $button-background: '';
  $shadow: '';

  @if (lightness($background) > 50%) {
    $button-background: darken($background, 10%);
    $selectbox: #fff;
    $selectbox-contrast: #000;
    $shadow: darken($background, 24%);
  } @else {
    $is-dark: true;
    $button-background: lighten($background, 11%);
    $selectbox: #5a5a5a;
    $selectbox-contrast: #fff;
    $shadow: $background;
  }

  .mbsc-#{$theme} {
    &.mbsc-segmented {
      background: $button-background;
    }

    &.mbsc-segmented-item:before {
      border-color: rgba($selectbox-contrast, 0.2);
    }

    &.mbsc-segmented-item.mbsc-focus .mbsc-segmented-selectbox {
      box-shadow: 0 0 0 0.0625em rgba($selectbox-contrast, 0.5) inset;
    }

    &.mbsc-segmented-selectbox-inner {
      background: $selectbox;

      &.mbsc-selected {
        box-shadow: $shadow 3px 3px 8px -4px;
      }
    }

    &.mbsc-segmented-button.mbsc-button {
      background: transparent;
      color: $selectbox-contrast;
    }

    /* Inside popup and calendar header */
    @if ($is-dark) {
      &.mbsc-datepicker .mbsc-segmented,
      &.mbsc-popup &.mbsc-segmented,
      &.mbsc-calendar-header &.mbsc-segmented {
        background: lighten($background, 17%);
      }
    }

    /* Color presets */

    &.mbsc-segmented-primary {
      background: $mbsc-ios-primary;
    }

    &.mbsc-segmented-secondary {
      background: $mbsc-ios-secondary;
    }

    &.mbsc-segmented-success {
      background: $mbsc-ios-success;
    }

    &.mbsc-segmented-warning {
      background: $mbsc-ios-warning;
    }

    &.mbsc-segmented-danger {
      background: $mbsc-ios-danger;
    }

    &.mbsc-segmented-info {
      background: $mbsc-ios-info;
    }

    &.mbsc-segmented-light {
      background: $mbsc-ios-light;
    }

    &.mbsc-segmented-dark {
      background: $mbsc-ios-dark;
    }
  }
}


@if ($mbsc-ios-theme and $mbsc-segmented) {
  .mbsc-ios {
    &.mbsc-segmented {
      margin: 0.75em;
      padding: 0.0625em;
      position: relative;
      border-radius: 0.5625em;
    }

    &.mbsc-segmented-item:before {
      content: '';
      position: absolute;
      border-left: 1px solid;
      top: 0.3125em;
      bottom: 0.3125em;
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
    }

    &.mbsc-segmented-item.mbsc-ltr:before {
      left: 0;
    }

    &.mbsc-segmented-item.mbsc-rtl:before {
      right: 0;
    }

    &.mbsc-segmented-item:first-child:before,
    &.mbsc-segmented-item-selected:before,
    &.mbsc-segmented-item-selected + .mbsc-segmented-item:before {
      opacity: 0;
    }

    &.mbsc-segmented-selectbox {
      position: absolute;
      padding: 0.0625em;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      user-select: none;
      border-radius: 0.4375em;
      display: none;
    }

    &.mbsc-segmented-selectbox.mbsc-selected {
      display: block;
    }

    &.mbsc-segmented-selectbox-animate {
      transition: transform 0.2s ease-in-out;
    }

    &.mbsc-segmented-selectbox-inner {
      height: 100%;
      border-radius: 0.4375em;
      transition: transform 0.1s ease-in-out;
      visibility: hidden;
    }

    &.mbsc-segmented-selectbox-inner-visible {
      visibility: visible;
    }

    &.mbsc-segmented-item:first-child .mbsc-segmented-selectbox-inner {
      transform-origin: left;
    }

    &.mbsc-segmented-item:last-child .mbsc-segmented-selectbox-inner {
      transform-origin: right;
    }

    &.mbsc-segmented-dragging {
      .mbsc-segmented-selectbox-inner {
        transform: scale(0.97, 0.95);
      }

      .mbsc-segmented-item:first-child .mbsc-segmented-selectbox-inner {
        transform: scale(0.97, 0.95) translateX(0.0625em);
      }

      .mbsc-segmented-item:last-child .mbsc-segmented-selectbox-inner {
        transform: scale(0.97, 0.95) translateX(-0.0625em);
      }
    }

    &.mbsc-segmented-button.mbsc-icon-button {
      height: 2.307693em; // 30px / 13px
    }

    &.mbsc-segmented-button.mbsc-button {
      margin: 0;
      padding: 0 0.615385em; // 0 8px
      border-radius: 0;
      font-size: 0.8125em; // 13px
      line-height: 2.307693em; // 30px / 13px
      transition: opacity 0.1s ease-out, background-color 0.1s ease-out, transform 0.1s ease-in-out;
      // for the scaling to look better
      -webkit-font-smoothing: subpixel-antialiased;

      &.mbsc-active,
      &.mbsc-hover {
        opacity: 1;
      }
    }

    &.mbsc-segmented-dragging .mbsc-segmented-item-selected .mbsc-segmented-button {
      transform: scale(0.97, 0.95);
    }

    &.mbsc-segmented-item-selected:first-child .mbsc-segmented-button {
      transform-origin: left;
    }

    &.mbsc-segmented-item-selected:last-child .mbsc-segmented-button {
      transform-origin: right;
    }
  }

  @include mbsc-ios-segmented('ios', $mbsc-ios-colors);
  @include mbsc-ios-segmented('ios-dark', $mbsc-ios-dark-colors);
}



@mixin mbsc-material-segmented($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $bg-param: map-get($params, 'form-background');
  $acc-param: map-get($params, 'form-accent');
  $text-param: map-get($params, 'form-text');
  $background: if($bg-param, $bg-param, $background);
  $accent: if($acc-param, $acc-param, $accent);
  $text: if($text-param, $text-param, $text);

  $button-color: '';
  $button-background: '';
  $button-text: '';

  @if (lightness($background) > 50%) {
    $button-background: darken($background, 19%);
    $button-color: darken($text, 36%);
    $button-text: darken($text, 36%);
  } @else {
    $button-background: lighten($background, 17%);
    $button-color: lighten($text, 24%);
    $button-text: lighten($text, 24%);
  }

  .mbsc-#{$theme} {
    &.mbsc-segmented-button.mbsc-button {
      border-color: $accent;
      color: $button-text;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($button-color, 0.2);
      }

      &.mbsc-selected {
        background: $accent;
        color: $background;
      }

      &.mbsc-focus::after {
        background: rgba($button-text, 0.2);
      }
    }
  }
}


@if ($mbsc-material-theme and $mbsc-segmented) {
  .mbsc-material {
    &.mbsc-segmented {
      padding: 0.75em;
    }

    &.mbsc-segmented-selectbox {
      display: none;
    }

    &.mbsc-segmented-item:first-child {
      .mbsc-segmented-button {
        border-top-left-radius: 0.25em;
        border-bottom-left-radius: 0.25em;
      }

      .mbsc-segmented-button.mbsc-rtl {
        border-radius: 0 0.25em 0.25em 0;
        border-right-width: 0.142858em;
      }
    }

    &.mbsc-segmented-item:last-child {
      .mbsc-segmented-button {
        border-top-right-radius: 0.25em;
        border-bottom-right-radius: 0.25em;
        border-right-width: 0.142858em;
      }

      .mbsc-segmented-button.mbsc-rtl {
        border-radius: 0.25em 0 0 0.25em;
        border-right-width: 0;
      }
    }

    &.mbsc-segmented-button.mbsc-button {
      margin: 0;
      padding: 0.285715em 1.142858em;
      border: 0.142858em solid transparent;
      border-right-width: 0;
      border-radius: 0;
      background: none;
      box-shadow: none;
      min-width: auto;
      font-size: 0.875em;

      &.mbsc-hover,
      &.mbsc-active {
        box-shadow: none;
      }

      &.mbsc-focus:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      /* Color presets */

      &.mbsc-button-primary {
        color: $mbsc-material-primary;
        border-color: $mbsc-material-primary;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-material-primary, 0.2);
        }

        &.mbsc-selected {
          background: $mbsc-material-primary;
        }
      }

      &.mbsc-button-secondary {
        color: $mbsc-material-secondary;
        border-color: $mbsc-material-secondary;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-material-secondary, 0.2);
        }

        &.mbsc-selected {
          background: $mbsc-material-secondary;
        }
      }

      &.mbsc-button-success {
        color: $mbsc-material-success;
        border-color: $mbsc-material-success;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-material-success, 0.2);
        }

        &.mbsc-selected {
          background: $mbsc-material-success;
        }
      }

      &.mbsc-button-danger {
        color: $mbsc-material-danger;
        border-color: $mbsc-material-danger;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-material-danger, 0.2);
        }

        &.mbsc-selected {
          background: $mbsc-material-danger;
        }
      }

      &.mbsc-button-warning {
        color: $mbsc-material-warning;
        border-color: $mbsc-material-warning;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-material-warning, 0.2);
        }

        &.mbsc-selected {
          background: $mbsc-material-warning;
        }
      }

      &.mbsc-button-info {
        color: $mbsc-material-info;
        border-color: $mbsc-material-info;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-material-info, 0.2);
        }

        &.mbsc-selected {
          background: $mbsc-material-info;
        }
      }
    }
  }

  @include mbsc-material-segmented('material', $mbsc-material-colors);
  @include mbsc-material-segmented('material-dark', $mbsc-material-dark-colors);
}



@mixin mbsc-windows-segmented($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $bg-param: map-get($params, 'form-background');
  $text-param: map-get($params, 'form-text');
  $acc-param: map-get($params, 'form-accent');

  $background: if($bg-param, $bg-param, $background);
  $text: if($text-param, $text-param, $text);
  $accent: if($acc-param, $acc-param, $accent);

  $button-border: '';
  $button-hover: '';
  $button-active: '';
  $border: '';
  $form-background: '';
  $button-bg: '';

  @if (lightness($background) > 50%) {
    $button-border: darken($background, 40%);
    $button-hover: darken($background, 17%);
    $button-active: lighten($text, 28%);
    $button-bg: darken($background, 20%);
    $border: darken($background, 10%);
    $form-background: lighten($background, 13%);
  } @else {
    $button-border: lighten($background, 35%);
    $button-active: darken($text, 33%);
    $button-hover: lighten($background, 17%);
    $button-bg: lighten($background, 15%);
    $border: lighten($background, 10%);
    $form-background: darken($background, 12%);
  }

  .mbsc-#{$theme} {
    &.mbsc-segmented-button.mbsc-button {
      background: $button-bg;
      color: $text;

      &.mbsc-hover {
        background: $button-hover;
      }

      &.mbsc-active {
        background: $button-hover;
      }

      &.mbsc-selected {
        background: $button-active;
        color: $form-background;
      }
    }
  }
}


@if ($mbsc-windows-theme and $mbsc-segmented) {
  .mbsc-windows {
    &.mbsc-segmented {
      padding: 0.75em;
    }

    &.mbsc-segmented-selectbox {
      display: none;
    }

    &.mbsc-segmented-button.mbsc-button {
      margin: 0;
      border-color: transparent;

      &.mbsc-active,
      &.mbsc-hover {
        border-color: transparent;
      }

      &.mbsc-focus {
        z-index: 2;
      }

      /* Color presets */
      &.mbsc-button-primary {
        &.mbsc-active,
        &.mbsc-selected {
          background: $mbsc-windows-primary;
        }
      }

      &.mbsc-button-secondary {
        &.mbsc-active,
        &.mbsc-selected {
          background: $mbsc-windows-secondary;
        }
      }

      &.mbsc-button-success {
        &.mbsc-active,
        &.mbsc-selected {
          background: $mbsc-windows-success;
        }
      }

      &.mbsc-button-danger {
        &.mbsc-active,
        &.mbsc-selected {
          background: $mbsc-windows-danger;
        }
      }

      &.mbsc-button-warning {
        &.mbsc-active,
        &.mbsc-selected {
          background: $mbsc-windows-warning;
        }
      }

      &.mbsc-button-info {
        &.mbsc-active,
        &.mbsc-selected {
          background: $mbsc-windows-info;
        }
      }
    }
  }

  @include mbsc-windows-segmented('windows', $mbsc-windows-colors);
  @include mbsc-windows-segmented('windows-dark', $mbsc-windows-dark-colors);
}


@if $mbsc-segmented {
  .mbsc-segmented {
    // display: table;
    // table-layout: fixed;
    // width: 100%;
    overflow: hidden;
    // box-sizing: border-box;
  }

  .mbsc-segmented-item {
    margin: 0;
    // display: table-cell;
    position: relative;
    // vertical-align: top;
    text-align: center;
    font-size: 1em;
    flex: 1 1 0;
    user-select: none;
    min-width: 0; // needed for the items to have equal widths
  }

  .mbsc-segmented-label {
    display: block;
    margin: 0;
    padding: 0;
  }

  .mbsc-segmented-input {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    position: absolute;
  }

  .mbsc-segmented-button.mbsc-button {
    width: 100%;
  }
}



@mixin mbsc-ios-timegrid($theme, $params) {
  $background: map-get($params, 'background');
  $accent: map-get($params, 'accent');
  $text: map-get($params, 'text');

  $background-hover: '';
  $selected-text: '';

  @if (lightness($background) > 50%) {
    $background-hover: lighten($accent, 35%);
    $selected-text: lighten($text, 100%);
  } @else {
    $background-hover: darken($accent, 25%);
    $selected-text: lighten($text, 100%);
  }

  .mbsc-#{$theme} {
    &.mbsc-timegrid-item:hover,
    &.mbsc-timegrid-item:focus {
      background: rgba($accent, 0.3);
    }

    &.mbsc-timegrid-item.mbsc-selected {
      background: $accent;
      color: $selected-text;
    }
  }
}


@if ($mbsc-ios-theme and $mbsc-datepicker) {
  .mbsc-ios {
    &.mbsc-timegrid-item {
      line-height: 2em;
      margin: 0.5em 0.625em;
      border-radius: 2em;
      font-size: 0.875em;
    }

    &.mbsc-timegrid-item.mbsc-selected,
    &.mbsc-timegrid-item:hover,
    &.mbsc-timegrid-item:focus {
      font-size: 1em;
      margin: 0 0.25em;
      outline: none;
    }
  }

  @include mbsc-ios-timegrid('ios', $mbsc-ios-colors);
  @include mbsc-ios-timegrid('ios-dark', $mbsc-ios-dark-colors);
}



@mixin mbsc-material-timegrid($theme, $params) {
  $background: map-get($params, 'background');
  $accent: map-get($params, 'accent');
  $text: map-get($params, 'text');

  $background-hover: '';
  $selected-text: '';

  @if (lightness($background) > 50%) {
    $background-hover: lighten($accent, 35%);
    $selected-text: lighten($text, 100%);
  } @else {
    $background-hover: darken($accent, 25%);
    $selected-text: darken($text, 100%);
  }

  .mbsc-#{$theme} {
    &.mbsc-timegrid-item:hover,
    &.mbsc-timegrid-item:focus {
      background: rgba($text, 0.1);
    }

    &.mbsc-timegrid-item.mbsc-selected {
      background: $accent;
      color: $selected-text;
    }
  }
}


@if ($mbsc-material-theme and $mbsc-datepicker) {
  .mbsc-material {
    &.mbsc-timegrid-item {
      line-height: 2em;
      margin: 0.5em 0.625em;
      border-radius: 2em;
      font-size: 0.875em;
    }

    &.mbsc-timegrid-item.mbsc-selected,
    &.mbsc-timegrid-item:hover,
    &.mbsc-timegrid-item:focus {
      margin: 0 0.25em;
      outline: none;
    }
  }

  @include mbsc-material-timegrid('material', $mbsc-material-colors);
  @include mbsc-material-timegrid('material-dark', $mbsc-material-dark-colors);
}



@mixin mbsc-windows-timegrid($theme, $params) {
  $background: map-get($params, 'background');
  $accent: map-get($params, 'accent');
  $text: map-get($params, 'text');

  .mbsc-#{$theme} {
    &.mbsc-timegrid-item.mbsc-selected {
      background: rgba($accent, 0.25);
      color: $text;
    }

    &.mbsc-timegrid-item:hover,
    &.mbsc-timegrid-item:focus {
      background: rgba($text, 0.1);
    }

    &.mbsc-timegrid-item.mbsc-selected:hover,
    &.mbsc-timegrid-item.mbsc-selected:focus {
      background: mix(rgba($accent, 0.5), rgba($text, 0.2));
    }
  }
}


@if ($mbsc-windows-theme and $mbsc-datepicker) {
  .mbsc-windows {
    &.mbsc-timegrid-item {
      padding: 1.3125em 0;
      margin: 0.0625em;
      font-size: 14px;
    }

    &.mbsc-timegrid-item:focus {
      outline: none;
    }
  }

  @include mbsc-windows-timegrid('windows', $mbsc-windows-colors);
  @include mbsc-windows-timegrid('windows-dark', $mbsc-windows-dark-colors);
}


@if $mbsc-datepicker {
  // When the calendar control is present, then the calendar specifies the height,
  // but when the row is wrapped, we need a minimum height, so the grid won't collapse
  // into 0
  .mbsc-datepicker-control-calendar .mbsc-datepicker-tab-timegrid {
    min-height: 10em;
  }

  // when there is no other control there the grid needs a minimum height
  .mbsc-datepicker-tab-timegrid {
    min-height: 19em;
  }

  .mbsc-timegrid-item.mbsc-disabled {
    pointer-events: none;
    opacity: 0.2;
  }

  .mbsc-datepicker-tab-timegrid.mbsc-datepicker-tab {
    position: relative;
    overflow: auto;
    align-items: flex-start;
    width: 18.5em;
  }

  .mbsc-timegrid-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .mbsc-timegrid-row {
    display: table-row;
  }

  .mbsc-timegrid-cell {
    display: table-cell;
    position: relative; // do not remove!

    &.mbsc-disabled {
      cursor: not-allowed;
    }
  }

  .mbsc-timegrid-item {
    vertical-align: middle;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
  }
}



@mixin mbsc-ios-datepicker($theme, $params) {
  $background: map-get($params, 'background');
  $accent: map-get($params, 'accent');
  $text: map-get($params, 'text');

  $background-alt: '';
  $background-top-bottom: '';
  $border-color: '';
  $buttons-background: '';
  $clear-hover: '';

  @if (lightness($background) > 50%) {
    $background-alt: lighten($background, 3%);
    $background-top-bottom: adjust-hue(darken(saturate($background, 12%), 13%), 216deg);
    $border-color: darken($background, 17%);
    $clear-hover: darken($border-color, 10%);
    $buttons-background: darken($background, 3%);
  } @else {
    $background-alt: lighten($background, 11%);
    $background-top-bottom: lighten($background, 11%);
    $border-color: lighten($background, 20%);
    $clear-hover: lighten($border-color, 10%);
    $buttons-background: lighten($background, 8%);
  }

  .mbsc-#{$theme} {
    &.mbsc-picker {
      .mbsc-popup-arrow,
      .mbsc-popup-body {
        background: $background-alt;
      }
    }

    &.mbsc-picker-header {
      border-color: $border-color;
    }

    &.mbsc-datepicker {
      .mbsc-calendar,
      .mbsc-calendar-slide,
      .mbsc-calendar-cell {
        background: $background-alt;
      }
    }

    &.mbsc-datepicker-top.mbsc-datepicker-control-date,
    &.mbsc-datepicker-bottom.mbsc-datepicker-control-date {
      background: $background-top-bottom;
    }

    &.mbsc-datepicker-inline {
      background: $background-alt;
      border-color: $border-color;
      color: $text;
    }

    /* Range Control */

    &.mbsc-range-control-value.active {
      color: $accent;
    }

    &.mbsc-range-control-text-empty,
    &.mbsc-range-control-text-empty.active {
      color: rgba($text, 0.4);
    }

    &.mbsc-range-label-clear {
      color: $border-color;

      &:hover {
        color: $clear-hover;
      }
    }
  }
}


@if ($mbsc-ios-theme and $mbsc-datepicker) {
  .mbsc-ios {
    &.mbsc-picker {
      .mbsc-popup-overlay-top,
      .mbsc-popup-overlay-bottom {
        background: none;
      }
    }

    &.mbsc-datepicker-inline {
      border-top: 1px solid;
      border-bottom: 1px solid;
      margin-top: -1px;
      margin-bottom: -1px;
      z-index: 2;
      position: relative;
    }

    &.mbsc-datepicker .mbsc-calendar-grid,
    &.mbsc-datepicker .mbsc-calendar-cell,
    &.mbsc-datepicker .mbsc-calendar-cell-inner {
      border-color: transparent;
    }

    &.mbsc-datepicker .mbsc-selected .mbsc-calendar-day-text,
    &.mbsc-datepicker .mbsc-highlighted .mbsc-calendar-day-text,
    &.mbsc-datepicker .mbsc-range-hover-start .mbsc-calendar-day-text,
    &.mbsc-datepicker .mbsc-range-hover-end .mbsc-calendar-day-text,
    &.mbsc-datepicker .mbsc-hover .mbsc-calendar-day-text {
      width: 1.444445em;
      height: 1.444445em;
      margin: 0.16667em;
      font-size: 1.125em;
      line-height: 1.444445em;
    }

    &.mbsc-picker-header {
      line-height: 1.25em;
      padding: 0.75em 4.375em;
      font-weight: bold;
      text-align: center;
      border-bottom: 1px solid;
    }

    /* Range Controls */

    &.mbsc-range-control-wrapper {
      padding: 0 0.75em;
      overflow: hidden;
    }

    &.mbsc-range-control-wrapper .mbsc-segmented {
      width: 17em; // 272px
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      box-sizing: border-box;
    }

    // Segmented width based on controls

    &.mbsc-datepicker-control-calendar.mbsc-datepicker-control-timegrid .mbsc-range-control-wrapper .mbsc-segmented,
    &.mbsc-datepicker-control-calendar.mbsc-datepicker-control-time .mbsc-range-control-wrapper .mbsc-segmented,
    &.mbsc-datepicker-control-date.mbsc-datepicker-control-time .mbsc-range-control-wrapper .mbsc-segmented,
    &.mbsc-datepicker-control-datetime .mbsc-range-control-wrapper .mbsc-segmented {
      width: 25em; // 400px: default format can show -> "09/25/2020 12:30 AM"
    }

    &.mbsc-range-control-wrapper .mbsc-segmented-button {
      display: block;
      padding: 0 0.5em;
    }

    &.mbsc-range-control-wrapper .mbsc-range-value-nonempty .mbsc-segmented-button {
      padding: 0 2.0625em 0 0.5em;
    }

    &.mbsc-range-control-wrapper .mbsc-range-value-nonempty .mbsc-segmented-button.mbsc-rtl {
      padding: 0 0.5em 0 2.0625em;
    }

    &.mbsc-range-control-label,
    &.mbsc-range-control-value {
      text-align: left;
      line-height: 1.538462em; // 20px

      &.active {
        font-weight: 600;
      }

      &.mbsc-rtl {
        text-align: right;
      }
    }

    &.mbsc-range-control-label {
      padding: 0.615385em 0.615385em 0 0.615385em; // 8px
    }

    &.mbsc-range-control-value {
      padding: 0 0.615385em 0.615385em 0.615385em; // 8px
      text-overflow: ellipsis;
      overflow: hidden;

      &.active {
        padding: 0 0.571429em 0.571429em 0.571429em; // 8px
        font-size: 1.076923em; // 14px
        line-height: 1.428572em; // 20px
      }
    }

    &.mbsc-range-label-clear {
      margin-top: -0.692308em; // 9px
      width: 1.307693em; // 17px
      height: 1.307693em;

      &.mbsc-ltr {
        right: 1em;
      }

      &.mbsc-rtl {
        left: 1em;
      }
    }
  }

  @include mbsc-ios-datepicker('ios', $mbsc-ios-colors);
  @include mbsc-ios-datepicker('ios-dark', $mbsc-ios-dark-colors);
}



@mixin mbsc-material-datepicker($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $ctrl-background: '';
  $clear-icon: '';
  $clear-icon-hover: '';

  @if (lightness($background) > 50%) {
    $ctrl-background: darken($text, 36%);
    $clear-icon: lighten($text, 50%);
    $clear-icon-hover: darken($clear-icon, 20%);
  } @else {
    $ctrl-background: lighten($text, 24%);
    $clear-icon: darken($text, 50%);
    $clear-icon-hover: lighten($clear-icon, 20%);
  }

  $background-alt: '';

  // Light background
  @if (lightness($background) > 50%) {
    $background-alt: $background;
  }

  // Dark background
  @else {
    $background-alt: lighten($background, 19%);
  }

  .mbsc-#{$theme} {
    &.mbsc-datepicker .mbsc-calendar-slide {
      background: $background-alt;
    }

    &.mbsc-datepicker-inline {
      background: $background;
      color: $text;
    }

    &.mbsc-datepicker-inline .mbsc-calendar-slide {
      background: $background;
    }

    /* Range Controls */

    &.mbsc-range-control-wrapper {
      border-bottom: 1px solid rgba($ctrl-background, 0.2);
    }

    &.mbsc-range-control-wrapper &.mbsc-segmented-button {
      &.mbsc-selected {
        border-bottom-color: $accent;
      }
    }

    &.mbsc-range-control-value,
    &.mbsc-range-control-label {
      &.active {
        color: $accent;
      }
    }

    &.mbsc-range-control-text-empty,
    &.mbsc-range-control-text-empty.active {
      color: rgba($text, 0.4);
    }

    &.mbsc-range-label-clear {
      color: $clear-icon;

      &:hover {
        color: $clear-icon-hover;
      }
    }
  }
}


@if ($mbsc-material-theme and $mbsc-datepicker) {
  .mbsc-material {
    &.mbsc-picker-header {
      font-size: 1.25em;
      font-weight: 500;
      padding: 0.8em 0.8em 0 0.8em;
      line-height: 1.4em;
      text-align: center;
    }

    /* Range Control */

    // Overrides for the segmented
    &.mbsc-range-control-wrapper {
      .mbsc-segmented {
        padding: 0;
        max-width: 100%;
        width: 18.5em; // 296px
      }

      .mbsc-segmented-button.mbsc-button.mbsc-font {
        background: none;
        border: 0;
        border-bottom: 2px solid transparent;
        border-radius: 0;
        padding: 0.8571422em 1.142857em; // 12px 16px (14px based)
        display: block;
      }

      .mbsc-segmented-button.mbsc-ltr {
        text-align: left;
      }

      .mbsc-segmented-button.mbsc-rtl {
        text-align: right;
      }

      .mbsc-range-value-nonempty .mbsc-segmented-button.mbsc-ltr {
        padding-right: 2.642857em; // 37px (14px based)
      }

      .mbsc-range-value-nonempty .mbsc-segmented-button.mbsc-rtl {
        padding-left: 2.642857em; // 37px (14px based)
      }
    }

    // Segmented width based on controls

    &.mbsc-datepicker-control-calendar.mbsc-datepicker-control-timegrid .mbsc-range-control-wrapper .mbsc-segmented,
    &.mbsc-datepicker-control-calendar.mbsc-datepicker-control-time .mbsc-range-control-wrapper .mbsc-segmented,
    &.mbsc-datepicker-control-date.mbsc-datepicker-control-time .mbsc-range-control-wrapper .mbsc-segmented,
    &.mbsc-datepicker-control-datetime .mbsc-range-control-wrapper .mbsc-segmented {
      width: 25em; // 400px: default format can show -> "09/25/2020 12:30 AM"
    }

    &.mbsc-range-control-label {
      text-transform: uppercase;
    }

    &.mbsc-range-control-value {
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.mbsc-range-label-clear {
      margin-top: -0.625em; // 10px
      right: 0.75em; // 12px
      width: 1.3125em; // 21px
      height: 1.3125em;

      &.mbsc-ltr {
        right: 0.75em;
      }

      &.mbsc-rtl {
        left: 0.75em;
      }
    }

    // Reduce calendar title font size

    &.mbsc-datepicker .mbsc-calendar-title {
      font-size: 1.142858em;
      line-height: 1.75em;
    }

    &.mbsc-calendar-grid .mbsc-calendar-title {
      font-size: 1.428572em;
      line-height: 1.4em;
    }
  }

  @include mbsc-material-datepicker('material', $mbsc-material-colors);
  @include mbsc-material-datepicker('material-dark', $mbsc-material-dark-colors);
}



@mixin mbsc-windows-datepicker($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $button-bg: '';

  @if (lightness($background) > 50%) {
    $button-bg: darken($background, 20%);
  } @else {
    $button-bg: lighten($background, 15%);
  }

  $border: '';
  $hover: '';

  @if (lightness($background) > 50%) {
    $border: darken($background, 10%);
    $hover: lighten($text, 70%);
  } @else {
    $border: lighten($background, 10%);
    $hover: darken($text, 70%);
  }

  .mbsc-#{$theme} {
    &.mbsc-datepicker-inline {
      background: $background;
      color: $text;
    }

    &.mbsc-picker-header {
      border-color: $border;
    }

    &.mbsc-datepicker-tab {
      border-color: $border;
    }

    &.mbsc-range-control-wrapper {
      border-bottom: 1px solid $border;
    }

    &.mbsc-range-control-wrapper &.mbsc-segmented-button {
      &.mbsc-selected {
        border-bottom-color: $accent;
      }

      &.mbsc-focus {
        box-shadow: 0 0 0 1px $text inset;
      }
    }

    &.mbsc-range-control-value,
    &.mbsc-range-control-label {
      &.active {
        color: $accent;
      }
    }

    &.mbsc-range-control-text-empty,
    &.mbsc-range-control-text-empty.active {
      color: rgba($text, 0.4);
    }

    &.mbsc-range-label-clear {
      color: rgba($text, 0.6);

      &:hover {
        color: $text;
      }
    }
  }
}


@if ($mbsc-windows-theme and $mbsc-datepicker) {
  .mbsc-windows {
    &.mbsc-picker-header {
      padding: 0.5em;
      font-weight: bold;
      text-align: center;
      border-bottom: 1px solid;
    }

    &.mbsc-datepicker-tab {
      border-top: 1px solid;
      margin-top: -1px;
    }

    /* Range Control */

    // overwrites for the segmented
    &.mbsc-range-control-wrapper {
      .mbsc-segmented {
        padding: 0;
        max-width: 100%;
        width: 18.5em; // 296px
      }

      .mbsc-segmented-button.mbsc-button.mbsc-font {
        background: none;
        border: 0;
        border-bottom: 2px solid transparent;
        padding: 0.625em 1em; // 10px 8px;
        line-height: 1.25em; // 20px
        display: block;
        text-align: left;
      }

      .mbsc-segmented-button.mbsc-rtl {
        text-align: right;
      }

      .mbsc-range-value-nonempty .mbsc-segmented-button.mbsc-ltr {
        padding-right: 1.875em; // 10px 28px 10px 8px;
      }

      .mbsc-range-value-nonempty .mbsc-segmented-button.mbsc-rtl {
        padding-left: 1.875em;
      }
    }

    // Segmented width based on controls

    &.mbsc-datepicker-control-calendar.mbsc-datepicker-control-timegrid .mbsc-range-control-wrapper .mbsc-segmented,
    &.mbsc-datepicker-control-calendar.mbsc-datepicker-control-time .mbsc-range-control-wrapper .mbsc-segmented,
    &.mbsc-datepicker-control-date.mbsc-datepicker-control-time .mbsc-range-control-wrapper .mbsc-segmented,
    &.mbsc-datepicker-control-datetime .mbsc-range-control-wrapper .mbsc-segmented {
      width: 26.25em; // 420px: default format can show -> "09/25/2020 12:30 AM"
    }

    &.mbsc-range-control-label {
      font-size: 0.9375em; // 15px
      line-height: 1.6em; // 24px
    }

    &.mbsc-range-control-value {
      font-size: 1.0625em; // 17px
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.mbsc-range-label-clear {
      width: 1em; // 16px
      height: 1em;
      right: 0.4375em; // 7px;
      margin-top: -0.5em;

      &.mbsc-ltr {
        right: 0.4375em; // 7px;
      }

      &.mbsc-rtl {
        left: 0.4375em; // 7px
      }
    }
  }

  @include mbsc-windows-datepicker('windows', $mbsc-windows-colors);
  @include mbsc-windows-datepicker('windows-dark', $mbsc-windows-dark-colors);
}


@if $mbsc-datepicker {
  /* Needed for angular */
  mbsc-datetime {
    display: block;
    width: 100%;
  }

  .mbsc-datepicker-inline {
    height: 100%;
  }

  .mbsc-datepicker .mbsc-calendar {
    height: 100%;
    max-width: 100%;
    padding-bottom: 0.5em;
    box-sizing: border-box;
    // For IE11
    display: block;
  }

  .mbsc-datepicker .mbsc-calendar-header .mbsc-calendar-week-days {
    padding: 0 0.5em;
  }

  /* Start/end controls */

  .mbsc-range-control-wrapper .mbsc-segmented-input {
    // v4 compatibility
    width: 1px;
  }

  .mbsc-range-label-clear {
    position: absolute;
    top: 50%;
  }

  /* Tabs */

  .mbsc-datepicker-tab-wrapper {
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
  }

  .mbsc-datepicker-tab {
    visibility: hidden;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }

  .mbsc-datepicker-tab-expand {
    height: 100%;
  }

  .mbsc-datepicker-tab-active {
    visibility: visible;
  }

  .mbsc-datepicker-time-modal {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    max-width: none;
    height: 100%;
  }

  .mbsc-datepicker .mbsc-calendar-slide {
    padding: 0 0.5em;
  }
}

// calendar component

// calendar-view component

// datepicker component

// datetime component

// button component

// popup component

// scroller component

// timegrid component

// theme builder functions
// theme builder function for ios theme

@mixin mbsc-ios-theme($theme, $colors) {
    @include mbsc-ios-calendar($theme, $colors);
    @include mbsc-ios-calendar-view($theme, $colors);
    @include mbsc-ios-datepicker($theme, $colors);
    @include mbsc-ios-datetime($theme, $colors);
    @include mbsc-ios-button($theme, $colors);
    @include mbsc-ios-popup($theme, $colors);
    @include mbsc-ios-scroller($theme, $colors);
    @include mbsc-ios-timegrid($theme, $colors);
}

@mixin mbsc-custom-theme($theme, $base-theme, $colors) {
    @if $base-theme==ios {
                @include mbsc-ios-theme($theme, $colors);
            }
}

